import css from 'styled-jsx/css';
import { colors, icons, typography } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const multiSelectStyle = css`
  .multiselect-container {
    @mixin mobileToTablet {
      background-color: ${colors.gray.shade12};
    }
    &.shipping {
      @mixin mobileToTablet {
        margin-top: 11px;
      }
    }
  }
  .searchBox {
    padding-bottom: 10px;
    @mixin mobileToTablet {
      background-color: ${colors.gray.shade12};
      padding: 0 15px;
      position: relative;
      display: flex;
      margin: unset;
    }
  }
  /* stylelint-disable length-zero-no-unit */
  .multiselect {
    overflow-y: auto;
    max-height: 245px;
    @mixin tabletToDesktop {
      max-height: 244px;
      border-bottom: 0;
    }
    @mixin mobileToTablet {
      max-height: 383px;
      border-bottom: 0;
    }
    &.shipping-multiselect {
      margin: 0;
      max-height: 100%;
      @mixin mobileToTablet {
        padding: 0 20px;
        background-color: ${colors.white.shade1};
        text-transform: none;
      }
    }
    li {
      padding: 10px 20px;
      @mixin mobileToTablet {
        border-bottom: 1px solid ${colors.gray.shade36};
        padding: 6px 16px;
        min-height: 40px;
      }
      @mixin tabletToDesktop {
        padding: 0 0 8px;
        border-bottom: 0;
      }
      .label-text,
      .label-count {
        font-size: ${typography.base};
        line-height: 2.3rem;
        letter-spacing: 0.4px;
        @mixin mobileToTablet {
          line-height: unset;
        }
        &.shipping-facet-text {
          font-size: ${typography.base};
          line-height: 1.6rem;
        }
        &.capitalize {
          text-transform: capitalize;
        }
        &.uppercase {
          text-transform: uppercase;
        }
        &.ignorecase {
          text-transform: none;
        }
      }
      .green-text {
        color: ${colors.green.shade20};
      }
      .facet-sub-text {
        font-size: 12px;
        color: ${colors.gray.shade40};
        font-weight: normal;
        line-height: 14px;
        .bold {
          font-weight: bold;
        }
        &.no-count {
          margin-top: 2px;
        }
      }
      .label-count {
        align-self: flex-start;
        font-weight: 400;
      }
    }
    li.shipping-facet {
      padding-left: 0;
      @mixin tabletToDesktop {
        padding-left: 31px;
      }
      @mixin mobileToTablet {
        border-bottom: unset;
        padding-right: 0;
      }
    }
    li.shipping-facet1 {
      padding-left: 0;
      @mixin tabletToDesktop {
        padding-left: 26px;
      }
      @mixin mobileToTablet {
        border-bottom: unset;
        padding-right: 0;
      }
    }
    .boom-div {
      display: flex;
    }
    .boomIcon {
      background-image: url('/a/fa/listing/static/styles/svg/boomIcon.svg');
      background-repeat: no-repeat;
      width: 60px;
      height: 16px;
      align-self: center;
      display: inline-block;
    }
    .facet-title {
      display: flex;
      align-items: center;
      height: 42px;
      p {
        font-weight: bold;
        font-size: 14px;
        line-height: 45px;
        letter-spacing: 0.4px;
        color: #6c7883;
        @mixin mobileToTablet {
          font-size: 16px;
          color: #333;
        }
      }
    }
    .home-delivery-icon {
      margin-right: 7.33px;
      width: 24px;
      height: 13px;
      background-image: url('${svgToDataUri(icons.hdIcon)}');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      @mixin mobileToTablet {
        width: 38px;
        height: 38px;
        margin-right: 8px;
      }
    }
    .click-collect-icon {
      margin-right: 10.25px;
      width: 17px;
      height: 14px;
      background-image: url('${svgToDataUri(icons.ccIcon)}');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      @mixin mobileToTablet {
        width: 38px;
        height: 38px;
        margin-right: 8px;
      }
    }
    .buy-at-store-icon {
      margin-right: 10.25px;
      width: 17px;
      height: 14px;
      background-image: url('${svgToDataUri(icons.bsIcon)}');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      @mixin mobileToTablet {
        width: 38px;
        height: 38px;
        margin-right: 8px;
      }
    }
    .divider-line {
      border: 1px solid ${colors.gray.shade14};
      margin-top: 4px;
      &.mobile {
        margin-bottom: 9px;
        margin-top: 14px;
      }
    }
    .rating-description {
      font-size: 12px;
      line-height: 24px;
      text-transform: lowercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .mkp-ratings {
      display: flex;
    }
  }
`;

export { multiSelectStyle };
