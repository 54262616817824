import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../../../config/constants';
import noop from '../../../../../utils/noop';
import Checkbox from '../../../../ui/Checkbox/Checkbox';
import StoresLine from '../StoresLine/StoresLine';
import { ShippingBoxStyles } from './ShippingBox.style';
import { ShippingBoxButtonWithApp } from './ShippingBoxButton/ShippingBoxButton';

const ShippingBox = (props) => {
  const {
    label,
    labelDetails,
    labelAmount,
    timeline,
    shippingMap,
    storesMap,
    onFacetChange,
    type,
    showLabel,
    freeShippingFacet,
    storesLine = '',
    deviceType,
  } = props;
  const handleFacetChange = (obj) => () => {
    onFacetChange({ ...obj, selected: !obj.selected });
  };
  const renderFacets = (facetData) => {
    return facetData.map((option) => {
      const { title, count, selected, facetType } = option;
      let icon = '';
      let displayTitle = title;
      const hasTimeConstraint = constants.SAME_DAY_HD_CC_REGEX.test(title);
      if (/(.bas_|.pks_)/.test(facetType)) {
        displayTitle = title.replace(constants.REGEX_1P, '');
      }
      if (facetType === 'f.shipping.cc_same_day_politicalId') {
        icon = 'flash-icon';
      }
      return (
        <ShippingBoxButtonWithApp
          isChecked={selected}
          onChange={handleFacetChange(option)}
          id={`${title.replace(/\s/g, '-').toUpperCase()}-${count}`}
          title={displayTitle}
          icon={icon}
          hasTimeConstraint={hasTimeConstraint}
        />
      );
    });
  };
  const getContent = (shippingtype) => {
    switch (shippingtype) {
      case 'home_delivery':
      case 'withdraw_at_store':
        return (
          <>
            <div className="shipping-buttons">{renderFacets(shippingMap)}</div>
            <style jsx>{ShippingBoxStyles}</style>
          </>
        );
      case 'buy_at_store':
        return storesMap?.map((s) => {
          const { facets, title, iconClassName } = s;
          return (
            <>
              <div>
                {title && (
                  <div className="store-title">
                    <div className={`${iconClassName}`}>
                      <i />
                    </div>
                    {title}
                  </div>
                )}
                <div className="shipping-buttons">{renderFacets(facets)}</div>
              </div>
              <style jsx>{ShippingBoxStyles}</style>
            </>
          );
        });
      default:
        return null;
    }
  };
  return (
    <div className="shipping-container">
      <div className="heading-section">
        <div className="icon-container">
          <i className={`icon ${type}`} />
        </div>
        <div className="heading shipping">
          <p>{label}</p>
        </div>
        {freeShippingFacet?.values?.[0] && (
          <div className="envio-gratis-toggle">
            <Checkbox
              variant="secondary"
              isChecked={freeShippingFacet.values[0].selected}
              id={freeShippingFacet.values[0].title}
              onChange={handleFacetChange(freeShippingFacet.values[0])}
              type="mkp-succes"
              normalBorderClass=" mkp-normal-border"
              selectedBorderClass=" mkp-border"
              isPrimaryTheme
            >
              <span className="toggle-name">Gratis</span>
            </Checkbox>
          </div>
        )}
      </div>
      {showLabel && (
        <div className="label-section">
          {labelDetails}&nbsp;<strong className="bold">{labelAmount}</strong>
          &nbsp;{timeline}
        </div>
      )}
      {storesLine.length > 0 && <StoresLine storesLine={storesLine} deviceType={deviceType} />}
      {getContent(type)}
      <style jsx>{ShippingBoxStyles}</style>
    </div>
  );
};
ShippingBox.defaultProps = {
  label: '',
  labelDetails: '',
  labelAmount: '',
  timeline: '',
  onFacetChange: noop,
  type: '',
  shippingMap: [],
  storesMap: [],
  storesLine: '',
  showLabel: false,
  deviceType: '',
  freeShippingFacet: { values: [] },
};

ShippingBox.propTypes = {
  label: PropTypes.string,
  freeShippingFacet: PropTypes.shape({ values: PropTypes.array }),
  storesLine: PropTypes.string,
  labelDetails: PropTypes.string,
  labelAmount: PropTypes.string,
  timeline: PropTypes.string,
  onFacetChange: PropTypes.func,
  type: PropTypes.string,
  shippingMap: PropTypes.array,
  storesMap: PropTypes.array,
  showLabel: PropTypes.bool,
  deviceType: PropTypes.string,
};

export { ShippingBox };
