import css from 'styled-jsx/css';
import { colors, icons } from '../../../../config/styles';
import { svgToDataUri } from '../../../../utils/styles/svgMixins';

const shippingListStyle = css`
  .shipping-list-container {
    gap: 10px;
    display: flex;
    flex-direction: column;

    @mixin mobileToTablet {
      padding: 0 12px 12px;
    }
  }

  .divider-line {
    border: 1px solid ${colors.gray.shade14};
    margin-top: 5px;
  }

  .location {
    padding: 14px;
    border-radius: 2px;
    background-color: ${colors.gray.shade12};
    border: 1px solid ${colors.gray.shade14};
  }
  .show-more-stores-toggle {
    display: flex;
    gap: 4px;
    color: ${colors.black.shade1};

    p {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      border-bottom: 1px solid ${colors.black.shade1};
    }

    .plus {
      width: 14px;
      height: 14px;
      background-image: url('${svgToDataUri(icons.plusIcon)}');
    }
    .minus {
      width: 14px;
      height: 14px;
      background-image: url('${svgToDataUri(icons.minusIcon)}');
    }
  }
`;

export { shippingListStyle };
