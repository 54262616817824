import constants from '../../config/constants';
import _ from '../../utils/LodashImports';
import { getPage } from '../../utils/urlHelper';

const pageTypeMapping = {
  noResult: 'noSearchResult',
  search: 'search',
  buscar: 'buscar',
  shop: 'shop',
  category: 'product list page',
  lista: 'product list page',
  collection: 'product list page',
  seleccion: 'product list page',
  product: 'product detail page',
  articulo: 'product detail page',
  seller: 'product list page',
  vendedor: 'product list page',
  brand: 'product list page',
  marca: 'product list page',
  '': 'home',
};

const catalystPageMapping = {
  noResult: 'slp',
  search: 'slp',
  category: 'plp',
  collection: 'collection',
  product: 'pdp',
  seller: 'plp seller',
  brand: 'plp brand',
  '': 'home',
};

const getPageType = () => {
  const page = getPage(window.location.href);
  const pageType = pageTypeMapping[page];
  return pageType || page;
};

const getCatalystPage = () => {
  const page = getPage(window.location.href);
  const mappedPage = catalystPageMapping[page];
  return mappedPage || 'nm';
};

const getSearchTerm = () => {
  const regex = new RegExp(`${constants.searchTerm}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' ')).toLowerCase();
};

const getBreadcrumbText = () => {
  const breadcrumbCollection = document.getElementsByClassName('breadcrumb');
  let name = '';
  if (breadcrumbCollection && breadcrumbCollection.length > 0) {
    const breadcrumb = breadcrumbCollection.item(0);
    for (let i = 1; i < breadcrumb.childElementCount; i += 1) {
      let text = breadcrumb.children[i].textContent;
      text = text.split('-').join(': ');
      name += i === breadcrumb.childElementCount - 1 ? text : `${text}: `;
    }
  }
  return name;
};

const getCollectionTitle = () => {
  const collectionHeading = document.getElementsByClassName('collection-title');
  return _.get(collectionHeading, '[0].textContent', '');
};

const getSellerTitle = () => {
  const sellerHeading = document.getElementsByClassName('brand-title-container');
  return _.get(sellerHeading, '[0].textContent', '');
};

const getBrandTitle = () => {
  const brandHeading = document.getElementsByClassName('brand-title-container');
  return _.get(brandHeading, '[0].textContent', '');
};

const pageNameFunctionMapping = {
  noResult: () => {
    const searchTerm = getSearchTerm();
    return `noSearchResult: ${searchTerm}`;
  },
  search: () => {
    const searchTerm = getSearchTerm();
    return `search: ${searchTerm}`;
  },
  buscar: () => {
    const searchTerm = getSearchTerm();
    return `buscar: ${searchTerm}`;
  },
  shop: () => {
    const searchTerm = getSearchTerm();
    return `shop: ${searchTerm}`;
  },
  category: () => {
    const breadcrumbText = getBreadcrumbText();
    return `plp: ${breadcrumbText}`;
  },
  lista: () => {
    const breadcrumbText = getBreadcrumbText();
    return `plp: ${breadcrumbText}`;
  },
  product: () => {
    const breadcrumbText = getBreadcrumbText();
    return `pdp: ${breadcrumbText}`;
  },
  articulo: () => {
    const breadcrumbText = getBreadcrumbText();
    return `pdp: ${breadcrumbText}`;
  },
  collection: () => {
    const collectionTitle = getCollectionTitle();
    return `plp: collection: ${collectionTitle}`;
  },
  seleccion: () => {
    const collectionTitle = getCollectionTitle();
    return `plp: Selección: ${collectionTitle}`;
  },
  seller: () => {
    const sellerTitle = getSellerTitle();
    return `plp: Seller: ${sellerTitle}`;
  },
  vendedor: () => {
    const sellerTitle = getSellerTitle();
    return `plp: Vendedor: ${sellerTitle}`;
  },
  brand: () => {
    const brandTitle = getBrandTitle();
    return `plp: Brand: ${brandTitle}`;
  },
  marca: () => {
    const brandTitle = getBrandTitle();
    return `plp: Marca: ${brandTitle}`;
  },
  default: () => {
    return getPageType();
  },
};

const getPageName = () => {
  const page = getPage(window.location.href);
  const pageNameFunction = pageNameFunctionMapping[page] || pageNameFunctionMapping.default;
  return pageNameFunction(page);
};

export { getBreadcrumbText, getCatalystPage, getPageName, getPageType, getSearchTerm };
