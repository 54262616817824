import css from 'styled-jsx/css';
import { colors, typography } from '../../config/styles';

const SelectedFiltersStyles = css`
  .selected-filters {
    padding: 15px 19px 15px 25px;
  }
  .filter-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  @mixin mobileToTablet {
    .filter-container {
      padding: 16px 0 16px 0;
    }
    :global(.filter-container .chips) {
      padding-top: 15px;
    }
  }
  .chips {
    max-height: 130px;
    overflow-y: scroll;
    padding-bottom: 5px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.black.shade1};
    font-size: ${typography.sm};
    cursor: pointer;
    outline: none;
  }
  i {
    font-size: 14px;
  }
  .divider-line {
    border: 1px solid #f0f0f0;
  }
  /* stylelint-disable length-zero-no-unit */
  .divider-line-container {
    padding: 0px 19px 0 25px;
  }
`;

export default SelectedFiltersStyles;
