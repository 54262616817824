import css from 'styled-jsx/css';
import { icons } from '../../../../../../config/styles';
import { getStoreShade } from '../../../../../../config/styles/colors';
import { svgToDataUri } from '../../../../../../utils/styles/svgMixins';

const ShippingBoxButtonStyles = (store) => {
  return css`
    .shipping-button {
      button {
        display: flex;
        flex-direction: row;
        gap: 4px;
        color: #333;
        font-size: 12px;
        line-height: 16px;
      }

      align-items: center;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #e0e0e0;
      display: inline-flex;
      gap: 4px;
      height: 32px;
      justify-content: center;
      overflow: hidden;
      padding: 8px;
      position: relative;
      width: fit-content;

      &.selected {
        border: 1.5px solid var(--primary-color);
        button {
          font-weight: 700;
        }
      }
      &.selected.tottus {
        border-color: var(--teal-shade1);
      }
      &.omni-exp {
        border-radius: 8px;

        &.selected {
          background-color: ${getStoreShade(store, 1)};
          border-color: ${getStoreShade(store, 5)};
          color: #333;
        }
      }

      &.green-text {
        button {
          color: #30871f;
          font-weight: 700;
        }
      }
      .flash-icon {
        display: flex;
        width: 15.541px;
        height: 18.911px;
        background-image: url('${svgToDataUri(icons.flashIcon)}');
      }
    }
  `;
};

export { ShippingBoxButtonStyles };
