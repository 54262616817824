import apiConfig from '../../../config/api/apiConfig';
import { getHostUrl } from '../../../containers/SearchResults/utils';
import { isWindow } from '../../../utils/deviceChecker';
import httpService from '../../../utils/httpService';
import _ from '../../../utils/LodashImports';

const getAppliedFacetParams = (values) => {
  const paramsObj = values
    .map((value) => value.filters || [])
    .flat(1)
    .reduce((obj, { name, value }) => {
      if (!obj[name]) {
        obj[name] = decodeURIComponent(value.replace(/\+/g, ' ')); // eslint-disable-line no-param-reassign
      } else {
        obj[name] += `::${decodeURIComponent(value.replace(/\+/g, ' '))}`; // eslint-disable-line no-param-reassign
      }
      return obj;
    }, {});
  const nonSelectedFacetValues = values.filter((value) => !value.selected).map((value) => value.facetValue);
  Object.keys(paramsObj).forEach((key) => {
    const newValue = Array.from(new Set(paramsObj[key].split('::')))
      .filter((value) => !nonSelectedFacetValues.includes(value))
      .join('::');
    if (!newValue) {
      delete paramsObj[key];
    } else {
      paramsObj[key] = newValue;
    }
  });
  return paramsObj;
};

const getFacet = async ({ config, appCtx, xlpName, facet, xlpId, facetCount }) => {
  const { regionCode, deviceType, politicalId, priceGroupId, zones } = appCtx;
  const facetName = facet.values[0].facetType || '';
  const store = _.get(appCtx, 'store', null);

  const getRequestParams = () => {
    const params = {
      xlpName,
      xlpId,
      facetName,
      ...getAppliedFacetParams(facet.values),
    };
    if (store) {
      params[encodeURIComponent('store')] = encodeURIComponent(store);
    }
    if (facetCount) {
      params.facetCount = facetCount;
    }
    params.pid = politicalId;
    params.pgid = priceGroupId;
    params.zones = zones;
    return params;
  };

  const getFacetValuesUrl = apiConfig.getFacetValues({
    base: getHostUrl({ config, store, regionCode }),
    siteId: regionCode,
    params: getRequestParams(),
  });
  const response = await httpService(appCtx).get(
    getFacetValuesUrl,
    {
      headers: {
        'Content-Type': 'application/json',
        'x-device-type': deviceType,
      },
    },
    'json'
  );
  const data = _.get(response, 'data.data.facets', []);
  if (!data.length && !facetCount) return facet;
  return data[0];
};

const getfacetsWithUpdatedBrandValues = async (facets, attributes) => {
  const brandFacet = facets.find((f) => f.name === 'Marca');
  if (!brandFacet) {
    return facets;
  }
  const facetWithAllValues = await getFacet({
    ...attributes,
    facet: brandFacet,
  });
  const updatedFacets = [...facets];
  const facetIndex = updatedFacets.findIndex((f) => f.name === 'Marca');
  updatedFacets[facetIndex].values = facetWithAllValues.values;
  return updatedFacets;
};

export { getFacet, getAppliedFacetParams, getfacetsWithUpdatedBrandValues };
