import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import {
  navigateWithUrl,
  navigateWithUrlForBrand,
  navigateWithUrlForCategory,
  navigateWithUrlForCollection,
  navigateWithUrlForSeller,
  navigateWithUrlForShop,
} from '../../utils/RouteHandler';
import WithRouter from '../../utils/WithRouter';
import { isWindow } from '../../utils/deviceChecker';
import { resetDigitalData } from '../Facets/Common/digitalData';
import { facetClick } from '../Facets/FacetEvents';
import { promotedFiltersStyle } from './PromotedFilters.style';

const generateFilterUrl = (filters = [], ignoreFilters = []) => {
  return filters
    .filter((filter) => !ignoreFilters.includes(filter.name))
    .map((filter) => {
      return `${filter.name}=${filter.value}`;
    })
    .join('&');
};

const PromotedFilters = ({ promotedFilter = {}, appCtx, baseFacetsURL, labels, pageName }) => {
  const { store, regionCode } = appCtx;
  const filtersRef = useRef(null);
  const [margin, setMargin] = useState(0);
  const [showLeftSlideIcon, setShowLeftSlideIcon] = useState(false);
  const [showRightSlideIcon, setShowRightSlideIcon] = useState(false);
  const selectedClass = 'mkp-selected';

  const isWhiteLabelExperience = _.get(appCtx, 'siteConfig.toggles.isWhiteLabelExperience', false);

  const facet = { ...promotedFilter };
  facet.values = facet.values.map((value) => {
    return {
      ...value,
      url: generateFilterUrl(value.filters, ['f.product.parentCategoryPaths.pathSearch']),
    };
  });

  const id = WithRouter('id');
  const name = WithRouter('name');

  const changeURL = (url) => {
    if (pageName === 'category') {
      navigateWithUrlForCategory({
        site: appCtx.tenant,
        url,
        onRouteChange: () => {
          if (isWindow()) {
            window.scrollTo(0, 0);
          }
          appCtx.setBusy(true);
        },
        categoryId: id,
        categoryName: name,
        store,
        regionCode,
        isWhiteLabelExperience,
      });
    } else if (pageName === 'collection') {
      navigateWithUrlForCollection({
        site: appCtx.tenant,
        url,
        onRouteChange: () => {
          if (isWindow()) {
            window.scrollTo(0, 0);
          }
          appCtx.setBusy(true);
        },
        collectionId: id,
        store,
        regionCode,
        isWhiteLabelExperience,
      });
    } else if (pageName === 'seller') {
      navigateWithUrlForSeller({
        regionCode,
        site: appCtx.tenant,
        url,
        onRouteChange: () => {
          if (isWindow()) {
            window.scrollTo(0, 0);
          }
          appCtx.setBusy(true);
        },
        sellerName: id,
        store,
        isWhiteLabelExperience,
      });
    } else if (pageName === 'brand') {
      navigateWithUrlForBrand({
        regionCode,
        site: appCtx.tenant,
        url,
        onRouteChange: () => {
          if (isWindow()) {
            window.scrollTo(0, 0);
          }
          appCtx.setBusy(true);
        },
        brandName: id,
        store,
      });
    } else if (pageName === 'shop') {
      navigateWithUrlForShop({
        site: appCtx.tenant,
        url,
        onRouteChange: () => {
          if (isWindow()) {
            window.scrollTo(0, 0);
          }
          appCtx.setBusy(true);
        },
        store,
        regionCode,
        shopId: id,
        isWhiteLabelExperience,
      });
    } else {
      navigateWithUrl({
        site: appCtx.tenant,
        url,
        onRouteChange: () => {
          if (isWindow()) {
            window.scrollTo(0, 0);
          }
          appCtx.setBusy(true);
        },
        store,
        regionCode,
        isWhiteLabelExperience,
      });
    }
    resetDigitalData();
  };

  const onFacetChange = (url, value, filterType) => {
    facetClick();
    if (filterType === 'SINGLE_SELECT_AS_LINK') {
      if (pageName === 'category') {
        navigateWithUrlForCategory({
          site: appCtx.tenant,
          url,
          onRouteChange: () => {
            appCtx.setBusy(true);
          },
          categoryId: value.id,
          categoryName: value.title,
          regionCode,
        });
      }
    } else {
      const urls = [];
      if (baseFacetsURL) {
        urls.push(baseFacetsURL);
      }
      if (url) {
        urls.push(url);
      }
      changeURL(urls.join('&'));
    }
  };

  useEffect(() => {
    setShowRightSlideIcon(Boolean(filtersRef.current.scrollWidth - filtersRef.current.clientWidth));
    setShowLeftSlideIcon(false);
    setMargin(0);
  }, [promotedFilter]);

  const scrollFilters = (newMargin) => {
    const totalScrollWidth = filtersRef.current.scrollWidth - filtersRef.current.clientWidth;
    setMargin(newMargin);
    setShowLeftSlideIcon(Boolean(newMargin));
    setShowRightSlideIcon(Boolean(totalScrollWidth - newMargin));
  };

  const scrollFiltersToLeft = () => {
    const newScrollMargin = margin - 200 > 0 ? margin - 200 : 0;
    scrollFilters(newScrollMargin);
  };

  const scrollFiltersToRight = () => {
    const totalScrollWidth = filtersRef.current.scrollWidth - filtersRef.current.clientWidth;
    const newScrollMargin = margin + 200 < totalScrollWidth ? margin + 200 : totalScrollWidth;
    scrollFilters(newScrollMargin);
  };

  let filterJustifyContent = 'start';

  if (!showRightSlideIcon && !showLeftSlideIcon && appCtx.deviceType === 'desktop') {
    filterJustifyContent = 'center';
  }
  const headerClass = 'mkp-header';
  const filterClass = 'mkp-filter';
  const arrowPrevClass = 'mkp-prev';
  const arrowNextClass = 'mkp-next';
  return (
    <div className="PromotedFilter-container">
      <div className={`PromotedFilter-container-${headerClass}`}>
        <span>
          {labels.PROMOTED_FILTER_FOR}
          <b>{facet.name}</b>
        </span>
      </div>
      <div
        ref={filtersRef}
        style={{
          marginLeft: `-${margin}px`,
          justifyContent: filterJustifyContent,
        }}
        className="PromotedFilter-container-filters"
      >
        {showLeftSlideIcon && (
          <button
            id="promoted-filter-scroll-left"
            type="button"
            onClick={scrollFiltersToLeft}
            className={`PromotedFilter-container-filters-${arrowPrevClass}`}
          >
            <i className="csicon-arrow_left">
              <span className="sr-only">Slide left</span>
            </i>
          </button>
        )}
        {facet.values.map((value, index) => {
          return (
            <span
              id={`${value.title}-${index}`}
              key={value.url}
              className={`PromotedFilter-container-filters-${filterClass} ${value.selected ? selectedClass : ''}`}
              onClick={() => {
                onFacetChange(value.url, value, facet.type);
              }}
              role="button"
              onKeyDown={() => {}}
              tabIndex="0"
            >
              {value.title || ''}
            </span>
          );
        })}
        {showRightSlideIcon && (
          <button
            id="promoted-filter-scroll-right"
            type="button"
            onClick={scrollFiltersToRight}
            className={`PromotedFilter-container-filters-${arrowNextClass}`}
          >
            <i className="csicon-arrow_right">
              <span className="sr-only">Slide right</span>
            </i>
          </button>
        )}
      </div>
      <style jsx>{promotedFiltersStyle}</style>
    </div>
  );
};

PromotedFilters.defaultProps = {
  pageName: 'category',
};

PromotedFilters.propTypes = {
  promotedFilter: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  baseFacetsURL: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  pageName: PropTypes.string,
};

export default withApplicationContext(withLabels(PromotedFilters));
export { PromotedFilters };
