import { isTottusStandalone } from '@digital-retail/store-manager';
import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import constants from '../../../../../../config/constants';
import { withApplicationContext } from '../../../../../../utils/ApplicationContext';
import noop from '../../../../../../utils/noop';
import { ShippingBoxButtonStyles } from './ShippingBoxButton.style';

const ShippingBoxButton = (props) => {
  const { id, isChecked, onChange, manageState, hasTimeConstraint, title, icon, appCtx } = props;
  const { store, siteConfig } = appCtx;
  const [isSelected, setSelected] = useState(isChecked);
  const styles = ShippingBoxButtonStyles(store);
  const handleOnChange = () => {
    if (manageState) {
      setSelected(!isSelected);
    }
    onChange({
      isSelected: !isSelected,
    });
  };
  useEffect(() => {
    setSelected(isChecked);
  }, [props]);

  function truncateText(str, charLimit, addEllipsis) {
    if (!str || str.length < charLimit) return str;
    return str.substr(0, charLimit).trim() + (addEllipsis === true ? '...' : '');
  }

  const omni = siteConfig?.toggles?.isNewOmniChannelExperience ?? true;

  return (
    <>
      <div
        className={clsx('shipping-button', {
          selected: isSelected,
          tottus: isTottusStandalone(store),
          'omni-exp': omni,
          'green-text': !omni && hasTimeConstraint,
        })}
      >
        <button id={id} type="button" onClick={handleOnChange}>
          {icon && <i className={icon} />}
          {truncateText(title, constants.FACETS.VALUE_TEXT_CHARCOUNT, true)}
        </button>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

ShippingBoxButton.defaultProps = {
  id: '',
  onChange: noop,
  isChecked: false,
  title: '',
  icon: '',
  manageState: true,
  hasTimeConstraint: false,
};

ShippingBoxButton.propTypes = {
  id: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  manageState: PropTypes.bool,
  hasTimeConstraint: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.string,
  appCtx: PropTypes.object.isRequired,
};

const ShippingBoxButtonWithApp = withApplicationContext(ShippingBoxButton);

export { ShippingBoxButtonWithApp, ShippingBoxButton };
