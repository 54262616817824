import { isSodimacStandalone, isTottusStandalone } from '@digital-retail/store-manager';
import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { linkButtonStyle } from './LinkButton.style';

const LinkButton = ({ children, type, href, size, disabled, target, id, appCtx: { store } }) => {
  const isTottusSA = isTottusStandalone(store);
  const isSodimacSA = isSodimacStandalone(store);
  const url = disabled ? '#' : href;
  return (
    <a
      id={id}
      href={url}
      className={clsx(`button button-${type} button-${size}`, {
        'button-disabled': disabled,
        'sodimac-theme': isSodimacSA,
        'tottus-theme': isTottusSA,
      })}
      target={target}
    >
      {children}
      <style jsx>{linkButtonStyle}</style>
    </a>
  );
};

const LinkLabel = ({ children, type, href, size, disabled, target, id }) => {
  const url = disabled ? '#' : href;
  return (
    <a
      href={url}
      className={`link-button button-${type} button-${size} ${disabled ? 'button-disabled' : ''}`}
      target={target}
      id={id}
    >
      {children}
      <style jsx>{linkButtonStyle}</style>
    </a>
  );
};

LinkLabel.defaultProps = {
  children: null,
  type: 'primary',
  href: '#',
  size: '',
  disabled: false,
  target: '',
  id: '',
};

LinkLabel.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'default']),
  href: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  target: PropTypes.string,
  id: PropTypes.string,
};

LinkButton.defaultProps = {
  children: null,
  type: 'primary',
  href: '#',
  size: 'medium',
  disabled: false,
  target: '',
  id: 'linkButton',
};

LinkButton.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'rebranding']),
  href: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  target: PropTypes.string,
  id: PropTypes.string,
  appCtx: PropTypes.object.isRequired,
};

export { LinkLabel };

export default withApplicationContext(LinkButton);
