import { isTottusStandalone } from '@digital-retail/store-manager';
import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../config/constants';
import { pumApplicableRegion } from '../../config/tenant/tenant.config';
import { withApplicationContext } from '../../utils/ApplicationContext';
import _ from '../../utils/LodashImports';
import {
  ALLOWED_CONFIGS_FOR_PRICE_BREAK,
  ALLOWED_CONFIGS_FOR_UNIT_PRICES,
  formatPriceRange,
} from '../../utils/PriceHelpers';
import DiscountBadge from '../Badges/DiscountBadge/DiscountBadge';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import priceStylesConfig, {
  newPriceStylesConfig,
  newUnitPriceStyleConfig,
  oldPriceStyles,
  priceSizes,
  unitPriceStylesConfig,
} from './PriceStyleConfig';
import { pricesStyles } from './Prices.style';

const Prices = ({
  priceList,
  productId,
  isTabApplicable,
  page,
  layout,
  isPumAvailable,
  pumQuantity,
  pumUnit,
  variant,
  labels,
  isConnectProduct,
  operators,
  selectedOperatorId,
  appCtx,
  discountBadge,
  priceCount,
  isOutOfStock,
  product,
}) => {
  const { deviceType, store, regionCode = 'cl' } = appCtx;
  const shouldBreakFirstPrice = ALLOWED_CONFIGS_FOR_PRICE_BREAK.includes(variant);
  const pricesCount = (priceList || []).length;
  const isNormalPriceCrossedOut = _.get(appCtx, 'siteConfig.toggles.isNormalPriceCrossedOut', false);
  const isNewPriceApplicable = _.get(appCtx, 'siteConfig.toggles.isNewPriceApplicable', false);
  const isTottusSA = isTottusStandalone(store);
  const showUnitPrice = !!ALLOWED_CONFIGS_FOR_UNIT_PRICES.includes(variant);
  const showUnitPriceXLP = showUnitPrice;
  const cmrLogoOnTop =
    page === constants.PAGES.PDP ||
    page === constants.PAGES.SLP ||
    page === constants.PAGES.PLP ||
    page === constants.PAGES.PLANS ||
    ([page, variant].includes(constants.PAGES.CART) && isNewPriceApplicable);

  let classCMRContainer = 'cmr-icon-container';
  const hasTwoPricesPlanPage = !!(page === constants.PAGES.PLANS && priceList.length === 2);
  const storeAndRegionCodeClassname = `${store || ''} ${regionCode}`;

  const getPriceImportance = (icon, crossed) => {
    if (icon === 'cmr-icon') {
      return 'high';
    }

    if (crossed) {
      return isNewPriceApplicable ? 'medium' : 'low';
    }

    return isNewPriceApplicable ? 'medium' : '';
  };

  const getPriceConfig = (config, icon, crossed = false, isSep = false, hasUnitStyles = false, pricePosition = 0) => {
    const priceConfig = hasUnitStyles ? newUnitPriceStyleConfig : newPriceStylesConfig;

    const variantPriceConfig = priceConfig[variant];
    const lineHeight = _.get(variantPriceConfig, `lineHeight[${pricePosition}]`, '');
    const lineHeightCrossed = _.get(variantPriceConfig, `lineHeight[${2}]`, '');
    if (isNormalPriceCrossedOut) {
      return {
        ...config,
        importance: isSep && icon ? '' : getPriceImportance(icon, crossed),
        size: isNewPriceApplicable && crossed ? variantPriceConfig.crossed : config.size,
        lineHeight: isNewPriceApplicable && !crossed ? lineHeight : lineHeightCrossed,
      };
    }

    return isNewPriceApplicable
      ? {
          ...config,
          importance: isSep && icon ? '' : getPriceImportance(icon, crossed),
          lineHeight,
        }
      : config;
  };

  const getUnitPrice = (unitPrice, symbol) => {
    if (!unitPrice || !showUnitPrice) {
      return '';
    }
    const { price, unit } = unitPrice;
    return price.map((p) => `${symbol} ${p} ${unit}`).join(' - ');
  };

  const getUnitForSale = (unitPrice) => {
    if (!unitPrice || !showUnitPrice) {
      return '';
    }
    const { unit } = unitPrice;
    return unit && typeof unit === 'string' ? unit.toLowerCase() : '';
  };

  const getPrice = (price, symbol) => {
    return price.map((p) => `${symbol} ${p}`).join(' - ');
  };

  const getPerProductPrice = (price) => {
    const cleanPrice = Number(`${price}`.replace(/[,.]/g, ''));
    const decimalSeparator = constants.DECIMAL_SEPARATOR[regionCode];
    const dotSeparator = constants.DOT_SEPARATOR[regionCode];

    const pumPrice = `${(cleanPrice / pumQuantity).toFixed(2)}`;

    const [num, dec] = pumPrice.split('.');

    return [num.replace(/\B(?=(\d{3})+(?!\d))/g, dotSeparator), dec].join(decimalSeparator);
  };

  const isPumValid = pumApplicableRegion[regionCode] && page === constants.PAGES.PDP && isPumAvailable;

  const getPriceAttributes = (price, type) => {
    const priceType = type && type.replace('Price', '');
    return { [`data-${priceType}-price`]: price };
  };

  const priceType = {
    PDP: 'pdp-prices',
    POD: 'pod-prices',
    PLP: 'pod-prices',
    CART: 'cart-prices',
    SLP: 'pod-prices',
  };

  const connectOperatorName = () => {
    if (!isConnectProduct || !selectedOperatorId) return null;
    const operator = operators.find((op) => op.id === selectedOperatorId);
    if (operator)
      return (
        <p className="operator-name">
          ({operator.name})<style jsx>{pricesStyles}</style>
        </p>
      );
    return null;
  };

  const getPriceStyle = (hasUnitStyles = false) => {
    if (isNewPriceApplicable) {
      if (hasUnitStyles) {
        return newUnitPriceStyleConfig[variant][priceList.length && priceList[0].icons ? 'cmr' : 'noCmr'];
      }
      return newPriceStylesConfig[variant][pricesCount && priceList[0].icons ? 'cmr' : 'noCmr'];
    }

    if (isNormalPriceCrossedOut) {
      if (hasUnitStyles) {
        return unitPriceStylesConfig[variant][priceList.length && priceList[0].icons ? 'cmr' : 'noCmr'];
      }
      return priceStylesConfig[variant][pricesCount && priceList[0].icons ? 'cmr' : 'noCmr'];
    }

    return oldPriceStyles[variant];
  };

  const priceConfig = (hasUnitStyles) => getPriceStyle(hasUnitStyles);
  const measurement = product?.measurements?.unit;
  return (
    <div
      className={clsx('prices', {
        'price-with-tab': isTabApplicable,
        [`prices-${layout}`]: true,
        isOutOfStock,
      })}
      id={`testId-pod-prices-${productId}`}
    >
      {priceList && (
        <ol
          className={clsx(`ol-${layout} ${priceType[page]} fa--prices`, {
            'li-separation': isNewPriceApplicable,
          })}
        >
          {priceList.map(({ symbol, price, icons = [], label = '', type, crossed, unitPrice }, i) => {
            const isCmr = icons.length > 0 && icons === 'cmr-icon';
            const unitPriceValue = getUnitPrice(unitPrice, symbol);
            const unitForSale = getUnitForSale(unitPrice);
            const hasUnitStyles = !!showUnitPrice && !!unitPriceValue;
            classCMRContainer =
              hasTwoPricesPlanPage && isCmr ? `${classCMRContainer} cmr-icon-container-two-prices` : classCMRContainer;
            const isFirstPrice = i === 0;
            const isFirstPriceFormatNeeded = isFirstPrice && shouldBreakFirstPrice && price.length > 1;
            const priceToRender = showUnitPriceXLP && unitPrice ? unitPrice.price : price;
            const pricesToRender = isFirstPriceFormatNeeded
              ? formatPriceRange(variant, priceToRender, pricesCount, isCmr, symbol.trim())
              : [
                  `${getPrice(priceToRender, symbol)} ${!label ? '' : label} ${
                    priceConfig(hasUnitStyles)[i].size || ''
                  }`,
                ];

            const dBadge =
              isTottusSA && discountBadge && !isCmr
                ? {
                    ...discountBadge,
                    styles: {
                      backgroundColor: 'var(--primary-color)',
                      textColor: 'var(--secondary-color)',
                    },
                  }
                : discountBadge;
            return (
              <li
                {...getPriceAttributes(price, type)}
                key={priceToRender}
                className={clsx(`prices-${i}`, {
                  'mb-2px':
                    isNewPriceApplicable && deviceType === constants.DEVICE_MOBILE && variant === constants.PAGES.CART,
                })}
              >
                <div className={classCMRContainer} data-variant={variant}>
                  {cmrLogoOnTop && icons.length > 0 && icons === 'cmr-icon' && (
                    <i
                      className={clsx(`unica-cmr-icon pdp-icon ${storeAndRegionCodeClassname}`, {
                        'cmr-icon-small':
                          isNewPriceApplicable &&
                          deviceType === constants.DEVICE_MOBILE &&
                          variant === constants.PAGES.CART,
                        'pdp-connect-tab-plan': priceCount && priceCount === 1,
                      })}
                    />
                  )}
                  {pricesToRender.map((p, priceToRenderIndex) => (
                    <BodyCopy
                      key={`${productId}-${p}`}
                      {...getPriceConfig(
                        priceSizes[priceConfig(hasUnitStyles)][i],
                        icons,
                        crossed,
                        false,
                        hasUnitStyles,
                        i
                      )}
                      crossed={crossed}
                      width={
                        isFirstPriceFormatNeeded && pricesToRender.length > 1 && priceToRenderIndex === 0
                          ? 'width-100'
                          : ''
                      }
                    >
                      {p}
                      {hasUnitStyles && <>&nbsp;{unitForSale}</>}
                    </BodyCopy>
                  ))}
                  {measurement && (
                    <BodyCopy
                      {...getPriceConfig(
                        priceSizes[priceConfig(hasUnitStyles)][i],
                        icons,
                        crossed,
                        false,
                        hasUnitStyles,
                        i
                      )}
                      crossed={crossed}
                    >
                      {' '}
                      {measurement}
                    </BodyCopy>
                  )}{' '}
                  {!cmrLogoOnTop && isCmr && (
                    <i className={clsx(`unica-cmr-icon ${storeAndRegionCodeClassname}`, {})} />
                  )}
                  {isFirstPrice && isConnectProduct && (
                    <div className={isCmr ? 'margin-left-6' : ''}>{connectOperatorName()}</div>
                  )}
                  {isFirstPrice && dBadge && <DiscountBadge badge={dBadge} />}
                </div>
                {isPumValid && (
                  <div className="per-product-price">
                    {`${pumUnit} ${labels.PUM_AFFIX} ${symbol} ${getPerProductPrice(priceToRender)}`}
                  </div>
                )}
              </li>
            );
          })}
        </ol>
      )}
      <style jsx>{pricesStyles}</style>
    </div>
  );
};

Prices.defaultProps = {
  productId: '',
  variantId: '',
  offeringId: '',
  isTabApplicable: false,
  page: 'POD',
  layout: '4_GRID',
  isPumAvailable: false,
  pumQuantity: 1,
  variant: 'PDP_MAIN',
  pumUnit: '',
  labels: {},
  operators: [],
  selectedOperatorId: undefined,
  isConnectProduct: false,
  appCtx: {},
  discountBadge: undefined,
  priceCount: undefined,
  isOutOfStock: false,
};

Prices.propTypes = {
  priceList: PropTypes.arrayOf(PropTypes.object).isRequired,
  productId: PropTypes.string,
  variantId: PropTypes.string,
  offeringId: PropTypes.string,
  isTabApplicable: PropTypes.bool,
  page: PropTypes.oneOf(['POD', 'PDP', 'CART', 'SLP', 'PLANS']),
  layout: PropTypes.string,
  isPumAvailable: PropTypes.bool,
  pumQuantity: PropTypes.number,
  variant: PropTypes.string,
  pumUnit: PropTypes.string,
  labels: PropTypes.object,
  isConnectProduct: PropTypes.bool,
  operators: PropTypes.arrayOf(PropTypes.object),
  selectedOperatorId: PropTypes.string,
  appCtx: PropTypes.object,
  discountBadge: PropTypes.object,
  priceCount: PropTypes.number,
  isOutOfStock: PropTypes.bool,
  product: PropTypes.object.isRequired,
};

export default withApplicationContext(Prices);
export { Prices };
