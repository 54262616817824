import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import _ from '../../../utils/LodashImports';
import Multiselect from '../../ui/Multiselect/Multiselect';
import ShippingList from './ShippingList/ShippingList';

const ShippingFacet = (props) => {
  const {
    item = { values: [] },
    freeShippingFacet,
    groupType,
    onFacetChange,
    baseFacetsURL,
    changeURL,
    searchInFacetsLabel,
    checkboxType,
    normalBorderClass,
    selectedBorderClass,
    isFacetCountHidden,
    appCtx,
  } = props;
  const { deviceType } = appCtx;
  const isNewOmniChannelExperience = _.get(appCtx, 'siteConfig.toggles.isNewOmniChannelExperience', true);

  const content = () => {
    return (
      <Multiselect
        variant="shipping-facet"
        groupType={groupType}
        item={item}
        onFacetChange={onFacetChange}
        searchInFacetsLabel={searchInFacetsLabel}
        checkboxType={checkboxType}
        selectedBorderClass={selectedBorderClass}
        normalBorderClass={normalBorderClass}
        isFacetCountHidden={isFacetCountHidden}
      />
    );
  };
  if (!isNewOmniChannelExperience && deviceType !== 'desktop') {
    return content();
  }

  return (
    <ShippingList
      item={item}
      onFacetChange={onFacetChange}
      baseFacetsURL={baseFacetsURL}
      changeURL={changeURL}
      freeShippingFacet={freeShippingFacet}
    />
  );
};

ShippingFacet.defaultProps = {
  searchInFacetsLabel: '',
  onFacetChange: noop,
  groupType: '',
  checkboxType: 'succes',
  selectedBorderClass: '',
  normalBorderClass: '',
  isFacetCountHidden: false,
  freeShippingFacet: { values: [] },
  changeURL: noop,
  baseFacetsURL: '',
};

ShippingFacet.propTypes = {
  searchInFacetsLabel: PropTypes.string,
  baseFacetsURL: PropTypes.string,
  item: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  onFacetChange: PropTypes.func,
  changeURL: PropTypes.func,
  groupType: PropTypes.oneOf(['', 'availability']),
  checkboxType: PropTypes.oneOf('mkp-succes', 'succes', ''),
  selectedBorderClass: PropTypes.string,
  normalBorderClass: PropTypes.string,
  isFacetCountHidden: PropTypes.bool,
  freeShippingFacet: PropTypes.shape({ facets: PropTypes.array }),
};

export default withApplicationContext(ShippingFacet);
export { ShippingFacet };
