import css from 'styled-jsx/css';
import { icons } from '../../config/styles';
import { getStoreShade } from '../../config/styles/colors';
import { svgReplaceColor, svgToDataUri } from '../../utils/styles/svgMixins';

const ShippingFacetsBarStyles = (store) => {
  const shadePrimary = getStoreShade(store, 10);
  const shadeActiveBorder = getStoreShade(store, 5);
  const shadeActiveBackground = getStoreShade(store, 1);
  return css`
    .shipping-facets-bar {
      width: 100%;
      overflow-x: scroll;
      scrollbar-width: none;
    }
    .shipping-facets-bar-scroll {
      display: flex;
      flex-wrap: nowrap;
      padding: 0 18px;
      gap: 8px;
    }

    .shipping-facets-bar-button {
      display: flex;
      align-items: center;
      padding: 4px 8px;
      gap: 4px;
      border-radius: 8px;
      border: solid 1px #343e49;
      color: #343e49;
      font-weight: 700;
      font-size: 12px;

      &.event-button {
        padding-right: 10px;
      }
      span {
        white-space: nowrap;
      }
      i {
        font-size: 10px;
      }
    }
    .shipping-facets-bar-button.active {
      border-color: ${shadeActiveBorder};
      background-color: ${shadeActiveBackground};
      span,
      i {
        color: ${shadePrimary};
      }
    }
    .csicon-arrow_down_small,
    .csicon-arrow_up_small {
      margin-left: 5px;
    }
    .csicon-disponibilidad-mkp {
      margin-right: 4px;
      width: 24px;
      font-size: 16px;
      height: 20px;
      background-image: url('${svgToDataUri(icons.store)}');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .shipping-facets-bar-button.active .csicon-disponibilidad-mkp {
      background-image: url('${svgToDataUri(svgReplaceColor(icons.store, shadePrimary, '#343E49'))}');
    }
    .csicon-event {
      margin-right: 0;
      width: 24px;
      font-size: 16px;
      height: 20px;
      background-image: url('${svgToDataUri(icons.event)}');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .shipping-facets-bar-button.active .csicon-event {
      background-image: url('${svgToDataUri(svgReplaceColor(icons.event, shadePrimary, '#333333'))}');
    }
    .csicon-filter {
      margin-right: 0;
      width: 24px;
      font-size: 16px;
      height: 20px;
      background-image: url('${svgToDataUri(icons.filtersIcon)}');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
    .shipping-facets-bar-button.active .csicon-filter {
      background-image: url('${svgToDataUri(svgReplaceColor(icons.filtersIcon, shadePrimary, '#343E49'))}');
    }
    .arrow-down {
      height: 16px;
      width: 16px;
      background-image: url('${svgToDataUri(icons.arrowDownSmall)}');
    }
    .flash-icon {
      display: flex;
      width: 15.541px;
      height: 18.911px;
      background-image: url('${svgToDataUri(icons.flashIcon)}');
    }
  `;
};

export default ShippingFacetsBarStyles;
