import constants from '../config/constants';

const cartAlertsAndErrorSeggregator = (alerts, siteConfig, statusCode = 200) => {
  const errors = [];
  const limitAlerts = {};

  const statusCodeAllowedForErrors = siteConfig?.textDictionary?.STATUS_CODE_ALLOWED_FOR_ERRORS ?? [
    400, 401, 403, 404, 500, 502, 503,
  ];

  const commerceApiErrors = siteConfig?.configurations?.commerceApiErrors;
  const commerceLimitApiAlerts = siteConfig?.configurations?.commerceLimitApiAlerts;

  alerts.forEach((alert) => {
    const alertCode = alert.alertCode.toUpperCase() || '';
    const { cartLineId = 'basket' } = alert;
    const limitAlert = commerceLimitApiAlerts?.[alertCode] ?? '';
    let error;

    if (alertCode === 'AL022' && !statusCodeAllowedForErrors.includes(statusCode)) {
      error = undefined;
    } else {
      error = commerceApiErrors?.[alertCode] ?? '';
    }

    if (error) {
      errors.push({
        message: error,
        code: alertCode,
      });
    }
    if (limitAlert) {
      limitAlerts[cartLineId] = {
        message: limitAlert,
        code: alertCode,
      };
    }
  });
  return {
    mappedErrors: errors.length > 0 ? errors : undefined,
    limitAlerts: Object.keys(limitAlerts).length > 0 ? limitAlerts : undefined,
  };
};

const getErrorMessage = (errors, siteConfig) => {
  const commerceApiErrors = siteConfig?.configurations?.commerceApiErrors;

  const errorResponse = errors[0];
  const alertCode = errorResponse?.code ?? '';
  const defaultMessage = commerceApiErrors?.CART_DEFAULT_MESSAGE ?? constants.CART_DEFAULT_MESSAGE;

  return commerceApiErrors?.[alertCode] ?? defaultMessage;
};

export { cartAlertsAndErrorSeggregator, getErrorMessage };
