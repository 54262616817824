import css from 'styled-jsx/css';

const styles = css`
  .facets-with-switch-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @mixin mobileToTablet {
      width: 100%;
      margin-top: 2px;
      margin-bottom: 7px;
    }
  }
`;

export default styles;
