import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import _ from '../../../../utils/LodashImports';
import noop from '../../../../utils/noop';
import { capitalizeText } from '../../../Pod/podUtils';
import StoresModal from '../StoresModal/StoresModal';
import { ShippingBox } from './ShippingBox/ShippingBox';
import { shippingListStyle } from './ShippingList.style';

const ShippingList = (props) => {
  const [showStoresModal, setShowStoresModal] = useState(false);
  const [expandedState, setExpandedState] = useState(false);
  const { item, onFacetChange, appCtx, baseFacetsURL, changeURL, freeShippingFacet } = props;
  const { deviceType } = appCtx;
  const { textDictionary = {} } = appCtx?.siteConfig || {};
  const {
    SAME_DAY_DELIVERY_LABEL = 'Si compras hasta las',
    SAME_DAY_DELIVERY_LABEL_AMOUNT = '16:00 horas',
    SAME_DAY_DELIVERY_TIMELINE = 'llega hoy',
    HOME_SHIPPING = 'Envío',
    WITHDRAWAL_AT_STORE = 'Retiro',
    BUY_AT_STORE_LABEL = 'Disponible en tienda',
    WITHDRAW_AT_STORE_LABEL_AMOUNT = '16:00 horas',
    WITHDRAWAL_AT_STORE_TIMELINE = 'retira hoy',
    WITHDRAWAL_AT_STORE_LABEL = 'Si compras hasta las',
  } = textDictionary;
  const shippingMap = {
    hd: [],
    cc: [],
    pks: [],
    bas: [],
  };
  const facilityOperatorsforBAS = new Set();
  item.values.forEach((v) => {
    if (/.hd_/.test(v.facetType)) {
      shippingMap.hd.push(v);
    } else if (/.cc_/.test(v.facetType)) {
      shippingMap.cc.push(v);
    } else if (/.pks_/.test(v.facetType)) {
      shippingMap.pks.push(v);
    } else if (/.bas_/.test(v.facetType)) {
      shippingMap.bas.push(v);
      facilityOperatorsforBAS.add(v.facilityOperator);
    }
  });

  const isNewOmniChannelExperience = _.get(appCtx, 'siteConfig.toggles.isNewOmniChannelExperience', true);
  const storesLine = shippingMap.pks.map((pksStore) => pksStore.title).join(', ');
  const hasHD = shippingMap.hd.length > 0 || freeShippingFacet?.values?.length > 0;
  const hasCC = shippingMap.cc.length > 0;
  const hasBAS = shippingMap.bas.length > 0;

  const showLabel = (shippingMapType) => {
    return shippingMapType.some((mapType) => mapType?.facetType?.includes('same_day'));
  };

  const showHDLabelDetails = !isNewOmniChannelExperience && showLabel(shippingMap.hd);
  const showCCLabelDetails = !isNewOmniChannelExperience && showLabel(shippingMap.cc);

  const storesMap = Array.from(facilityOperatorsforBAS).map((f) => {
    return {
      title: `Tiendas ${capitalizeText(f)}`,
      iconClassName: `facility-icon ${f.toLowerCase()}`,
      facets: expandedState
        ? shippingMap.bas.filter((facet) => facet.facilityOperator === f)
        : shippingMap.bas.filter((facet) => facet.facilityOperator === f).slice(0, 4),
    };
  });

  const onExpandhandler = () => {
    if (deviceType === 'mobile') {
      setExpandedState((prev) => !prev);
      return;
    }
    setShowStoresModal(true);
  };

  return (
    <div className="shipping-list-container">
      {hasHD && (
        <ShippingBox
          label={HOME_SHIPPING}
          labelDetails={SAME_DAY_DELIVERY_LABEL}
          labelAmount={SAME_DAY_DELIVERY_LABEL_AMOUNT}
          showLabel={showHDLabelDetails}
          shippingMap={shippingMap.hd}
          freeShippingFacet={freeShippingFacet}
          onFacetChange={onFacetChange}
          type="home_delivery"
          deviceType={deviceType}
          timeline={SAME_DAY_DELIVERY_TIMELINE}
        />
      )}
      {hasHD && hasCC && <div className="divider-line" />}
      {hasCC && (
        <ShippingBox
          label={WITHDRAWAL_AT_STORE}
          labelDetails={WITHDRAWAL_AT_STORE_LABEL}
          labelAmount={WITHDRAW_AT_STORE_LABEL_AMOUNT}
          showLabel={showCCLabelDetails}
          shippingMap={shippingMap.cc}
          onFacetChange={onFacetChange}
          type="withdraw_at_store"
          storesLine={storesLine}
          deviceType={deviceType}
          timeline={WITHDRAWAL_AT_STORE_TIMELINE}
        />
      )}
      {(hasHD || hasCC) && hasBAS && <div className="divider-line" />}
      {hasBAS && (
        <>
          <ShippingBox
            label={BUY_AT_STORE_LABEL}
            storesMap={storesMap}
            onFacetChange={onFacetChange}
            type="buy_at_store"
          />
          {shippingMap.bas.length > 4 && (
            <button className="show-more-stores-toggle" type="button" onClick={onExpandhandler}>
              <i className={`${expandedState ? 'minus' : 'plus'}`} />
              {!expandedState && <p>Mostrar más</p>}
              {expandedState && <p>Mostrar menos</p>}
            </button>
          )}
          {showStoresModal && (
            <StoresModal
              facetValue="f.shipping.bas_stores"
              initialStoresList={shippingMap.bas}
              showModal={showStoresModal}
              onFacetChange={onFacetChange}
              setShowModal={setShowStoresModal}
              textDictionary={textDictionary}
              baseFacetsURL={baseFacetsURL}
              changeURL={changeURL}
            />
          )}
        </>
      )}
      <style jsx>{shippingListStyle}</style>
    </div>
  );
};

ShippingList.defaultProps = {
  item: { values: [] },
  onFacetChange: noop,
  appCtx: {},
  baseFacetsURL: {},
  changeURL: noop,
  freeShippingFacet: {},
};

ShippingList.propTypes = {
  baseFacetsURL: PropTypes.object,
  changeURL: PropTypes.func,
  freeShippingFacet: PropTypes.object,
  item: PropTypes.object,
  onFacetChange: PropTypes.func,
  appCtx: PropTypes.object,
};

export default withApplicationContext(ShippingList);
export { ShippingList };
