import css from 'styled-jsx/css';
import { colors, typography } from '../../../../config/styles';
import { svgUrl } from '../../../../utils/staticUrls';

const LinearPodMobileStyles = css`
  .pod {
    display: flex;
    flex-direction: row;
    padding: 18px 15px 10px;
    flex: 1;
    border-left: none;
    background-color: ${colors.white.shade1};
    position: relative;
    height: 100%;
    width: 100%;
    .link-container {
      width: 100%;
    }
  }

  .section-head {
    width: 30.15%;
    min-width: 105px;
    padding-right: 15px;
  }

  .section-body {
    flex: 1;
  }
  .btn-text {
    display: flex;
    align-items: center;
  }
  div.pod-action-rebranding {
    width: 158px;
  }
  .pod-link {
    display: flex;
    width: inherit;
    text-decoration: none;
    color: inherit;
    margin-bottom: 9px;
  }

  .pod-subTitle {
    display: block;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .pod-rating {
    display: flex;
    align-items: center;
  }

  .title-ratings-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  :global(.swatch--container) {
    justify-content: center;
  }

  :global(.pod-badges) {
    margin-top: 15px;
  }

  .pod-title {
    text-transform: uppercase;
    &.title-rebrand {
      font-size: 13px;
      line-height: 12px;
      color: ${colors.gray.shade44};
      letter-spacing: 0;
    }
  }
  .subTitle-rebrand {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.gray.shade40};
    padding-right: 25px;
    display: block;
  }
  .pod-action {
    margin-top: 10px;
  }

  .pod-button-add-to-cart {
    display: flex;
    justify-content: center;
  }
  .pod-button-add-to-cart-spinner {
    margin-right: 8px;
  }

  .pod-action-LIST {
    width: 200px;
    height: 35px;
  }

  .pod-sellerText {
    font-size: 13px;
    color: #888;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 224px;
    line-height: 16px;
    margin-bottom: 16px;
    display: inline-block;

    @mixin smallerMobile {
      max-width: 180px;
    }
    &.seller-text-rebrand {
      margin-top: 8px;
      font-family: inherit;
      font-size: 13px;
      line-height: 16px;
      color: ${colors.gray.shade15};
      letter-spacing: 0;
    }
  }
  .seller-icon {
    background-repeat: no-repeat;
    margin-left: 4px;
    margin-right: 6px;
    display: inline-block;
    vertical-align: middle;
  }
  .falabellaIcon {
    background-image: url(${svgUrl('falabella.svg')});
    height: 18px;
    width: 12px;
    margin-top: -3px;
  }
  .sodimacIcon,
  .homecenterIcon {
    background-image: url(${svgUrl('sodimac.svg')});
    height: 18px;
    width: 18px;
    margin-top: 3px;
  }
  .tottusIcon {
    background-image: url(${svgUrl('tottus.svg')});
    height: 18px;
    width: 18px;
    margin-top: -3px;
  }
  .linioIcon {
    background-image: url(${svgUrl('linio.svg')});
    height: 18px;
    width: 18px;
    margin-top: -3px;
  }
  .patrocinado-pod {
    color: ${colors.gray.shade44};
    font-weight: 400;
    font-size: ${typography.sm};
    letter-spacing: 1px;
    line-height: 1.3;
    margin-top: 16px;
    margin-bottom: 1px;
    max-height: 16px;
    @mixin mobileToTablet {
      margin-top: 13px;
    }
  }
  .patrocinado-title {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .empty-mkp-badge-space {
    height: 7px;
  }
  .event-sticker-wrapper {
    max-width: 99px;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    width: fit-content;
    align-self: flex-start;
    margin-top: 5px;
  }
`;

export default LinearPodMobileStyles;
