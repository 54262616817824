import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import constants from '../../../config/constants';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import noop from '../../../utils/noop';
import { truncateText, ucFirst } from '../../../utils/textHelper';
import { digitalData } from '../../Facets/Common/digitalData';
import { FacetWithLink } from '../../Facets/FacetWithLink/FacetWithLink';
import BodyCopy from '../BodyCopy/BodyCopy';
import Checkbox from '../Checkbox/Checkbox';
import { showCount } from '../FacetCount/helper';
import InputBox from '../InputBox/InputBox';
import RatingsVisual from '../RatingsVisual/RatingsVisual';
import { multiSelectStyle } from './Multiselect.style';

const Multiselect = (props) => {
  const {
    item,
    type,
    groupType,
    onFacetChange,
    searchInFacetsLabel,
    checkboxType,
    normalBorderClass,
    selectedBorderClass,
    isFacetCountHidden,
    variant,
    appCtx,
    labels,
    isRadio,
  } = props;
  const { name, isLink, canonicalUrl } = item;
  const [data, setData] = useState(item.values);
  const { deviceType } = appCtx;
  const isShippingFacet = variant === 'shipping-facet';
  const {
    SAME_DAY_DELIVERY_LABEL = 'Si compras hasta las',
    SAME_DAY_DELIVERY_LABEL_AMOUNT = '14:00 horas',
    HOME_SHIPPING = 'Envío',
    WITHDRAWAL_AT_STORE = 'Retiro',
    BUY_AT_STORE_LABEL = 'Disponible en tienda',
  } = _.get(appCtx, 'siteConfig.textDictionary', {});
  const showRatingDescription = _.get(appCtx, 'siteConfig.configurations.ratingFacetConfig.useDerivedAttribute', false);
  const shippingMap = {
    hd: [],
    cc: [],
    pks: [],
    bas: [],
  };
  const [searchTerm, setSearchTerm] = useState(null);
  if (isShippingFacet) {
    item.values.forEach((v) => {
      if (/.hd_/.test(v.facetType)) {
        shippingMap.hd.push(v);
      } else if (/.cc_/.test(v.facetType)) {
        shippingMap.cc.push(v);
      } else if (/.pks_/.test(v.facetType)) {
        shippingMap.pks.push(v);
      } else if (/.bas_/.test(v.facetType)) {
        shippingMap.bas.push(v);
      }
    });
  }

  useEffect(() => {
    setData(item.values);
  }, [item.values]);

  const handleChange = (val) => {
    const filteredData = item.values.filter((d) => {
      return _.deburr(d.facetValue).toLowerCase().includes(_.deburr(val).toLowerCase());
    });
    setData(filteredData);
    setSearchTerm(val);
  };
  const handleFacetChange = (obj) => () => {
    onFacetChange({ ...obj, selected: !obj.selected }, !searchTerm);
    if (searchTerm) {
      digitalData(`${name}|${searchTerm}|${obj.title}`);
    }
  };
  const skipFacetSort = (facets) => {
    const facetType = _.get(facets, '[0].facetType', null);
    return constants.SKIP_FACET_SORTING.includes(facetType);
  };
  const renderFacets = (facetData) => {
    return facetData
      .sort((a, b) => {
        if (skipFacetSort(facetData)) return 0;
        if (a.selected === b.selected) return 0;
        return a.selected ? -1 : 1;
      })
      .map((option = {}) => {
        const { title = '', count, selected, isBoomApplicable, facetValue, facetType, extraId } = option;
        const hasTimeConstraint = constants.SAME_DAY_HD_CC_REGEX.test(title);
        const showGreenText = constants.NEXT_DAY_DELIVERY_REGEX.test(title) && facetData.length === 1;
        const isCCFacet = constants.SHIPPING_CC_REGEX.test(title);
        const isBrandFacet = constants.BRAND_FACET.test(facetType);
        const facetCase = () => {
          if (isBrandFacet) {
            return 'capitalize';
          }
          return 'ignorecase';
        };
        const markedStars = (value) => {
          const stars = Math.round(value * 2) / 2;
          const fiveStars = labels.RATINGS_STAR_ONLY_TEXT ? `${stars} ${labels.RATINGS_STAR_ONLY_TEXT}` : '';
          const lessThanFiveStars = labels.RATINGS_STAR_MORE_TEXT ? `${stars} ${labels.RATINGS_STAR_MORE_TEXT}` : '';
          return stars === 5 ? `${fiveStars}` : ` ${lessThanFiveStars}`;
        };
        return (
          <li
            className={`${variant} ${isCCFacet ? 'shipping-facet1' : ''}`}
            data-facet-type={isShippingFacet ? facetType : null}
            key={title}
          >
            <Checkbox
              variant={groupType === 'availability' && !isShippingFacet ? 'primary' : 'secondary'}
              isChecked={selected}
              onChange={handleFacetChange(option)}
              id={`${title.replace(/\s/g, '-').toUpperCase()}-${count}${extraId ? `-${extraId}` : ''}`}
              type={checkboxType}
              normalBorderClass={normalBorderClass}
              selectedBorderClass={selectedBorderClass}
              hasTimeConstraint={hasTimeConstraint}
              isShippingFacet={isShippingFacet}
              isPrimaryTheme
              isRadio={isRadio}
            >
              {type === 'star' ? (
                <BodyCopy size="title24">
                  <div className="mkp-ratings">
                    <RatingsVisual size="large" value={facetValue} component="facet" />
                    {showRatingDescription && <span className="rating-description">{markedStars(facetValue)}</span>}
                  </div>
                  {showCount({ isFacetCountHidden, type: facetType, appCtx }) ? (
                    <span className="label-count">{`(${count})`}</span>
                  ) : null}
                </BodyCopy>
              ) : (
                <BodyCopy size={isShippingFacet ? 'title23' : 'title24'}>
                  {isBoomApplicable ? (
                    (cls) => (
                      <div className={`${cls} boom-div`}>
                        <i className="boomIcon label-text">
                          <span className="sr-only">{title}</span>
                        </i>
                        {showCount({ isFacetCountHidden, type: facetType, appCtx }) ? (
                          <span className="label-count">{`(${count})`}</span>
                        ) : null}
                      </div>
                    )
                  ) : (
                    <>
                      <FacetWithLink isLink={isLink} canonicalUrl={canonicalUrl} option={option}>
                        <span
                          className={`label-text ${hasTimeConstraint || showGreenText ? 'green-text' : ''} ${
                            isShippingFacet ? 'shipping-facet-text' : ''
                          } ${facetCase()}`}
                        >
                          {ucFirst(truncateText(title, constants.FACETS.VALUE_TEXT_CHARCOUNT, true))}
                        </span>
                      </FacetWithLink>
                      {showCount({ isFacetCountHidden, type: facetType, appCtx }) ? (
                        <span className="label-count">&nbsp;&nbsp;{`(${count})`}</span>
                      ) : null}
                      {hasTimeConstraint ? (
                        <p className={`facet-sub-text ${isFacetCountHidden && 'no-count'}`}>
                          {SAME_DAY_DELIVERY_LABEL} <span className="bold">{SAME_DAY_DELIVERY_LABEL_AMOUNT}</span>
                        </p>
                      ) : null}
                    </>
                  )}
                </BodyCopy>
              )}
            </Checkbox>
            <style jsx>{multiSelectStyle}</style>
          </li>
        );
      });
  };
  const renderShippingFacets = () => {
    return (
      <div>
        {!!shippingMap.hd.length && (
          <div className="facet-title">
            <div className="home-delivery-icon" />
            <p>{HOME_SHIPPING}</p>
          </div>
        )}
        {renderFacets(shippingMap.hd)}
        {!!(shippingMap.hd.length && shippingMap.cc.length) && deviceType !== 'desktop' && (
          <div className="divider-line mobile" />
        )}
        {!!shippingMap.cc.length && (
          <div className="facet-title">
            <div className="click-collect-icon" />
            <p className="green-text">{WITHDRAWAL_AT_STORE}</p>
          </div>
        )}
        {renderFacets(shippingMap.cc)}
        {!!(shippingMap.hd.length || !!shippingMap.cc.length) &&
          !!shippingMap.bas.length &&
          deviceType !== 'desktop' && <div className="divider-line" />}
        {!!shippingMap.bas.length && (
          <div className="facet-title">
            <div className="buy-at-store-icon" />
            <p className="green-text">{BUY_AT_STORE_LABEL}</p>
          </div>
        )}
        {renderFacets(shippingMap.bas)}
        {!!(shippingMap.hd.length || shippingMap.cc.length || shippingMap.bas.length) && deviceType !== 'desktop' && (
          <div className="divider-line" />
        )}
        <style jsx>{multiSelectStyle}</style>
      </div>
    );
  };

  return (
    <div className={`multiselect-container ${isShippingFacet ? 'shipping' : ''}`}>
      {item.isSearchable && (
        <div className="searchBox">
          <InputBox
            hideLabel
            label={searchInFacetsLabel}
            onChange={handleChange}
            id={`testId-Multiselect-${item.name}`}
          />
        </div>
      )}
      <ul
        data-facet-type={!isShippingFacet ? _.get(data, '[0].facetType', null) : null}
        className={`multiselect ${isShippingFacet && 'shipping-multiselect'}`}
        ref={(el) => {
          if (el) {
            const scrollPosition = sessionStorage.getItem(`scrollPosition-${name}`);
            if (scrollPosition) {
              // eslint-disable-next-line no-param-reassign
              el.scrollTop = scrollPosition;
            }
            el.addEventListener('scroll', () => {
              sessionStorage.setItem(`scrollPosition-${name}`, el.scrollTop);
            });
          }
          return () => {
            sessionStorage.removeItem(`scrollPosition-${name}`);
          };
        }}
      >
        {!isShippingFacet ? renderFacets(data) : renderShippingFacets()}
      </ul>
      <style jsx>{multiSelectStyle}</style>
    </div>
  );
};

Multiselect.defaultProps = {
  type: 'text',
  item: { values: [] },
  searchInFacetsLabel: '',
  onFacetChange: noop,
  groupType: '',
  checkboxType: 'succes',
  selectedBorderClass: '',
  normalBorderClass: '',
  isFacetCountHidden: false,
  variant: '',
  appCtx: {},
  labels: {},
  isRadio: false,
};

Multiselect.propTypes = {
  searchInFacetsLabel: PropTypes.string,
  item: PropTypes.object,
  onFacetChange: PropTypes.func,
  type: PropTypes.oneOf(['star', 'text']),
  groupType: PropTypes.oneOf(['', 'availability']),
  checkboxType: PropTypes.oneOf('mkp-succes', 'succes', ''),
  selectedBorderClass: PropTypes.string,
  normalBorderClass: PropTypes.string,
  isFacetCountHidden: PropTypes.bool,
  variant: PropTypes.string,
  appCtx: PropTypes.object,
  labels: PropTypes.object,
  isRadio: PropTypes.bool,
};

export default withApplicationContext(withLabels(Multiselect));
export { Multiselect };
