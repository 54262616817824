const spanishDict = {
  Á: 'A',
  á: 'a',
  É: 'E',
  é: 'e',
  Í: 'I',
  í: 'i',
  Ó: 'O',
  ó: 'o',
  Ú: 'U',
  ú: 'u',
  Ñ: 'N',
  ñ: 'n',
  ' ': '-',
  '?': '-',
  '/': '-',
};

function cleanText(str = '') {
  return str.replace(/[^a-zA-Z0-9]/g, (matched) => {
    return spanishDict[matched] ? spanishDict[matched] : '-';
  });
}

function truncateText(str, charLimit, addEllipsis) {
  if (!str || str.length < charLimit) return str;
  return str.substr(0, charLimit).trim() + (addEllipsis === true ? '...' : '');
}

function ucFirst(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
export { cleanText, truncateText, ucFirst };
