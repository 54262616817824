import { isWhitelabel } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React from 'react';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import badgesStyles, { puntospesos } from './MultipurposeBadge.style';

const PuntosPesosBadge = ({ badge }) => {
  const parts = badge.label.split('+');
  return (
    <span>
      {parts?.[0]}
      <i className="puntospesos-icon" />+{parts?.[1] || ''}
      <style jsx>{puntospesos}</style>
    </span>
  );
};

PuntosPesosBadge.propTypes = {
  badge: PropTypes.object.isRequired,
};

const MultipurposeBadge = ({ badgeItems, layout, inPdp, store }) => {
  const isWhitelabelSite = isWhitelabel({ store });

  const getBadgeStyles = (badge) => {
    return isWhitelabelSite
      ? { backgroundColor: 'var(--secondary-color)', color: '#fff' }
      : {
          backgroundColor: badge.styles.backgroundColor,
          color: badge.styles.textColor,
        };
  };

  return (
    <div className={`mtp-pod-badges mtp-pod-badges-${layout}`}>
      {badgeItems?.map((badge) => (
        <BodyCopy size="copy5" key={badge.label}>
          {(classes) => (
            <span
              id={`testId-Pod-badges-${badge.label}`}
              className={`${classes} pod-badges-item
                  ${inPdp ? 'inPdp' : ''}
                `}
              style={getBadgeStyles(badge)}
            >
              {badge.id === 'puntospesos' ? <PuntosPesosBadge badge={badge} /> : badge.label}
            </span>
          )}
        </BodyCopy>
      ))}
      <style jsx>{badgesStyles}</style>
    </div>
  );
};

MultipurposeBadge.propTypes = {
  badgeItems: PropTypes.array,
  layout: PropTypes.string,
  inPdp: PropTypes.bool,
  store: PropTypes.string,
};

MultipurposeBadge.defaultProps = {
  badgeItems: [],
  layout: '',
  inPdp: false,
  store: '',
};

export default MultipurposeBadge;
