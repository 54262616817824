import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { getImages } from '../../../utils/GetImages';
import _ from '../../../utils/LodashImports';
import ImageStyles from './ImageListing.style';

const DEFAUL_MEDIA_URL = {
  cl: 'https://media.falabella.com/FalabellaCL/NoImage/public',
  pe: 'https://media.falabella.com/FalabellaPEAndCO/NoImage/public',
  co: 'https://media.falabella.com/FalabellaPEAndCO/NoImage/public',
};

const ImageListing = ({
  id,
  width,
  height,
  alt,
  lazyLoad,
  appCtx,
  quality,
  hideLazyLoadPlaceHolder,
  imageUrl,
  priorityFetch,
}) => {
  const { regionCode, store } = appCtx;
  const isCFMediasEnabled = _.get(appCtx, 'siteConfig.toggles.isCFMediasEnabled', false);

  const variantId = id.includes('/') ? id.split('/')[1] : id;

  const imageSrc = getImages({
    variantId: isCFMediasEnabled ? 'NoImage' : variantId,
    regionCode,
    attr: {
      width,
      height,
      quality,
    },
    url: imageUrl,
    store,
  });

  const imageSrcSet = getImages({
    variantId: isCFMediasEnabled ? 'NoImage' : variantId,
    regionCode,
    attr: {
      width: width * 2,
      height: height * 2,
      quality,
    },
    url: imageUrl,
    store,
  });

  const [src, setSrc] = useState(imageSrc);
  const [srcSet, setSrcSet] = useState(imageSrcSet);
  const [errored, setErrored] = useState(false);

  useEffect(() => {
    setSrc(imageSrc);
    setSrcSet(imageSrcSet);
    setErrored(false);
  }, [variantId, imageUrl]);

  const onError = () => {
    if (!errored) {
      setErrored(true);
      const fallback = DEFAUL_MEDIA_URL[appCtx.regionCode];
      setSrc(fallback);
      setSrcSet(fallback);
    }
  };

  const getImage = () => {
    return (
      <>
        <picture>
          <source srcSet={`${src}, ${srcSet} 2x`} type="image/webp,image/*,*/*" />
          <img
            src={src}
            srcSet={`${srcSet} 2x`}
            alt={alt}
            id={`testId-pod-image-${id}`}
            onError={onError}
            width={width}
            height={height}
            {...(priorityFetch ? { fetchpriority: 'high' } : {})}
          />
        </picture>
        <style jsx>{ImageStyles}</style>
      </>
    );
  };

  return lazyLoad ? (
    <LazyLoad
      placeholder={
        !hideLazyLoadPlaceHolder && (
          <div
            className="place-holder"
            style={{
              height,
            }}
          />
        )
      }
    >
      {getImage()}
    </LazyLoad>
  ) : (
    getImage()
  );
};

ImageListing.propTypes = {
  id: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  alt: PropTypes.string,
  lazyLoad: PropTypes.bool,
  appCtx: PropTypes.object.isRequired,
  quality: PropTypes.number,
  hideLazyLoadPlaceHolder: PropTypes.bool,
  imageUrl: PropTypes.string,
  priorityFetch: PropTypes.bool.isRequired,
};

ImageListing.defaultProps = {
  alt: '',
  id: '',
  lazyLoad: false,
  quality: 70,
  hideLazyLoadPlaceHolder: false,
  imageUrl: '',
};

export default withApplicationContext(ImageListing);
export { ImageListing };
