import { isTottusStandalone } from '@digital-retail/store-manager';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../config/constants';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { useShouldHideSponsoredPod } from '../../utils/hooks/UseShouldHideSponsoredPod';
import _ from '../../utils/LodashImports';
import { getQuantityUnit } from '../../utils/store.helpers';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import GridPod from './GridPod/GridPod';
import LinearPod from './LinearPod/LinearPod';
import PodStyles from './Pod.style';

const classNameMap = {
  LIST: 'search-results-list',
  '1_GRID': 'search-results-1-grid',
  '2_GRID': 'search-results-2-grid',
  '4_GRID': 'search-results-4-grid',
  '3_GRID': 'search-results-3-grid',
};

const Pod = ({
  product,
  labels,
  layout: config,
  lazyLoadImage,
  appCtx,
  setShowModalHandler,
  addProductToCart,
  isAddToCartInProgress,
  isSelected,
  sponsoredClick,
  collectionId,
  handlePodClick,
  isFirstPod,
  excludeAddToCartGscSet,
  setIsAddToCartInProgress,
  index,
  setShowOverlay,

  containerProps,
}) => {
  const { deviceType, store } = appCtx;
  const { mobile: mobileConfig = {}, desktop: desktopConfig = {} } = config;

  const isPodActionButtonEnabled = _.get(appCtx, 'siteConfig.toggles.isPodActionButtonEnabled', false);
  const getATCText = () => {
    return deviceType === 'desktop' ? (
      labels.ADD_TO_CART_TEXT_REBRANDING
    ) : (
      <>
        {labels.ADD_REBRANDING}
        <div className="basket-icon" />
        <style jsx>{PodStyles}</style>
      </>
    );
  };
  let buttonLabel;
  buttonLabel = getATCText();

  if (isAddToCartInProgress && isSelected) {
    buttonLabel = labels.ADDING_TO_CART_1;
  }

  let redirectToPDP = false;
  if (product.GSCCategoryId && appCtx.regionCode === 'co' && excludeAddToCartGscSet.has(product.GSCCategoryId)) {
    buttonLabel = deviceType === 'desktop' ? labels.XLP_VIEW_PRODUCT_DESKTOP : labels.XLP_VIEW_PRODUCT_MOBILE;
    redirectToPDP = true;
  }

  const clickForProductDetails = (colorIndex) => {
    const selectedVariant = _.get(product, `variants[0].options[${colorIndex}]`, {
      value: '',
      label: '',
      variant: product.skuId,
    });
    if (
      !product.variants ||
      product.variants.length === 0 ||
      (_.get(product, 'variants[0].options[0].sizes', []).length === 0 &&
        _.get(product, 'variants[1].options', []).length === 0) ||
      isTottusStandalone(store)
    ) {
      addProductToCart(
        {
          hasVariations: false,
          productId: product.productId,
          quantity: 1,
          quantityUnit: getQuantityUnit({ store }),
          skuId: selectedVariant.extraInfo || selectedVariant.variant,
          offeringId: selectedVariant.offeringId || product.offeringId,
          colorCode: selectedVariant.value,
          colorName: selectedVariant.label,
        },
        product
      );
    } else if (_.get(product, 'variants[1].options', []).length > 0) {
      // Variant has no colors but has size
      setShowModalHandler(product);
    } else if (_.get(product, 'variants[1].options[0].sizes', []).length === 0) {
      // Variant has no colors but has size
      setShowModalHandler(product, selectedVariant.extraInfo || selectedVariant.variant);
    } else if (_.get(product, 'variants[0].options[0].sizes', []).length === 0) {
      // Variant colors but no sizes, selected color should be add to cart
      addProductToCart(
        {
          hasVariations: false,
          productId: product.productId,
          quantity: 1,
          quantityUnit: getQuantityUnit({ store }),
          skuId: selectedVariant ? selectedVariant.variant : product.skuId,
          offeringId: selectedVariant.offeringId,
        },
        product
      );
    } else {
      // variant has sizes, show modal
      setShowModalHandler(product, selectedVariant.variant);
    }
  };

  const renderPod = (layout) => {
    const { className, ...otherContainerProps } = containerProps;
    return layout !== constants.DEFAULT_LAYOUT ? (
      <div
        className={`${classNameMap[layout]} grid-pod ${className ?? ''}`}
        pod-layout={layout}
        key={product.skuId}
        {...otherContainerProps}
      >
        <GridPod
          product={product}
          labels={labels}
          deviceType={deviceType}
          layout={layout}
          lazyLoadImage={lazyLoadImage}
          setShowModalHandler={setShowModalHandler}
          addProductToCart={addProductToCart}
          isAddToCartInProgress={isAddToCartInProgress}
          isSelected={isSelected}
          buttonLabel={buttonLabel}
          clickForProductDetails={clickForProductDetails}
          isPodActionButtonEnabled={isPodActionButtonEnabled}
          appCtx={appCtx}
          sponsoredClick={sponsoredClick}
          collectionId={collectionId}
          handlePodClick={handlePodClick}
          isFirstPod={isFirstPod}
          redirectToPDP={redirectToPDP}
          setIsAddToCartInProgress={setIsAddToCartInProgress}
          index={index}
          setShowOverlay={setShowOverlay}
        />
        <style jsx>{PodStyles}</style>
      </div>
    ) : (
      <div
        className={`search-results-list  ${className ?? ''}`}
        pod-layout={layout}
        key={product.skuId}
        {...otherContainerProps}
      >
        <LinearPod
          product={product}
          labels={labels}
          layout={layout}
          lazyLoadImage={lazyLoadImage}
          setShowModalHandler={setShowModalHandler}
          addProductToCart={addProductToCart}
          isAddToCartInProgress={isAddToCartInProgress}
          isSelected={isSelected}
          buttonLabel={buttonLabel}
          clickForProductDetails={clickForProductDetails}
          isPodActionButtonEnabled={isPodActionButtonEnabled}
          appCtx={appCtx}
          sponsoredClick={sponsoredClick}
          handlePodClick={handlePodClick}
          isFirstPod={isFirstPod}
          redirectToPDP={redirectToPDP}
          setIsAddToCartInProgress={setIsAddToCartInProgress}
          index={index}
        />
        <style jsx>{PodStyles}</style>
      </div>
    );
  };

  const isSponsored = _.get(product, 'isSponsored', false);
  const shouldHideSponsoredPod = useShouldHideSponsoredPod(appCtx, isSponsored);

  if (shouldHideSponsoredPod) return null;
  return (
    <MatchMedia
      content={{
        mobile: () => renderPod(mobileConfig.view),
        tablet: () => renderPod(mobileConfig.view),
        desktop: () => renderPod(desktopConfig.view),
      }}
    />
  );
};

Pod.defaultProps = {
  product: {},
  lazyLoadImage: false,
  appCtx: {},
  layout: {
    desktop: {
      view: '4_GRID',
    },
    mobile: {
      view: '2_GRID',
    },
  },
  collectionId: '',
  excludeAddToCartGscSet: new Set(),
  setShowOverlay: noop,
  containerProps: {},
};

Pod.propTypes = {
  product: PropTypes.object,
  labels: PropTypes.object.isRequired,
  layout: PropTypes.object,
  lazyLoadImage: PropTypes.bool,
  appCtx: PropTypes.object,
  setShowModalHandler: PropTypes.func.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isAddToCartInProgress: PropTypes.bool.isRequired,
  sponsoredClick: PropTypes.func.isRequired,
  collectionId: PropTypes.string,
  handlePodClick: PropTypes.func.isRequired,
  isFirstPod: PropTypes.bool.isRequired,
  excludeAddToCartGscSet: PropTypes.object,
  setIsAddToCartInProgress: PropTypes.object.isRequired,
  index: PropTypes.object.isRequired,
  setShowOverlay: PropTypes.func,

  containerProps: PropTypes.object,
};

export { Pod };
export default withApplicationContext(Pod);
