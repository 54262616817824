import { SponsoredProductBanner, SponsoredProductsContext } from '@digital-retail/sponsored-provider-library';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { useShouldHideSponsoredDisplay } from '../../utils/hooks/UseShouldHideSponsoredPod';
import SponsorDisplayLeftStyles from './SponsorDisplayLeft.style';

const SponsorDisplayLeftWrapper = ({ handleBannerAction, appCtx }) => {
  const { verticalProduct } = useContext(SponsoredProductsContext);
  const shouldHideSDForKiosk = useShouldHideSponsoredDisplay(appCtx);
  return (
    <div className="sponsored-display-left">
      {verticalProduct && !shouldHideSDForKiosk && (
        <SponsoredProductBanner
          onBannerClick={handleBannerAction}
          isVerticalBanner
          product={verticalProduct}
          store={appCtx.store}
        />
      )}
      <style jsx>{SponsorDisplayLeftStyles}</style>
    </div>
  );
};

SponsorDisplayLeftWrapper.propTypes = {
  handleBannerAction: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
};

export { SponsorDisplayLeftWrapper };
export default withApplicationContext(SponsorDisplayLeftWrapper);
