import css from 'styled-jsx/css';
import { colors, typography, zIndex } from '../../../config/styles';
import { operatorSvgUrl, svgUrl } from '../../../utils/staticUrls';

const GridPodStyles = css`
  .pod {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border: 1px solid ${colors.gray.shade14};
    background-color: ${colors.white.shade1};
    position: relative;
  }
  .pod__head {
    position: relative;
  }
  .badges-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 4px;
    margin-bottom: 5px;
  }

  .pod-summary {
    padding: 0 15px 0 15px;
    padding-bottom: 6px;
    display: flex;
    flex-direction: column;
    height: 100%;
    @mixin smallMobileOnly {
      padding: 0 10px;
    }
    &.tottus {
      padding: 0 16px 0 16px;
      padding-bottom: 12px;
    }
  }
  .action-button-rebranding {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @mixin desktop {
      min-height: 44px;
    }
  }

  .pod-summary-2_GRID {
    @mixin tabletToDesktop {
      padding: 0 15px 0 31px;
    }
  }

  .pod-title {
    margin-bottom: 4px;

    @mixin tabletToDesktop {
      margin-bottom: 7px;
    }

    &.title-rebrand {
      font-size: 13px;
      line-height: 12px;
      color: ${colors.gray.shade44};
      letter-spacing: 0;

      @mixin desktop {
        line-height: 16px;
      }
    }
  }

  .pod-subTitle {
    margin-top: 4px;
    text-transform: none;
    display: block;
    @mixin tabletToDesktop {
      line-height: 16px;
      margin-top: 7px;
    }
    &.subTitle-rebrand {
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      color: ${colors.gray.shade40};
    }
    @mixin fallback {
      &.subTitle-rebrand {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  .pod-subtitle-unit {
    color: ${colors.gray.shade44};
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1rem;
    margin-top: 4px;
  }

  .pod-sellerText {
    margin-top: 4px;
    text-transform: none;
    display: inline-block;
    font-size: 13px;
    color: #888;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;

    @mixin tabletToDesktop {
      line-height: 16px;
      margin-top: 4px;
    }
    &.seller-text-rebrand {
      font-family: inherit;
      font-size: 13px;
      line-height: 16px;
      color: ${colors.gray.shade15};
      margin-top: 8px;

      @mixin mobileToTablet {
        margin-top: 6.22px;
      }
    }
  }
  .seller-icon {
    background-repeat: no-repeat;
    margin-left: 4px;
    margin-right: 6px;
    display: inline-block;
    vertical-align: middle;
  }
  .falabellaIcon {
    background-image: url(${svgUrl('falabella.svg')});
    height: 18px;
    width: 12px;
    margin-top: -9px;
  }
  .sodimacIcon,
  .homecenterIcon {
    background-image: url(${svgUrl('sodimac.svg')});
    height: 18px;
    width: 18px;
    margin-top: -3px;
  }
  .tottusIcon {
    background-image: url(${svgUrl('tottus.svg')});
    height: 18px;
    width: 18px;
    margin-top: -9px;
  }
  .linioIcon {
    background-image: url(${svgUrl('linio.svg')});
    height: 18px;
    width: 18px;
    margin-top: -9px;
  }

  .pod-summary.pod-summary-1_GRID {
    padding: 0 21px 20px 26px;
  }

  .pod-details {
    padding: 15px 15px 6px 15px;
    border-top: 1px solid ${colors.gray.shade14};
    position: relative;

    .my-list-xlp {
      position: absolute;
      top: 19px;
      right: 15px;
      @mixin mobileToTablet {
        top: 14px;
        right: 13px;
      }
    }

    @mixin tabletToDesktop {
      padding: 20px 15px 7px 15px;
      &.has-stickers {
        padding-top: 22px;
      }
    }

    @mixin smallMobileOnly {
      padding: 15px 10px 6px 10px;
    }

    @mixin mobileToTablet {
      &.has-stickers {
        padding-top: 24px;
      }
    }
  }

  .pod-details-2_GRID {
    @mixin tabletToDesktop {
      padding: 30px 15px 7px 31px;
    }
  }

  .pod-details.pod-details-1_GRID {
    padding: 33px 21px 6px 26px;

    .pod-title {
      margin-bottom: 12px;
    }
  }

  .pod-head {
    position: relative;
  }
  .tooltip-wrapper {
    position: absolute;
    top: 50%;
    right: 50%;
  }

  .pod-link {
    text-decoration: none;
    color: inherit;
    border-radius: var(--border-radius);
    overflow: hidden;
    @mixin mobileToTablet {
      border-radius: 0;
    }
  }

  .patrocinado-pod {
    text-align: right;
    color: ${colors.gray.shade44};
    font-weight: 400;
    font-size: ${typography.sm};
    letter-spacing: 1px;
    display: block;
    max-height: 13px;

    @media (hover: hover) {
      position: absolute;
      bottom: 20px;
      right: 15px;
    }

    @mixin mobileToTablet {
      position: static;
    }
  }

  .pod-rating {
    padding-bottom: 12px;
    display: flex;
    align-items: center;

    @mixin tabletToDesktop {
      padding-bottom: 2px;
    }
  }
  .rating-rebranding {
    @mixin desktop {
      padding-bottom: 6px;
    }
  }
  .pod-rating-1_GRID {
    margin-top: 15px;
    margin-bottom: 5px;
    padding-bottom: 0;
    align-items: center;
    height: 0;
    min-height: 0;
  }
  .pod-rating-1_GRID.no-rating {
    margin: 0;
  }

  .action-button-place-holder {
    min-height: 34px;
  }

  .action-button-place-holder-2_GRID {
    min-height: 50px;
  }

  div.rebranding-pod-action {
    padding: 12px 0;

    @mixin tabletOnly {
      :global(button.button) {
        font-size: 1.8rem;
      }
    }
  }

  .pod-action {
    align-items: end;
    width: 100%;
    padding: 12px 10px 20px 10px;

    @media (hover: hover) {
      visibility: hidden;
    }
  }
  .pod-button-add-to-cart {
    display: flex;
    justify-content: center;

    .btn-text {
      display: flex;
      align-items: center;
    }
  }
  .pod-button-add-to-cart-spinner {
    margin-right: 8px;
    align-self: center;
    display: flex;
  }
  .pod-action-2_GRID {
    @mixin tabletToDesktop {
      padding: 12px 17px 11px 23px;
    }
  }
  .pod-action-1_GRID {
    padding: 7px 0 0 0;
    position: relative;
  }
  .pod:hover {
    .pod-action {
      visibility: visible;
    }
    .patrocinado-pod {
      display: none;
    }
  }

  .tabContent-text {
    line-height: 15px;
  }

  .pod-meta {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  .meta-1_GRID {
    margin-top: 15px;
  }

  .no-fixed-height {
    min-height: 16px;
  }

  .badgesContainer {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, -65%);
  }

  .badges-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${zIndex.pod.badges};
    text-transform: uppercase;
    transform: translateY(50%);

    @mixin mobileToTablet {
      transform: translateY(9px);
    }
  }

  .top-stickers-container {
    position: absolute;
    bottom: 0;
    left: 4px;
    z-index: ${zIndex.pod.badges + 1};
    transform: translateY(50%);
    display: flex;
    gap: 4px;
  }
  .event-sticker-wrapper {
    height: 20px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .event-sticker-wrapper div span {
    position: static;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17.8px;
    margin: 3px;
    letter-spacing: 0.2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @mixin mobileToTablet {
    .event-sticker-wrapper div span {
      font-size: 12px;
      line-height: 17.8px !important;
    }
    .top-stickers-container {
      left: 2px;
      gap: 2px;
    }
  }
`;

const GridPodPlanStyles = css`
  .text-center {
    text-align: center;
  }
  .planProdDetails {
    margin-top: -9px;
    @mixin mobileToTablet {
      margin-top: 5px;
    }
    .spinner {
      display: inline-block;
    }
    .planBold {
      font-weight: 700;
    }
    .planFont {
      font-size: 13px;
    }
    .planTitle {
      margin-bottom: 10px;
      @mixin mobileToTablet {
        font-size: 12px;
      }
    }
    .planLowest {
      margin-top: -10px;
      @mixin mobileToTablet {
        margin-top: 2px;
      }
    }
    .planAllSellers {
      margin-top: 15px;
      @mixin mobileToTablet {
        font-size: 12px;
      }
    }
    .planCMRprice {
      margin-bottom: -8px;
    }
    .operator-icons {
      display: flex;
      margin-bottom: 18px;

      .wom {
        background-image: url(${operatorSvgUrl('planes_wom.svg')});
        margin: 6px 9px 0 0;
      }
      .claro {
        background-image: url(${operatorSvgUrl('planes_claro.svg')});
        margin: 6px 9px 0 0;
      }
      .movistar {
        background-image: url(${operatorSvgUrl('planes_movistar.svg')});
        margin: 6px 9px 0 0;
      }
      .entel {
        background-image: url(${operatorSvgUrl('planes_entel.svg')});
        margin: 6px 9px 0 0;
      }
      .icon {
        height: 20px;
        width: 20px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
`;

const GridPodGlobalStyles = css.global`
  .tabContent-1 {
    height: 15px;

    @mixin tabletToDesktop {
      height: 19px;
    }
  }

  .tabContent-2 {
    height: 30px;

    @mixin tabletToDesktop {
      height: 35px;
    }
  }

  .tabContent-3 {
    height: 43px;

    @mixin tabletToDesktop {
      height: 48px;
    }
  }

  .tabContent,
  .prices {
    margin-bottom: 12px;
    @mixin tabletToDesktop {
      margin-bottom: 12px;
    }
  }

  .pod-1_GRID {
    .prices-2,
    .prices-3,
    .price-with-tab {
      height: auto;
      line-height: auto;
    }
    .tabContent-text {
      margin-top: 10px;
    }
    .pod-summary {
      padding: 0 15px 25px;
    }
    .no-rating {
      margin: 0;
    }
    .pod-rating {
      min-height: 0;
    }
  }
  .multipurpose-badge {
    margin-bottom: 12px;
  }
  .empty-mkp-badge-space {
    height: 4px;
  }
  .overlay-exception {
    z-index: 102;
  }
  .no-scroll {
    overflow: hidden;
  }
  .pod-head {
    position: relative;
    width: 100%;
  }
  .bestseller-ribbon-wrapper {
  }
`;
export { GridPodGlobalStyles, GridPodPlanStyles, GridPodStyles };
