/* eslint-disable no-nested-ternary */
import { isTottusStandalone } from '@digital-retail/store-manager';
import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import noop from '../../../utils/noop';
import { checkboxStyles } from './Checkbox.style';

const Checkbox = (props) => {
  const {
    isChecked,
    onChange,
    id,
    children,
    variant,
    inConnect,
    type,
    selectedBorderClass,
    normalBorderClass,
    inFloorCalculator,
    isShippingFacet,
    disabled,
    isPrimaryTheme,
    appCtx,
    isRadio,
  } = props;
  const isTottusSA = isTottusStandalone(appCtx.store);
  const [isSelected, setIsSelected] = useState(isChecked);
  const dashedId = id.replace(/ /g, '-');

  useEffect(() => {
    setIsSelected(isChecked);
  }, [isChecked]);

  const renderCheckIcon = () => {
    if (isSelected) {
      return (
        <>
          <i
            id={`${dashedId}-icon`}
            className={clsx(`csicon-${type}`, {
              'in-floor-calc': inFloorCalculator,
              shipping: isShippingFacet,
            })}
          />
          <style jsx>{checkboxStyles}</style>
        </>
      );
    }
    return null;
  };
  const handleOnChange = (e) => {
    const newIsSelected = e.target.checked;
    setIsSelected(newIsSelected);
    onChange({
      isSelected: newIsSelected,
    });
  };

  const titleClass = inConnect ? 'checkbox-connect-title' : 'checkbox-title';
  const renderCheckBox = () => {
    if (isShippingFacet) {
      return (
        <>
          {children}
          <div className="shipping-label">
            <input id={dashedId} type="checkbox" checked={isSelected} onChange={handleOnChange} />
            <span
              id={`${dashedId}-span`}
              className={`${titleClass} shipping ${isTottusSA && 'tottus'} ${
                isShippingFacet && 'hasTimeConstraint'
              } ${variant}${selectedBorderClass}${normalBorderClass} ${inFloorCalculator ? 'in-floor-calc' : ''}`}
            />
            {renderCheckIcon()}
          </div>
          <style jsx>{checkboxStyles}</style>
        </>
      );
    }
    if (isRadio) {
      return (
        <>
          <input id={dashedId} type="checkbox" checked={isChecked} onChange={handleOnChange} />
          <span className="mkp-radio" />
          {children}
          <style jsx>{checkboxStyles}</style>
        </>
      );
    }
    return (
      <>
        <input id={dashedId} type="checkbox" checked={isChecked} onChange={handleOnChange} />
        <span
          className={`${titleClass} ${isShippingFacet && 'hasTimeConstraint'} ${isTottusSA && 'tottus'}
          ${variant}${selectedBorderClass}${normalBorderClass} ${inFloorCalculator ? 'in-floor-calc' : ''}`}
        >
          {renderCheckIcon()}
          {children}
        </span>
        <style jsx>{checkboxStyles}</style>
      </>
    );
  };

  const color = isPrimaryTheme ? 'var(--primary-color)' : 'var(--secondary-color)';

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <label
      id={`${dashedId}-wrapper`}
      className={`checkbox ${disabled ? ' disabled' : ''} ${isShippingFacet ? 'shipping' : ''}`}
      htmlFor={dashedId}
    >
      {renderCheckBox()}
      <style jsx>{`
         {
          --checkbox-color: ${isTottusSA ? 'white' : color};
          --checkbox-rebranded: ${isPrimaryTheme
            ? 'var(--primary-checkbox-rebranded)'
            : 'var(--secondary-checkbox-rebranded)'};
          --checkbox-round-rebranded: ${isPrimaryTheme
            ? 'var(--primary-round-checkbox-rebranded)'
            : 'var(--secondary-round-checkbox-rebranded)'};
        }
      `}</style>
      <style jsx>{checkboxStyles}</style>
    </label>
  );
};

Checkbox.defaultProps = {
  children: null,
  onChange: noop,
  isChecked: false,
  variant: 'secondary',
  inConnect: false,
  type: 'succes',
  selectedBorderClass: '',
  normalBorderClass: '',
  inFloorCalculator: false,
  isShippingFacet: false,
  disabled: false,
  isPrimaryTheme: false,
  isRadio: false,
};

Checkbox.propTypes = {
  children: PropTypes.node,
  isChecked: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'services']),
  inConnect: PropTypes.bool,
  type: PropTypes.oneOf('succes', 'mkp-succes', 'mkp-bundle-succes'),
  selectedBorderClass: PropTypes.oneOf(' mkp-border', ' mkp-border-bundle', ''),
  normalBorderClass: PropTypes.oneOf(' mkp-normal-border', ''),
  inFloorCalculator: PropTypes.bool,
  isShippingFacet: PropTypes.bool,
  disabled: PropTypes.bool,
  isPrimaryTheme: PropTypes.bool,
  appCtx: PropTypes.object.isRequired,
  isRadio: PropTypes.bool,
};

export default withApplicationContext(Checkbox);
