import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import _ from '../../../../utils/LodashImports';
import FeatureHighlighter from '../../../ui/FeatureHighlighter/FeatureHighlighter';
import Switch from '../../../ui/Switch/Switch';
import styles from './FacetWithSwitch.style';

const FacetWithSwitch = ({ item, onFacetChange, withIcon, type, appCtx, isMobileFacetUI }) => {
  const [data, setData] = useState(item?.values?.[0]);
  const facetLabeles = _.get(appCtx, 'siteConfig.facetLabels', '');

  useEffect(() => {
    setData(item?.values?.[0]);
  }, [item?.values]);

  const handleSwitchChange = () => {
    onFacetChange({ ...data, selected: !data?.selected, isSwitchFacet: true });
  };

  if (!data) {
    return null;
  }

  const isNextDayFacet = type === 'shipping' && data.title === 'Llega mañana';
  const isEventFacet = type === 'event';

  return (
    <div
      data-facet-type={data.facetType}
      className={`facet-with-switch${isMobileFacetUI ? ' event-mobile-container' : ''} ${type} ${
        data.selected ? 'selected' : ''
      }`}
    >
      <div className="heading-section">
        {withIcon && (
          <div className="icon-container">
            <i className="icon" />
          </div>
        )}
        <div className={`heading ${type}${isMobileFacetUI ? ' event-mobile' : ''}`}>
          <p>{data.title}</p>
          <FeatureHighlighter component="facets" id={data.title} />
        </div>
        {isEventFacet && isMobileFacetUI && facetLabeles[`${data.facetType}`] && (
          <div
            className="label-section event-mobile"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: facetLabeles[`${data.facetType}`].replace(/<strong/gi, "<strong style='font-weight: bold'"),
            }}
          />
        )}
        <div className="switch-container">
          <Switch
            id={`facet-swich-${type}`}
            isChecked={data.selected}
            onChange={handleSwitchChange}
            manageState={false}
            type={type}
          />
        </div>
      </div>
      {!isNextDayFacet && !isMobileFacetUI && facetLabeles[`${data.facetType}`] && (
        <div
          className="label-section"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: facetLabeles[`${data.facetType}`].replace(/<strong/gi, "<strong style='font-weight: bold'"),
          }}
        />
      )}
      <style jsx>{styles}</style>
    </div>
  );
};

FacetWithSwitch.defaultProps = {
  type: '',
  isMobileFacetUI: false,
  appCtx: {},
};
FacetWithSwitch.propTypes = {
  item: PropTypes.object.isRequired,
  onFacetChange: PropTypes.func.isRequired,
  withIcon: PropTypes.bool.isRequired,
  type: PropTypes.string,
  isMobileFacetUI: PropTypes.bool,
  appCtx: PropTypes.object,
};

export default FacetWithSwitch;
