const SEOLeftContentStyles = `
.description-content-text {
  color: #555;
  border-bottom: 1px solid #f5f5f5;
  margin: 0;
  width: 100%;
}

.description-content-text h1,
.description-content-text h2,
.description-content-text h3,
.description-content-text h4,
.description-content-text h5,
.description-content-text h6 {
  font-weight: 700;
	margin-bottom: 10px;
	line-height: 1.5;
	color: #6f6f6f;
	letter-spacing: 0px;
	text-transform: none;
}

.description-content-text h1 {
	font-size: 14px;
}

.description-content-text h2 {
	font-size: 13px;
}

.description-content-text h3 {
	font-size: 12px;
}

.description-content-text h4 {
	font-size: 11px;
}

.description-content-text h5 {
	font-size: 10px;
}

.description-content-text h6 {
	font-size: 10px;
}

.description-content-text > h1:first-child,
.description-content-text > h2:first-child,
.description-content-text > h3:first-child,
.description-content-text > h4:first-child,
.description-content-text > h5:first-child,
.description-content-text > h6:first-child {
	padding-bottom: 13px;
    border-bottom: 2px solid #e0dcdc;
    margin-left: calc(1em - 25px);
    margin-right: calc(1em - 25px);
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: calc(1em + 5px);
    line-height: 2.4rem;
    text-transform: uppercase;
}

.description-content-text p {
  color: #555;
}

.description-content-text a {
  color: #555;
}
`;

export default SEOLeftContentStyles;
