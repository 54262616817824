import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import apiConfig from '../../../config/api/apiConfig';
import httpService from '../../../utils/httpService';
import _ from '../../../utils/LodashImports';
import Prices from '../../Prices/Prices';
import Heading from '../../ui/Heading/Heading';
import MarketplaceSpinner from '../../ui/Spinner/MarketplaceSpinner';
import { capitalizeText } from '../podUtils';
import { GridPodPlanStyles } from './GridPod.style';

const LowestPlan = ({
  plan,
  productId,
  isTabApplicable,
  layout,
  deviceType,
  environment = 'production',
  setLowestPlan,
  appCtx,
  shouldShowPlans,
  labels,
}) => {
  const [loading, setLoading] = useState(false);
  const price = _.get(plan, 'price');
  const monthlyCost = _.get(plan, 'monthlyCost');
  const operator = _.get(plan, 'operator');
  const allOperators = _.get(plan, 'allOperators');
  const showDetails = plan && price && monthlyCost && operator && allOperators;

  useEffect(() => {
    const fetchPlans = async () => {
      const rest = {
        pgid: appCtx.priceGroupId,
        pid: appCtx.politicalId,
        zones: appCtx.zones,
        Ntt: productId,
        searchPlans: true,
      };
      const params = {};
      Object.keys(rest).forEach((key) => {
        const value = rest[key];
        params[encodeURIComponent(key)] = encodeURIComponent(value);
      });
      setLoading(true);
      const getSearchUrl = apiConfig.getLowestPlanUrl({
        regionCode: appCtx.regionCode,
        environment,
        rest: params,
      });
      const resp = await httpService({
        tracingHeaders: appCtx.tracingHeaders,
      }).get(getSearchUrl, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (!resp.error) {
        setLowestPlan(_.get(resp, 'data.data.results[0].lowestPlan', null));
      } else {
        setLowestPlan(null);
      }
      setLoading(false);
    };
    if (!shouldShowPlans && !plan) {
      fetchPlans();
    }
  }, []);

  return (
    <ul className={`planProdDetails ${loading ? 'text-center' : ''}`}>
      {loading && <MarketplaceSpinner size="small" />}
      {showDetails && (
        <>
          <li className="planBold planFont planTitle">Equipo con plan desde:</li>
          <li className="planCMRprice">
            <Prices
              priceList={price}
              productId={productId}
              isTabApplicable={isTabApplicable}
              page="SLP"
              layout={layout}
              variant={`${deviceType === 'desktop' ? 'DESKTOP' : 'MOBILE'}_${layout}`}
            />
          </li>
          <li className="planFont planFont planLowest">
            {`Contratando un plan ${capitalizeText(operator[0])} de $${monthlyCost[0]} mensual`}
          </li>
          <li className="planFont planAllSellers">Compañías disponibles:</li>
          <li className="operator-icons">
            {allOperators.map((op) => (
              <span title={`${op}`} className={`icon ${op.toLowerCase()}`} />
            ))}
          </li>
        </>
      )}
      {!plan && !loading && (
        <>
          <Heading labels={3} variation="title8">
            {labels.NO_PLAN_TITLE}
          </Heading>
          <p className="no-plan-text">{labels.NO_PLAN_LABEL}</p>
        </>
      )}
      <style jsx>{GridPodPlanStyles}</style>
    </ul>
  );
};

LowestPlan.defaultProps = {
  plan: null,
  productId: '',
  layout: '4_GRID',
  environment: 'production',
  isTabApplicable: false,
  deviceType: 'desktop',
  shouldShowPlans: false,
};

LowestPlan.propTypes = {
  plan: PropTypes.object,
  productId: PropTypes.string,
  isTabApplicable: PropTypes.bool,
  layout: PropTypes.string,
  deviceType: PropTypes.string,
  environment: PropTypes.string,
  setLowestPlan: PropTypes.func.isRequired,
  appCtx: PropTypes.object.isRequired,
  shouldShowPlans: PropTypes.bool,
  labels: PropTypes.object.isRequired,
};

export { LowestPlan };
