import _ from '../../../utils/LodashImports';

const showCount = ({ isFacetCountHidden = true, type, appCtx = {} }) => {
  const overrideFacets = _.get(appCtx, 'siteConfig.textDictionary.OVERRIDE_FACETS_COUNT', '');
  let allowedFacets = [];
  if (typeof overrideFacets === 'string') {
    allowedFacets = overrideFacets.split(',');
  }
  return !isFacetCountHidden || allowedFacets.includes(type);
};

export { showCount };
