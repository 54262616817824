import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import noop from '../../../../utils/noop';
import Button from '../../../ui/Button/Button';
import Checkbox from '../../../ui/Checkbox/Checkbox';
import Drawer from '../../../ui/Drawer/Drawer';
import { Modal } from '../../../ui/Modal/Modal';
import { StoresModalStyles } from './StoresModal.style';

const StoresModal = ({
  facetValue,
  initialStoresList,
  selectedOptions,
  showModal,
  setShowModal,
  textDictionary,
  setSelectedCount,
  baseFacetsURL,
  changeURL,
  appCtx,
}) => {
  const { deviceType } = appCtx;
  const isMobile = deviceType !== 'desktop';
  const {
    STORES_FILTERS_MODAL = 'Filtrar por tiendas',
    STORES_MODAL_SUBTITLE = 'Estas son las tiendas cercanas a la ubicación que ingresaste',
    SELECT_ALL_STORES_LABEL = 'Seleccionar todas las tiendas',
    ACCEPT_MKP = 'Aceptar',
    CANCEL = 'Cancelar',
  } = textDictionary;
  const [storesList, setStoresList] = useState(initialStoresList || []);
  const [masterSelected, setMasterSelected] = useState(false);

  useEffect(() => {
    const allSelected = storesList.length > 0 && storesList.every((store) => store.selected);
    setMasterSelected(allSelected);
  }, [storesList]);

  const onStoreClick = (facet) => {
    const updatedList = [];
    storesList.forEach((storeFacet) => {
      if (storeFacet.facetValue === facet.facetValue) {
        updatedList.push({ ...storeFacet, selected: !storeFacet.selected });
      } else {
        updatedList.push(storeFacet);
      }
    });
    setStoresList(updatedList);
  };

  const applyStoreFacets = () => {
    const urls = [];
    if (baseFacetsURL) {
      urls.push(baseFacetsURL);
    }
    if (Object.keys(selectedOptions)?.length > 0) {
      Object.keys(selectedOptions).forEach(
        (key) => key !== facetValue && key !== 'facetSelected' && urls.push(`${key}=${selectedOptions[key]}`)
      );
    }
    const selectedStoresFacets = storesList.filter((store) => store.selected);

    if (storesList.length > 0 && selectedStoresFacets.length > 0) {
      const selectedStoresParam = selectedStoresFacets.map((facet) => facet.facetValue).join('::');
      setSelectedCount(selectedStoresFacets.length);
      urls.push(`${facetValue}=${selectedStoresParam}`);
      urls.push('facetSelected=true');
    }
    setShowModal(false);
    changeURL(urls.join('&'));
  };

  const handleMasterCheckboxChange = () => {
    setMasterSelected((prev) => !prev);
    setStoresList((prev) => prev.map((store) => ({ ...store, selected: !masterSelected })));
  };

  const getContent = () => {
    return (
      <div className="stores-filter-container">
        <div className="subtitle">{STORES_MODAL_SUBTITLE}</div>
        <div className="select-all-stores">
          <Checkbox
            variant="secondary"
            isChecked={!!masterSelected}
            id={SELECT_ALL_STORES_LABEL}
            onChange={handleMasterCheckboxChange}
            type="mkp-succes"
            normalBorderClass=" mkp-normal-border"
            selectedBorderClass=" mkp-border"
            isPrimaryTheme
          >
            {SELECT_ALL_STORES_LABEL}
          </Checkbox>
        </div>
        <div className="facets-container">
          {storesList.map((facet) => {
            const { title, selected, facilityAddress } = facet;
            return (
              <div className="store-facet">
                <Checkbox
                  variant="secondary"
                  isChecked={!!selected}
                  id={title}
                  onChange={() => onStoreClick(facet)}
                  type="mkp-succes"
                  normalBorderClass=" mkp-normal-border"
                  selectedBorderClass=" mkp-border"
                  isPrimaryTheme
                >
                  <div>
                    <div className="facility-name">{title}</div>
                    <div className="facility-address">{facilityAddress}</div>
                  </div>
                </Checkbox>
              </div>
            );
          })}
        </div>
        <div className={`footer${isMobile ? '-mobile' : ''}`}>
          <button className="cancel-button" type="button" onClick={() => setShowModal(false)}>
            {CANCEL}
          </button>
          <Button type="mkp-primary-medium primary-theme" onClick={applyStoreFacets}>
            {ACCEPT_MKP}
          </Button>
        </div>
        <style jsx>{StoresModalStyles}</style>
      </div>
    );
  };

  return isMobile ? (
    <Drawer toggleState={[showModal, setShowModal]} type="secondary" isLoading={false}>
      <div className="drawer-container">
        <div className="title">{STORES_FILTERS_MODAL}</div>
        {getContent()}
      </div>
      <style jsx>{StoresModalStyles}</style>
    </Drawer>
  ) : (
    <Modal
      header={{ title: STORES_FILTERS_MODAL }}
      showCloseButton
      content={getContent}
      options={{
        width: 400,
        height: 'auto',
        borderRadius: '12px',
      }}
      toggleState={{
        visible: showModal,
        setVisible: setShowModal,
      }}
      className="bottom"
      customClassBody="auto"
      deviceType="desktop"
    />
  );
};

StoresModal.defaultProps = {
  initialStoresList: [],
  showModal: false,
  setShowModal: noop,
  textDictionary: {
    STORES_FILTERS_MODAL: '',
    SELECT_ALL_STORES_LABEL: '',
  },
  setSelectedCount: noop,
  selectedOptions: {},
  baseFacetsURL: '',
  changeURL: noop,
  appCtx: {},
};

StoresModal.propTypes = {
  facetValue: PropTypes.string.isRequired,
  initialStoresList: PropTypes.array,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  selectedOptions: PropTypes.object,
  textDictionary: PropTypes.object,
  setSelectedCount: PropTypes.func,
  baseFacetsURL: PropTypes.string,
  changeURL: PropTypes.func,
  appCtx: PropTypes.object,
};

export default withApplicationContext(StoresModal);
export { StoresModal };
