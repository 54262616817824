import { isTottusStandalone } from '@digital-retail/store-manager';
import Router from 'next/router';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import apiConfig from '../../../config/api/apiConfig';
import constants from '../../../config/constants';
import colors, { getStoreShade } from '../../../config/styles/colors';
import { getHostUrl } from '../../../containers/SearchResults/utils';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withConfig } from '../../../utils/ConfigurationContext';
import { useOnScroll } from '../../../utils/hooks/UseOnScroll';
import httpService from '../../../utils/httpService';
import { toggleBodyClass } from '../../../utils/jsUtils';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import noop from '../../../utils/noop';
import {
  navigateWithUrl,
  navigateWithUrlForBrand,
  navigateWithUrlForCategory,
  navigateWithUrlForCollection,
  navigateWithUrlForSeller,
  navigateWithUrlForShop,
} from '../../../utils/RouteHandler';
import SkipSSR from '../../../utils/SkipSSR';
import WithRouter from '../../../utils/WithRouter';
import Chip from '../../ui/Chip/Chip';
import Dialog from '../../ui/Dialog/Dialog';
import MobileLinkMenu from '../../ui/MobileLinkMenu/MobileLinkMenu';
import MobileMenu from '../../ui/MobileMenu/MobileMenu';
import getFacetsData from '../Common/getFacetsData';
import { facetClick } from '../FacetEvents';
import { facetsMobileGlobalStyle, facetsMobileStyles } from './FacetsMobile.style';
import FacetsMobileFooter from './FacetsMobileFooter';

const DialogWithoutSSR = SkipSSR(Dialog);

const FacetsMobile = ({
  config,
  data: initialFacetsData,
  dataMethods,
  freeShippingFacet,
  title,
  mobileTitle,
  appCtx,
  dropDownLabel,
  sortOptions,
  labels,
  availabilityFacets: initialAvailabilityFacets,
  onHideMenu,
  selectedURLs: originalSelection,
  selectedURLsForAvailability,
  pageName,
  onFacetChangeHandler,
  getUpdatedFacets,
  canonicalUrl,
  appliedFacets: initialAppliedFacets,
  pagination,
  handleScrollToTopProductList,
  isFiltersMenuVisible,
  setIsFiltersMenuVisible,
}) => {
  const ref = useRef();
  const { tenant, setBusy, regionCode, store } = appCtx;
  const mobileAvailabilityTitle = labels.FILTERS_AVAILABILITY_TITLE_MOBILE;
  const searchInFacetsLabel = labels.SEARCH_IN_FACETS_LABEL;
  const priceRangeErrorLabel = labels.ENTER_VALID_PRICE_ERROR_LABEL;
  const priceRangeNoResultsLabel = labels.PRICE_NO_RESULTS_ERROR_LABEL;

  const id = WithRouter('id');
  const name = WithRouter('name');
  const Ntt = WithRouter('Ntt');
  const xlpId = pageName === 'search' ? Ntt : id;

  const isFacetWithSwitchVisible = _.get(appCtx, 'siteConfig.toggles.isFacetWithSwitchVisible', false);
  const isEventSwitchVisible = _.get(appCtx, 'siteConfig.toggles.isEventToggleVisible', false);
  const isWhiteLabelExperience = _.get(appCtx, 'siteConfig.toggles.isWhiteLabelExperience', false);

  const handlerSortOption = ({ url }) => {
    if (pageName === 'category') {
      navigateWithUrlForCategory({
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        categoryId: id,
        categoryName: name,
        store,
        regionCode,
        isWhiteLabelExperience,
      });
    } else if (pageName === 'collection') {
      navigateWithUrlForCollection({
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        collectionId: id,
        store,
        regionCode,
        isWhiteLabelExperience,
      });
    } else if (pageName === 'seller') {
      navigateWithUrlForSeller({
        regionCode,
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        sellerName: id,
        store,
        isWhiteLabelExperience,
      });
    } else if (pageName === 'brand') {
      navigateWithUrlForBrand({
        regionCode,
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        brandName: id,
        store,
      });
    } else if (pageName === 'shop') {
      navigateWithUrlForShop({
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        store,
        regionCode,
        shopId: id,
        isWhiteLabelExperience,
      });
    } else {
      navigateWithUrl({
        site: tenant,
        onRouteChange: () => {
          setBusy(true);
        },
        url,
        store,
        regionCode,
        isWhiteLabelExperience,
      });
    }
  };
  const [appliedFacets, setAppliedFacets] = useState(initialAppliedFacets);
  const [digitalData, setDigitalData] = useState([]);
  const { baseFacetsURL, changeURL } = dataMethods;
  const initPaginationCount = _.get(pagination, 'data.count', undefined);
  const [paginationCount, setCount] = useState(initPaginationCount);

  const [isMenuActive, setMenuActive] = useState(false);
  const [isSortByDialogVisible, setSortyByDialogVisibility] = useState(false);

  const [selectedURLs, setSelectedURLs] = useState(originalSelection);
  const [selectedURLsForAvailabilityFacets, setSelectedURLsForAvailabilityFacets] =
    useState(selectedURLsForAvailability);
  const [selectedMenu, setSelectedMenu] = useState({});
  const [selectedFacet, setSelectedFacet] = useState('');

  const [facetCount, setFacetCount] = useState(0);
  const [availabilityFacetCount, setAvailabilityFacetCount] = useState(0);
  const [facets, setFacets] = useState(initialFacetsData);
  const [availabilityFacets, setAvailabilityFacets] = useState(initialAvailabilityFacets);
  const [finalSelectedUrl, setFinalSelectedUrl] = useState('');
  const [headerTitle, setHeaderTitle] = useState(title);
  const [selectedFacetsMenu, setSelectedFactesInsideMenu] = useState([]);

  useEffect(() => {
    onHideMenu(() => setIsFiltersMenuVisible(false));
  }, []);

  useEffect(() => {
    const panelState = isFiltersMenuVisible || isSortByDialogVisible;
    toggleBodyClass(panelState, 'FacetMobilePanel--open');
  }, [isFiltersMenuVisible, isSortByDialogVisible]);

  useEffect(() => {
    if (Object.keys(selectedMenu).length === 0) {
      setHeaderTitle(title);
      setMenuActive(false);
    }
  }, [selectedMenu, title]);

  const updateCount = useCallback(() => {
    const reducer = (acc, facet) => {
      let total = acc + facet.values.filter(({ selected }) => selected).length;
      if (facet.meta && facet.meta[0].selected) {
        total += 1;
      }
      return total;
    };
    const facetsCount = initialFacetsData.reduce(reducer, 0);
    const availabilityCount = initialAvailabilityFacets.reduce(reducer, 0);
    setFacetCount(facetsCount);
    setAvailabilityFacetCount(availabilityCount);
  }, [initialFacetsData, initialAvailabilityFacets]);

  const filterFacetUrls = useCallback(
    (initialUrls) => {
      const urls = initialUrls;
      if (isTottusStandalone(store)) {
        if (
          urls[constants.FACETS.PRICE_SEARCH_KEY_TOTTUS] &&
          urls[constants.FACETS.PRICE_SEARCH_KEY_TOTTUS][0] === '::'
        ) {
          delete urls[constants.FACETS.PRICE_SEARCH_KEY_TOTTUS];
        }
      } else if (urls[constants.FACETS.PRICE_SEARCH_KEY] && urls[constants.FACETS.PRICE_SEARCH_KEY][0] === '::') {
        delete urls[constants.FACETS.PRICE_SEARCH_KEY];
      }

      const facetURLArray = [];
      Object.keys(urls).forEach((key) => {
        const value = urls[key].sort();
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(value.join('::'));
        if (value.length) facetURLArray.push(`&${encodedKey}=${encodedValue}`);
      });
      return facetURLArray;
    },
    [store]
  );

  const setSelectedFacets = useCallback(() => {
    const initSelectedURLs = {};
    const initSelectedURLsForAvailabilityFacets = {};

    initialFacetsData.forEach((facet) => {
      facet.values.forEach((value) => {
        const { selected, facetType, facetValue } = value;
        if (selected) {
          initSelectedURLs[facetType] = [...(initSelectedURLs[facetType] || []), facetValue];
        }
      });
    });

    initialAvailabilityFacets.forEach((facet) => {
      facet.values.forEach((value) => {
        const { selected, facetType, facetValue } = value;
        if (selected) {
          initSelectedURLsForAvailabilityFacets[facetType] = [
            ...(initSelectedURLsForAvailabilityFacets[facetType] || []),
            facetValue,
          ];
        }
      });
    });

    const urls = {
      ...initSelectedURLs,
      ...initSelectedURLsForAvailabilityFacets,
    };
    const facetURLArray = filterFacetUrls(urls);

    setSelectedURLs(initSelectedURLs);
    setSelectedURLsForAvailabilityFacets(initSelectedURLsForAvailabilityFacets);
    setFinalSelectedUrl([...facetURLArray].sort().join(''));
  }, [initialFacetsData, initialAvailabilityFacets, filterFacetUrls]);

  useEffect(() => {
    setFacets(initialFacetsData);
    setAvailabilityFacets(initialAvailabilityFacets);
    setAppliedFacets(initialAppliedFacets);
    setCount(initPaginationCount);
    updateCount();
    setSelectedFacets();
  }, [
    initialFacetsData,
    initialAvailabilityFacets,
    initialAppliedFacets,
    initPaginationCount,
    updateCount,
    setSelectedFacets,
  ]);
  useEffect(() => {
    handleScrollToTopProductList(selectedURLs);
  }, [handleScrollToTopProductList, selectedURLs]);

  useEffect(() => {
    if (selectedFacetsMenu.length) {
      setSelectedFactesInsideMenu([]);
    }
  }, [selectedFacet, initialFacetsData, initialAvailabilityFacets, selectedFacetsMenu.length]);

  useEffect(() => {
    if (digitalData.length > 0) {
      const eventToDigitalData = new CustomEvent('DDXLPMobileTabInteraction', {
        bubbles: true,
        detail: { payload: digitalData.join('|') },
      });
      window.dispatchEvent(eventToDigitalData);
    }
  }, [digitalData]);

  const makeFacetCall = async (facetUrl) => {
    const url = facetUrl;
    const zoneParams = `pid=${encodeURIComponent(appCtx.politicalId)}&zones=${encodeURIComponent(
      appCtx.zones
    )}&pgid=${encodeURIComponent(appCtx.priceGroupId)}`;
    const storeParam = store ? `&store=${store}` : '';

    appCtx.setBusy(true);

    const getFacetValuesUrl = apiConfig.getSelectedFacetValues({
      base: getHostUrl({ config, store, regionCode }),
      siteId: regionCode,
      params: `${url}&${zoneParams}&xlpId=${encodeURIComponent(xlpId)}&xlpName=${pageName}${storeParam}`,
    });
    const updatedFacets = await httpService(appCtx).get(getFacetValuesUrl);

    if (_.get(updatedFacets, 'data.data.availabilityFacets', undefined)) {
      setAvailabilityFacets(updatedFacets.data.data.availabilityFacets);
    }

    if (_.get(updatedFacets, 'data.data.pagination.count', undefined)) {
      const updatePagination = updatedFacets.data.data.pagination.count;
      setCount(updatePagination);
    }
    const updatedAppliedFacets = _.get(updatedFacets, 'data.data.appliedFacets', undefined);
    if (appliedFacets) {
      setAppliedFacets(updatedAppliedFacets);
    }
    if (_.get(updatedFacets, 'data.data.facets', undefined)) {
      setFacets(updatedFacets.data.data.facets);
    }
    appCtx.setBusy(false);
    setFinalSelectedUrl(facetUrl);
    changeURL(facetUrl);
  };

  const onFacetApply = () => {
    const url = finalSelectedUrl;
    const urls = [];
    if (baseFacetsURL) {
      urls.push(baseFacetsURL);
    }
    if (url) {
      const facetURL = url.split('&').filter((u) => {
        return !!u && !u.match(/facetSelected/g);
      });
      if (facetURL.length > 0) {
        urls.push('facetSelected=true', facetURL.join('&'));
      }
    }
    changeURL([...urls].sort().join('&'));
  };

  const digitalDataSetter = (type, interacted = false) => {
    const userInteractedUpdates = JSON.parse(JSON.stringify(digitalData));
    userInteractedUpdates[0] = 1;
    userInteractedUpdates[1] = 0;
    if (type) {
      userInteractedUpdates[2] = type;
    }
    if (interacted) {
      userInteractedUpdates[1] = 1;
    }
    setDigitalData(userInteractedUpdates);
  };

  const onFacetChange = async (obj) => {
    facetClick();
    const { url, dontMakeFacetCall } = obj;
    const urls = [];
    if (baseFacetsURL) {
      urls.push(baseFacetsURL);
    }
    if (url) {
      const facetURL = url.split('&').filter((u) => !u.match(/facetSelected/g));
      if (facetURL.length > 0) {
        urls.push(facetURL.join('&'));
      }
    }
    if (!dontMakeFacetCall) {
      await makeFacetCall(urls.join('&'));
      digitalDataSetter('', true);
    }
  };

  const facetData = facets.map(
    getFacetsData({
      groupType: '',
      tenant,
      onFacetChange,
      baseFacetsURL,
      changeURL,
      priceRangeErrorLabel,
      priceRangeNoResultsLabel,
      searchInFacetsLabel,
      pageName,
      categoryId: id,
      hideMenuFacets: () => setIsFiltersMenuVisible(false),
      appCtx,
      isMobileFacetUI: true,
      selectedFacetsMenu,
      selectedFacet,
      getUpdatedFacets,
      canonicalUrl,
    })
  );
  const availabilityFacetsData = availabilityFacets.map(
    getFacetsData({
      groupType: 'MULTI_SELECT_WITH_AVAILABILITY',
      tenant,
      onFacetChange,
      baseFacetsURL,
      freeShippingFacet,
      changeURL,
      priceRangeErrorLabel,
      priceRangeNoResultsLabel,
      searchInFacetsLabel,
      pageName,
      categoryId: id,
      hideMenuFacets: () => setIsFiltersMenuVisible(false),
      appCtx,
      isMobileFacetUI: true,
      selectedFacetsMenu,
      selectedFacet,
      getUpdatedFacets,
      canonicalUrl,
    })
  );

  const onFacetClose = (hideMenu) => () => {
    const initFacets = initialFacetsData;
    initFacets.forEach((facet, index) => {
      initFacets[index].state = false;
    });
    const initAvailabilityFacets = initialAvailabilityFacets;
    initAvailabilityFacets.forEach((facet, index) => {
      initFacets[index].state = false;
    });
    setFacets(initFacets);
    setAvailabilityFacets(initAvailabilityFacets);
    setAppliedFacets(initialAppliedFacets);
    setCount(initPaginationCount);
    updateCount();
    setSelectedFacets();
    hideMenu();
  };

  const onFacetChangeForEvent = (obj) => {
    const { url } = obj;
    const urls = [];
    if (baseFacetsURL) {
      urls.push(baseFacetsURL);
    }
    if (url) {
      const facetURL = url.split('&').filter((u) => !u.match(/facetSelected/g));
      if (facetURL.length > 0) {
        urls.push('facetSelected=true', facetURL.join('&'));
      }
    }
    changeURL(urls.join('&'));
    onFacetChangeHandler();
  };

  const eventData = facetData.filter((d) => constants.EVENT_REGEX.test(d.type));

  const cleanData = () => {
    changeURL(baseFacetsURL);
  };

  const facetMobileDialogAction = (obj) => {
    switch (obj.action) {
      case 'APPLY':
        onFacetApply();
        break;

      case 'CLEAN':
        cleanData();
        break;
      default:
        break;
    }
  };

  const handleAccordianClick = async (facetTitle, group) => {
    setSelectedFacet(facetTitle);
    if (constants.SHIPPING_GROUP_REGEX.test(group)) {
      setAvailabilityFacets(
        availabilityFacetsData.map((facet) => {
          return {
            ...facet,
            state: facet.name === facetTitle ? !facet.state : false,
          };
        })
      );
    } else {
      setFacets(
        facetData.map((facet) => {
          return {
            ...facet,
            state: facet.name === facetTitle ? !facet.state : false,
          };
        })
      );
    }
    digitalDataSetter('', true);
  };

  const dialogData = (showDialog, facetDataForDialog, hideMenu, isGroupedFacets, pane) => {
    const onClean = () => {
      facetMobileDialogAction({
        action: 'CLEAN',
        isGroupedFacets,
      });
    };

    const hideActiveMenu = () => {
      facetMobileDialogAction({
        action: 'BACK',
      });
    };

    const removeSortOption = () => {
      const currentUrl = new URL(Router.asPath, window.location.origin);
      currentUrl.searchParams.delete('sortBy');
      changeURL(currentUrl.search);
    };

    const body = () => (
      <>
        <div>
          <div className="group-title">{labels.ORDER_BY_DESKTOP}</div>
          <div className="chips-wrapper">
            <div className="chips">
              {sortOptions
                .sort((a, b) => {
                  if (a.selected && !b.selected) return -1;
                  if (!a.selected && b.selected) return 1;
                  return 0;
                })
                .map((option) => (
                  <Chip
                    hideRemove={!option.selected || option.url.includes('sortBy=_score')}
                    value={option.label}
                    key={option.url}
                    onChipClick={changeURL}
                    url={option.url}
                    active={option.selected}
                    onRemove={removeSortOption}
                  />
                ))}
            </div>
          </div>
        </div>

        <div className="group-title">{labels.SELECTED_FILTERS_MOBILE}</div>
        <MobileMenu
          pane={pane}
          isGroupedFacets={isGroupedFacets}
          data={
            isFacetWithSwitchVisible
              ? facetDataForDialog.filter((d) => {
                  if (isEventSwitchVisible) {
                    return !constants.FREE_SHIPPING_REGEX.test(d.name) && !constants.EVENT_REGEX.test(d.type);
                  }
                  return !constants.FREE_SHIPPING_REGEX.test(d.name);
                })
              : facetDataForDialog.filter((d) => {
                  if (isEventSwitchVisible) {
                    return !constants.EVENT_REGEX.test(d.type);
                  }
                  return true;
                })
          }
          appCtx={appCtx}
          facetTitleClass="mkp-facet-title"
          handleAccordianClick={handleAccordianClick}
          baseFacetsURL={baseFacetsURL}
          appliedFacets={appliedFacets}
          makeFacetCall={makeFacetCall}
          digitalDataSetter={digitalDataSetter}
          facets={facets}
        />
      </>
    );

    const footer = () => (
      <FacetsMobileFooter
        isGroupedFacets={isGroupedFacets}
        facetMobileDialogAction={facetMobileDialogAction}
        hideMenu={hideMenu}
        cleanSelectedFacets={onClean}
        readyButtonType="mkp-secondary"
        readyButtonSize="small primary-theme"
        count={paginationCount}
        appliedFacets={appliedFacets}
        digitalDataSetter={digitalDataSetter}
      />
    );

    return {
      isMenuActive,
      animationDirection: 'rightToLeft',
      showDialog,
      header: {
        headerClass: 'mkp-header',
        title: isGroupedFacets ? mobileAvailabilityTitle : headerTitle,
        onClose: onFacetClose(hideMenu),
        onClean,
        hideActiveMenu,
      },
      body,
      footer,
    };
  };

  const handleStickyFacetHeader = () => {
    if (!ref.current) return;
    const topOffset = ref.current.getBoundingClientRect().top;
    if (topOffset <= 0) {
      toggleBodyClass(true, 'stickyHeader');
    } else {
      toggleBodyClass(false, 'stickyHeader');
    }
  };

  useOnScroll({
    handler: _.debounce(handleStickyFacetHeader, 10),
  });

  const body = () => (
    <MobileLinkMenu
      title={labels.CHOOSE_OPTION}
      links={sortOptions}
      onSelection={(link) => {
        handlerSortOption(link);
        setSortyByDialogVisibility(false);
        onFacetChangeHandler();
        digitalDataSetter(dropDownLabel, true);
      }}
    />
  );

  return (
    <div className="facetsMobile" ref={ref} id="testId-facets-mobile-container">
      {sortOptions.length > 0 && (
        <DialogWithoutSSR
          animationDirection="rightToLeft"
          showDialog={isSortByDialogVisible}
          header={{
            title: `${labels.ORDER_BY_DESKTOP}`,
            onClose: () => {
              setSortyByDialogVisibility(false);
            },
            onClean: noop,
            hideActiveMenu: noop,
          }}
          body={body}
        />
      )}
      <DialogWithoutSSR
        {...dialogData(
          isFiltersMenuVisible,
          [...availabilityFacetsData, ...facetData],
          () => setIsFiltersMenuVisible(false),
          false,
          'multiple',
          null
        )}
      />
      <style jsx>{facetsMobileStyles}</style>
      <style jsx>{facetsMobileGlobalStyle}</style>
      <style>{`
        .chips-wrapper {
          width: 100%;
          overflow-x: auto;
        }
        .chips {
          display: flex;
          flex-wrap: nowrap;
          gap: 8px;
              padding: 12px 18px;
        }
        .chips .chip {
          font-size: 0.9em;
          white-space: nowrap;
          margin: 0;
          height: 30px;
          border-radius: 8px;
        }
        .selected-filters .chips .chip, .chips .chip.active {
          border-color: ${getStoreShade(store, 5)};
          background-color: ${getStoreShade(store, 1)};
        }
        .selected-filters .chips .chip {
          color: ${getStoreShade(store, 5)};
          font-weight: bold;
        }
        .chips .chip button {
          background: transparent;
        }
        .chips .chip.active button, .selected-filters .chips .chip button {
          color: ${getStoreShade(store, 10)};
          font-weight: bold;
        }
        .group-title {
          min-height: 40px;
          font-size: 1.6rem;
          padding: 0 18px;
          display: flex;
          align-content: center;
          flex-wrap: wrap;
          background-color: ${colors.gray.shade3};
          font-weight: bold;
        }
        header.mkp-header {
          background: white;

        }
        header.mkp-header h1, header.mkp-header .actions .clear {
          color: #333 !important;
        }
        .mobileMenuContainer .accordion {
          background-color: ${colors.gray.shade3};
        }
        .facetsMobile .dialog .body {
          padding: 0;
        }
      `}</style>
    </div>
  );
};

FacetsMobile.defaultProps = {
  onHideMenu: noop,
  pageName: 'search',
  onFacetChangeHandler: noop,
  canonicalUrl: '',
  freeShippingFacet: { values: [] },
};

FacetsMobile.propTypes = {
  title: PropTypes.string.isRequired,
  mobileTitle: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  sortOptions: PropTypes.array.isRequired,
  dropDownLabel: PropTypes.string.isRequired,
  availabilityFacets: PropTypes.array.isRequired,
  appCtx: PropTypes.object.isRequired,
  onHideMenu: PropTypes.func,
  selectedURLs: PropTypes.object.isRequired,
  dataMethods: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  pageName: PropTypes.string,
  selectedURLsForAvailability: PropTypes.object.isRequired,
  onFacetChangeHandler: PropTypes.func,
  getUpdatedFacets: PropTypes.func.isRequired,
  appliedFacets: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  handleScrollToTopProductList: PropTypes.func.isRequired,
  isFiltersMenuVisible: PropTypes.bool.isRequired,
  setIsFiltersMenuVisible: PropTypes.func.isRequired,
  canonicalUrl: PropTypes.string,
  freeShippingFacet: PropTypes.shape({ facets: PropTypes.array }),
};

export { FacetsMobile, FacetsMobileFooter };

export default withApplicationContext(withLabels(withConfig(FacetsMobile)));
