import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Drawer from 'react-bottom-drawer';

import constants from '../../../config/constants';
import { RouterContext } from '../../../containers/Layout/RouterContext';
import { logger } from '../../../lib/logger';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';

import { emitDigitalDataEvent } from '../../../external_dependencies/analytic/digitalData';
import noop from '../../../utils/noop';
import SkipSSRFacets from '../../../utils/SkipSSRFacets';
import getFacetsData from '../Common/getFacetsData';

import { colors, zIndex } from '../../../config/styles';
import Button from '../../ui/Button/Button';
import DynamicFacetsBar from '../DynamicFacetsBar';
import FacetsWithSwitch from '../FacetsWithSwitch/FacetsWithSwitch';
import FacetWithSwitch from '../FacetsWithSwitch/FacetWithSwitch/FacetWithSwitch';
import ShippingFacetsBar from '../ShippingFacetsBar';
import { actionsStyles } from './FacetsMobile.style';

const Render = ({ children, variant }) => (
  <div className={`facetsDesktop ${variant} dynamic-container`}>{children}</div>
);
Render.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string.isRequired,
};

const DynamicFacets = (props) => {
  const {
    facets: facetsProp,
    availabilityFacets,
    tenant,
    baseFacetsURL,
    changeURL,
    pageName,
    onFacetChange,
    store,
    appCtx,
    getUpdatedFacets,
    labels,
    setIsFiltersMenuVisible,
    selectedFacetsCount,
  } = props;
  const [facets, setFacets] = useState([]);
  const [isTabUsed, setIsTabUsed] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showPlus, setShowPlus] = useState(false);
  const routerContext = useContext(RouterContext.Context);
  const allFacets = useMemo(() => facetsProp.concat(availabilityFacets), [facetsProp, availabilityFacets]);
  const loadFiltersFromUrl = useCallback(() => {
    const params = routerContext?.router?.asPath?.split('?')?.[1];
    if (!params) {
      setSelectedOptions({});
      return;
    }
    setSelectedOptions(
      params.split('&').reduce((sum, option) => {
        if (['site', 'Ntt'].includes(option.split('=')[0])) return sum;
        return { ...sum, [decodeURIComponent(option.split('=')[0])]: option.split('=')[1] };
      }, {})
    );
  }, [routerContext?.router?.asPath]);

  useEffect(() => {
    setFacets(
      allFacets?.map((facet) => ({
        ...facet,
        values: facet?.values?.map((value) => ({
          ...value,
          extraId: 'dynamic',
        })),
      }))
    );
  }, [allFacets, setFacets]);

  const [selectedGroupKey, setSelectedGroupKey] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isNewOmniChannelExperience = _.get(appCtx, 'siteConfig.toggles.isNewOmniChannelExperience', true);
  const dynamicFacetsLimit = parseInt(_.get(appCtx, 'siteConfig.configurations.dynamicFacets.limit', 6), 10);

  const isDynamicFacetsEnabled = appCtx?.siteConfig?.toggles?.isDynamicFacetsEnabled || true;
  const freeShippingFacet = facetsProp.find((d) => constants.FREE_SHIPPING_REGEX.test(d.name));
  const showShippingFacetsBar = isNewOmniChannelExperience && (availabilityFacets.length > 0 || freeShippingFacet);
  const searchInFacetsLabel = labels.SEARCH_IN_FACETS_LABEL;
  const priceRangeErrorLabel = labels.ENTER_VALID_PRICE_ERROR_LABEL;
  const priceRangeNoResultsLabel = labels.PRICE_NO_RESULTS_ERROR_LABEL;

  useEffect(() => {
    loadFiltersFromUrl();
  }, [loadFiltersFromUrl]);

  const onFacetChangeEvent = useCallback(
    (obj) => {
      setIsTabUsed(true);
      // toggle switch
      if (obj.isSwitchFacet) {
        setFacets((prevFacets) =>
          prevFacets.map((f) => {
            if (f.title === obj.title) {
              return {
                ...f,
                values: [
                  {
                    ...f.values[0],
                    selected: !f.values[0].selected,
                  },
                ],
              };
            }
            return f;
          })
        );
      }
      // Split current values for facet
      let opts = selectedOptions?.[obj.facetType]
        ? decodeURIComponent(selectedOptions?.[obj.facetType])
            .split('::')
            .map((str) => str.replace(/\+/g, ' '))
        : [];
      // Add or remove value
      opts = opts.includes(obj.facetValue) ? opts.filter((opt) => opt !== obj.facetValue) : [...opts, obj.facetValue];
      // Reencode for url
      opts = encodeURIComponent(opts.join('::'));

      // Update options with current value, add if not present or remove if empty
      setSelectedOptions(
        !(obj.facetType in selectedOptions)
          ? { ...selectedOptions, [obj.facetType]: opts }
          : Object.keys(selectedOptions).reduce((sum, key) => {
              if (key !== obj.facetType) return { ...sum, [key]: selectedOptions[key] };
              if (key === obj.facetType && opts) return { ...sum, [key]: opts };
              return sum;
            }, {})
      );
    },
    [selectedOptions]
  );

  const getFacetWithSelectedOptions = useCallback(
    (facet) => {
      if (!facet?.values) return facet;
      const isValueSelected = (key, value) => {
        if (selectedOptions?.[key]) {
          return decodeURIComponent(selectedOptions[key])
            .split('::')
            .map((str) => str.replace(/\+/g, ' '))
            .includes(value);
        }
        return false;
      };
      return {
        ...facet,
        values: facet.values.map((value) => ({
          ...value,
          selected: isValueSelected(value.facetType, value.facetValue),
        })),
      };
    },
    [selectedOptions]
  );

  const getFacetData = useCallback(
    (facet, includeSelectedOptions) => {
      const gtype =
        constants.FREE_SHIPPING_REGEX.test(facet.name) || constants.EVENT_REGEX.test(facet.type)
          ? 'facetsWithSwitch'
          : facet.type;
      const data = getFacetsData({
        groupType: gtype,
        tenant,
        onFacetChange: onFacetChangeEvent,
        baseFacetsURL,
        changeURL,
        priceRangeErrorLabel,
        priceRangeNoResultsLabel,
        searchInFacetsLabel,
        pageName,
        hideMenuFacets: noop,
        appCtx,
        getUpdatedFacets,
        showAllBrands: true,
      })(includeSelectedOptions ? getFacetWithSelectedOptions({ ...facet }) : { ...facet });
      data.content = data.state ? data.content : SkipSSRFacets(data.content);
      return data;
    },
    [
      tenant,
      onFacetChangeEvent,
      baseFacetsURL,
      changeURL,
      priceRangeErrorLabel,
      priceRangeNoResultsLabel,
      searchInFacetsLabel,
      pageName,
      appCtx,
      getUpdatedFacets,
      getFacetWithSelectedOptions,
    ]
  );

  const isShippingFacet = (facet) =>
    constants.FREE_SHIPPING_REGEX.test(facet.name) ||
    constants.SHIPPING_GROUP_REGEX.test(facet.group) ||
    constants.SHIPPING_FACET_NAMES.includes(facet.name);

  /**
   * Every facet group corresponds to a button in the DynamicFacetsBar.
   * Most should have a facets array with a single facet but shipping might have multiple.
   */
  const getFacetGroups = useCallback(
    (facetsList, includeSelectedOptions) => {
      const filtrarKeyword = _.get(appCtx, 'siteConfig.textDictionary.FILTRAR', '');
      const facetArray = facetsList || [];
      const groups = [];

      if (facetArray.length === 0) return [];

      try {
        groups.push({
          key: 'filters',
          name: `${filtrarKeyword}(${selectedFacetsCount})`,
          icon: 'csicon-filter',
          type: 'filters',
          skipDrawer: true,
          active: selectedFacetsCount > 0,
          facets: [],
        });

        // Show shipping facets for the first button.
        const shippingFacets = facetArray.filter(isShippingFacet);
        if (!showShippingFacetsBar && shippingFacets.length > 0) {
          groups.push({
            key: 'shipping',
            name: 'Entrega',
            icon: 'csicon-disponibilidad-mkp',
            type: 'availability',
            facets: shippingFacets.map((facet) => getFacetData(facet, includeSelectedOptions)),
          });
        }

        // If there's an event it must be the second button.
        const eventFacet = facetArray.find((d) => constants.EVENT_REGEX.test(d.type));
        if (eventFacet) {
          groups.push({
            key: 'event',
            name: eventFacet.name,
            icon: 'csicon-event',
            skipDrawer: true,
            facets: [getFacetData(eventFacet, includeSelectedOptions)],
          });
        }
        // popular brand
        const popularBrandFacet = facetArray.find((d) => constants.POPULAR_BRAND.test(d.type));
        if (popularBrandFacet) {
          groups.push({
            name: popularBrandFacet.name,
            facets: [getFacetData(popularBrandFacet, includeSelectedOptions)],
            skipDrawer: true,
          });
        }
        // Brand must be the third button.
        const brand = facetArray.find((d) => d?.name === 'Marca');
        if (brand)
          groups.push({
            key: 'brand',
            name: brand.name,
            facets: [getFacetData(brand, includeSelectedOptions)],
          });
        let remaining = facetArray
          .filter(
            (facet) =>
              facet.displayType === 'DYNAMIC' &&
              !isShippingFacet(facet) &&
              facet.name !== 'Marca' &&
              facet.type !== 'SINGLE_SELECT_STATIC' &&
              (eventFacet ? facet.name !== eventFacet.name : true)
          )
          // Move selected facets to the top.
          .sort((a, b) => {
            const aSelected = a.values.some((value) => value.selected);
            const bSelected = b.values.some((value) => value.selected);
            return bSelected - aSelected;
          });

        setShowPlus(remaining.length > dynamicFacetsLimit - groups.length);

        remaining = remaining.slice(0, Math.max(0, dynamicFacetsLimit - groups.length)).map((f) => ({
          key: f.key || `${(f.group || f.name).replace(/ /g, '_')}_${f.order}`,
          name: f.name,
          facets: [getFacetData(f, includeSelectedOptions)],
        }));
        // Show the rest of the facets.
        return [...groups, ...remaining];
      } catch (e) {
        logger.error('Error in DynamicFacets.js: ', e.message, routerContext?.router?.asPath);
        return [];
      }
    },
    [dynamicFacetsLimit, getFacetData, routerContext?.router?.asPath]
  );

  const initialFacetGroups = useMemo(() => getFacetGroups(facets, false), [facets, getFacetGroups]);
  const facetGroups = useMemo(() => getFacetGroups(facets, true), [facets, getFacetGroups]);
  const selectedGroup = useMemo(
    () => (selectedGroupKey ? facetGroups.find((fg) => fg.key === selectedGroupKey) : false),
    [selectedGroupKey, facetGroups]
  );

  const applyFilters = (opts) => {
    if (isTabUsed) {
      emitDigitalDataEvent('DDXLPTabInteraction', `1|1|1|${selectedGroup.name}`);
      setIsTabUsed(false);
    }
    let urls = [];
    if (baseFacetsURL) {
      urls.push(baseFacetsURL);
    }
    urls = [...urls, ...Object.keys(opts).map((key) => `${key}=${opts[key]}`)];
    if (Object.keys(opts).length > 0 && !opts?.facetSelected) urls.push('facetSelected=true');
    setIsDrawerOpen(false);
    changeURL(urls.join('&'));
    onFacetChange();
  };

  const clearFilters = () => {
    const activeFacets = [];
    selectedGroup.facets.forEach((facet) => {
      facet?.values?.forEach((value) => {
        if (value?.facetType && !activeFacets.includes(value?.facetType)) activeFacets.push(value.facetType);
      });
    });
    const opts = Object.keys(selectedOptions).reduce((sum, key) => {
      if (activeFacets.includes(key)) return sum;
      return { ...sum, [key]: selectedOptions[key] };
    }, {});
    if (opts?.facetSelected && Object.keys(opts).length === 1) delete opts.facetSelected;
    setSelectedOptions(opts);
    applyFilters(opts);
  };

  const selectFacetGroup = (group) => {
    setSelectedGroupKey(group.key);
    if (group.key === 'filters') {
      setIsFiltersMenuVisible(true);
      return;
    }
    if (group.skipDrawer) {
      const alreadySelectedFilters = JSON.parse(JSON.stringify(selectedOptions));
      const values = group?.facets?.[0]?.values[0];
      const filters = values?.filters[0] || {};
      const facetType = values?.facetType;
      const currentFacet = { [filters.name]: filters.value };
      delete alreadySelectedFilters[facetType];
      const options = { ...currentFacet, ...alreadySelectedFilters };
      setSelectedOptions(options);
      applyFilters(options);
      return;
    }
    if (group.key !== 'event') {
      setIsDrawerOpen(true);
      return;
    }

    const eventFacetKey = group.facets[0].values[0].facetType;
    const options = { ...selectedOptions };
    if (options[eventFacetKey]) delete options[eventFacetKey];
    else options[eventFacetKey] = true;
    setSelectedOptions(options);
    applyFilters(options);
  };

  const closeDrawer = () => {
    if (isTabUsed) {
      loadFiltersFromUrl();
      emitDigitalDataEvent('DDXLPTabInteraction', ` 1|1|0|${selectedGroup?.name}`);
    } else {
      emitDigitalDataEvent('DDXLPTabInteraction', ` 1|0|0|${selectedGroup?.name}`);
    }
    setIsTabUsed(false);
    setIsDrawerOpen(false);
    setSelectedGroupKey('');
  };

  return (
    <div className="dynamic-facets-wrapper">
      <div className="dynamic-facets-container">
        {isDynamicFacetsEnabled && (
          <DynamicFacetsBar
            groups={initialFacetGroups}
            onSelect={selectFacetGroup}
            store={store}
            selectedKey={selectedGroupKey}
            isOpen={isDrawerOpen}
            showPlus={showPlus}
            setIsFiltersMenuVisible={setIsFiltersMenuVisible}
            selectedFacetsCount={selectedFacetsCount}
          />
        )}
        {showShippingFacetsBar && (
          <ShippingFacetsBar
            shippingFacetGroup={availabilityFacets.find((facet) => constants.SHIPPING_GROUP_REGEX.test(facet.group))}
            freeShippingFacet={freeShippingFacet}
            selectedOptions={selectedOptions}
            onSelect={onFacetChange}
            store={store}
            dataMethods={{
              type: 'availability',
              tenant,
              baseFacetsURL,
              changeURL,
            }}
          />
        )}
      </div>
      <Drawer isVisible={isDrawerOpen} onClose={closeDrawer}>
        {selectedGroup && (
          <div className="drawer">
            <div className="title">{selectedGroup.name}</div>
            {selectedGroup.facets.map((facet) => {
              if (constants.FREE_SHIPPING_REGEX.test(facet.name)) {
                return (
                  <FacetWithSwitch
                    key={`${facet.name}_${facet.order}`}
                    item={facet}
                    onFacetChange={onFacetChangeEvent}
                    withIcon
                    type="freeShipping"
                    appCtx={appCtx}
                  />
                );
              }
              switch (selectedGroup.type) {
                case 'facetsWithSwitch': {
                  return <FacetsWithSwitch key={`${facet.name}_${facet.order}`} items={facet} />;
                }
                case 'availability': {
                  if (isNewOmniChannelExperience) {
                    return (
                      <Render key={`${facet.name}_${facet.order}`} variant="shipping" type={selectedGroup.type}>
                        {facet.content()}
                      </Render>
                    );
                  }
                  return (
                    <Render key={`${facet.name}_${facet.order}`} variant="shipping" type={selectedGroup.type}>
                      {facet.content()}
                    </Render>
                  );
                }
                default:
                  return (
                    <Render key={`${facet.name}_${facet.order}`} type={selectedGroup.type}>
                      {facet.content()}
                    </Render>
                  );
              }
            })}
            <div className="action-buttons">
              <Button type="secondary-link-mkp" isHighlighted onClick={clearFilters}>
                Limpiar filtros
              </Button>
              <Button type="mkp-secondary" onClick={() => applyFilters(selectedOptions)}>
                Mostrar
              </Button>
            </div>
            <style>{actionsStyles}</style>
          </div>
        )}
      </Drawer>
      <style jsx>{`
        .dynamic-facets-wrapper {
          .title {
            font-size: 2rem;
            font-family: Lato, sans-serif;
            font-weight: 700;
            padding: 1em 15px 0.5em 15px;
            margin-bottom: 0.2em;
          }
          :global(.searchBox),
          :global(.ver-mas-container),
          :global(.verMas-button-container) {
            background: none;
          }
          .drawer {
            padding-bottom: 80px;
          }

          :global(& .multiselect-container) {
            background-color: transparent !important;
          }

          :global(& .multiselect-container .checkbox .title) {
            align-items: center !important;
          }

          :global(& .multiselect li:last-child) {
            border-bottom: none !important;
          }
        }
        .dynamic-facets-container {
          display: flex;
          flex-direction: column;
          gap: 8px;
          background: white;
          padding: 8px 0;
        }
        :global(.stickyHeader .dynamic-facets-container) {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 120;
          box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
          -webkit-transition: all 225ms ease-out;
          transition: all 225ms ease-out;
        }

        .action-buttons {
          position: fixed;
          display: flex;
          bottom: 0;
          width: 100%;
          height: 75px;
          justify-content: center;
          align-items: center;
          background-color: ${colors.white.shade1};
          z-index: ${zIndex.dialog.footer};
          gap: 24px;

          :global(& .button) {
            width: auto !important;
          }
          :global(& .button-mkp-secondary) {
            font-size: 1.6rem !important;
            padding: 0 32px;
            font-weight: bold;
          }
        }
      `}</style>
    </div>
  );
};

DynamicFacets.defaultProps = {
  facets: [],
  availabilityFacets: [],
  tenant: '',
  baseFacetsURL: '',
  changeURL: '',
  pageName: '',
  store: '',
  onFacetChange: () => {},
  getUpdatedFacets: noop,
  labels: {},
  appCtx: {},
  setIsFiltersMenuVisible: noop,
  selectedFacetsCount: 0,
};

DynamicFacets.propTypes = {
  facets: PropTypes.arrayOf(PropTypes.shape),
  availabilityFacets: PropTypes.arrayOf(PropTypes.shape),
  tenant: PropTypes.string,
  baseFacetsURL: PropTypes.string,
  changeURL: PropTypes.func,
  pageName: PropTypes.string,
  onFacetChange: PropTypes.func,
  store: PropTypes.string,
  getUpdatedFacets: PropTypes.func,
  labels: PropTypes.object,
  appCtx: PropTypes.object,
  setIsFiltersMenuVisible: PropTypes.func,
  selectedFacetsCount: PropTypes.number,
};

export default withApplicationContext(withLabels(DynamicFacets));
