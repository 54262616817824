import css from 'styled-jsx/css';
import { colors } from '../../../../config/styles';

const StoresModalStyles = css`
  .stores-filter-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .facets-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    width: 100%;
    max-height: 260px;
    overflow-y: auto;
  }
  .subtitle {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding-bottom: 8px;
  }
  .store-facet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid ${colors.gray.shade37};
  }

  .facility-name,
  .select-all-stores {
    color: ${colors.black.shade1};
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  .select-all-stores {
    padding: 10px 0 8px 14px;
  }
  .facility-address {
    color: ${colors.gray.shade52};
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  .footer {
    display: flex;
    align-self: flex-end;
    gap: 8px;
    & :global(button) {
      align-self: center;
      width: auto;
    }

    & :global(button:last-child) {
      align-self: center;
      width: auto;
      padding: 0 20px;
    }
  }
  .drawer-container {
    padding: 0 20px;
    .title {
      color: #333;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      padding-bottom: 20px;
    }
  }
  .footer-mobile {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    padding-top: 20px;
  }
  .cancel-button {
    padding: 18px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    width: 100%;
    color: ${colors.black.shade1};
  }
`;

export { StoresModalStyles };
