import PropTypes from 'prop-types';
import React from 'react';
import WarrantySelectEnhanced from './WarrantySelectEnhanced';

const WarrantySelect = (props) => {
  return <WarrantySelectEnhanced {...props} />;
};

WarrantySelect.propTypes = {
  appCtx: PropTypes.object.isRequired,
};

export default WarrantySelect;
