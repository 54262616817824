export const ADS_SIZE_CONFIG = {
  AD_UPPER_BANNER: {
    ADS_SIZE_DESKTOP: [
      [1280, 100],
      [1280, 150],
    ],
    ADS_SIZE_MOBILE: [
      [300, 0],
      [300, 100],
    ],
    CLASSNAME: 'top',
    GET_ARRAY_INFO: 0,
  },
  AD_SIDE_BANNER: {
    ADS_SIZE_DESKTOP: [
      [160, 0],
      [160, 600],
      [200, 0],
      [200, 200],
      [250, 0],
      [250, 250],
      [300, 0],
      [300, 600],
      [300, 250],
    ],
    ADS_SIZE_MOBILE: [300, 600],
    CLASSNAME: 'side',
    GET_ARRAY_INFO: 1,
  },
  AD_BOTTOM_BANNER: {
    ADS_SIZE_DESKTOP: [
      [728, 90],
      [982, 90],
      [970, 90],
    ],
    ADS_SIZE_MOBILE: [300, 250],
    CLASSNAME: 'bottom',
    GET_ARRAY_INFO: 2,
  },
};
