import { isSodimacStandalone } from '@digital-retail/store-manager';
import { isKiosk } from '../kisokHelper';

export function useShouldHideSponsoredPod(appCtx, isSponsored) {
  const isKioskChannel = isKiosk(appCtx);
  const isSodimacSA = isSodimacStandalone(appCtx?.store);
  const shouldHideSponsoredPod = isKioskChannel && isSodimacSA && isSponsored;

  return shouldHideSponsoredPod;
}

export function useShouldHideSponsoredDisplay(appCtx) {
  const isKioskChannel = isKiosk(appCtx);
  const isSodimacSA = isSodimacStandalone(appCtx?.store);
  const shouldHideSponsoredDisplay = isKioskChannel && isSodimacSA;

  return shouldHideSponsoredDisplay;
}
