import _ from '../../utils/LodashImports';

const waitForDigitalDataClasification = () => {
  return new Promise((resolve) => {
    const checkExist = setInterval(() => {
      const xlpClasification = _.get(window, 'digitalData.tag.XLP_Clasification', undefined);
      if (xlpClasification) {
        clearInterval(checkExist);
        resolve();
      }
    }, 300);
  });
};

const getTargetValues = (appCtx) => {
  const digitalDataObject = {
    subSection: _.get(window, 'digitalData.page.subSection', undefined),
    subJota: _.get(window, 'digitalData.medalliaParameters.j_sublinea', undefined),
    siteSection: _.get(window, 'digitalData.page.subSection', undefined),
    store_in_store: _.get(appCtx, 'store', 'falabella') || 'falabella',
    searchTerm:
      _.get(window, '__NEXT_DATA__.props.pageProps.searchTerm', undefined) === 'undefined'
        ? undefined
        : _.get(window, '__NEXT_DATA__.props.pageProps.searchTerm', undefined),
    jota: _.get(window, 'digitalData.medalliaParameters.j_linea', undefined),
    gcategory_a: _.get(window, 'digitalData.tag.XLP_Clasification', '').slice(0, 3),
    gcategory_b: _.get(window, 'digitalData.tag.XLP_Clasification', '').slice(0, 5),
    gcategory_c: _.get(window, 'digitalData.tag.XLP_Clasification', '').slice(0, 7),
    gcategory_d: _.get(window, 'digitalData.tag.XLP_Clasification', '').slice(0, 9),
    emailHash: _.get(window, 'digitalData.login.emailHash', undefined),
  };

  const filteredDigitalDataObject = Object.fromEntries(
    Object.entries(digitalDataObject).filter(([, value]) => !!value)
  );
  return filteredDigitalDataObject;
};

export { getTargetValues, waitForDigitalDataClasification };
