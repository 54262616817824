import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import noop from '../../../../../utils/noop';
import { StoresLineStyles } from './StoresLine.style';

const StoresLine = ({ storesLine = '', deviceType }) => {
  const [hoverState, setHoverState] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const textRef = useRef(null);
  const buttonRef = useRef(null);
  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowing(textRef.current.scrollWidth > textRef.current.clientWidth);
      }
    };

    checkOverflow();
    if (typeof ResizeObserver !== 'undefined') {
      const observer = new ResizeObserver(checkOverflow);
      if (textRef.current) {
        observer.observe(textRef.current);
      }

      return () => {
        if (textRef.current) {
          observer.unobserve(textRef.current);
        }
      };
    }
    return null;
  }, [storesLine]);

  return (
    <div className="stores-line-container">
      <div ref={textRef} className="stores-line">
        {storesLine}
      </div>
      {isOverflowing && (
        <button
          type="button"
          ref={buttonRef}
          className="question-icon"
          onMouseOver={() => setHoverState(true)}
          onFocus={noop}
          onBlur={noop}
          onMouseOut={() => setHoverState(false)}
          onClick={() => setHoverState((prev) => !prev)}
        >
          {hoverState && (
            <div className={`stores-list-popup${deviceType === 'mobile' ? '-mobile' : ''}`}>
              <div className="content">{storesLine}</div>
            </div>
          )}
        </button>
      )}
      <style jsx>{StoresLineStyles}</style>
    </div>
  );
};

StoresLine.defaultProps = {
  storesLine: '',
  deviceType: '',
};

StoresLine.propTypes = {
  storesLine: PropTypes.string,
  deviceType: PropTypes.string,
};

export default StoresLine;
