import { isTottusStandalone } from '@digital-retail/store-manager';
import apiConfig from '../../config/api/apiConfig';
import constants from '../../config/constants';
import CookieStorage from '../CookieStorage';
import _ from '../LodashImports';
import Logger from '../Logger';
import httpService from '../httpService';
import { getQuantityUnit } from '../store.helpers';
import { getCommerceHeaders } from '../tenantHelper';
import {
  cartAlertsAndErrorSeggregator,
  formatUpdatedServiceOptions,
  formatUpdatedWarrantyOptions,
  updateCartLineId,
} from './helpers';

const removeCartLine = async ({ cartId, cartLineId, additionalHeaders, item, setTotal, regionCode, appCtx }) => {
  const deleteCartLineUrl = apiConfig.removeCartlineCommerceUrl(cartId, regionCode);

  const reqBody = {
    data: {
      cart: {
        cartLineIds: [`${cartLineId}`],
      },
    },
    metadata: {
      politicalAreaId: appCtx.politicalId,
      priceGroup: appCtx.priceGroupId,
    },
  };

  const response = await httpService().post(
    deleteCartLineUrl,
    reqBody,
    {
      credentials: 'include',
    },
    additionalHeaders,
    'json'
  );

  const alerts = _.get(response, 'data.data.cart.cartAlerts', []);
  const { mappedErrors } = cartAlertsAndErrorSeggregator(alerts, appCtx.siteConfig, response.statusCode);
  const totalProductsQuantityCount = _.get(response, 'data.data.cart.totalProductsQuantityCount', 0);

  setTotal(totalProductsQuantityCount);

  if (!(response.statusCode === 200 && !mappedErrors)) {
    Logger.error(`Failed to Remove Current Warranty ${JSON.stringify(item)}`);
    return {
      success: false,
      errors: [
        {
          message: _.get(appCtx, 'siteConfig.textDictionary.A2C_ERROR_REMOVE_ITEM', ''),
        },
      ],
    };
  }

  return {
    success: true,
  };
};

const deleteCartLine = async ({ cartId, cartLineId, additionalHeaders, item, regionCode, appCtx }) => {
  const deleteCartLineUrl = apiConfig.deleteCartlineCommerceUrl(cartId, cartLineId, regionCode);

  const response = await httpService().delete(deleteCartLineUrl, {
    headers: additionalHeaders,
  });
  if (!(response.statusCode === 204)) {
    Logger.error(`Failed to Delete Current Warranty ${JSON.stringify(item)}`);
    return {
      success: false,
      errors: [
        {
          message: _.get(appCtx, 'siteConfig.textDictionary.A2C_ERROR_REMOVE_ITEM', ''),
        },
      ],
    };
  }

  return {
    success: true,
  };
};

const setWarrantyAndServices =
  ({ cart, setCart, channel = 'warranty' }) =>
  async ({ item, appCtx, productContext, variantId, cartLineId, totalQuantity, offeringId }) => {
    const cartId = CookieStorage.getCookie('cartId') || _.get(cart, 'cartId', '');
    const { regionCode } = appCtx;
    let totalProductsCount = 0;

    const setTotal = (count) => {
      totalProductsCount = count;
    };

    const zone = _.get(appCtx, 'zoneData.zoneID', constants.ZONE.DEFAULT_ZONE_ID);

    const additionalHeaders = getCommerceHeaders(regionCode, true, appCtx.url, appCtx.store);

    if (cartLineId) {
      const response = !item.skuId
        ? await removeCartLine({
            cartId,
            cartLineId,
            zone,
            additionalHeaders,
            item,
            setTotal,
            regionCode,
            appCtx,
          })
        : await deleteCartLine({
            cartId,
            cartLineId,
            additionalHeaders,
            item,
            regionCode,
            appCtx,
          });

      if (!response.success) {
        return response.errors;
      }
      updateCartLineId({
        cart,
        setCart,
        warranty: item,
        variantId,
        channel,
        childProducts: [],
        method: 'DELETE',
        prevCartLineId: cartLineId,
        totalProductsCount,
      });
      const newProduct =
        channel === 'warranty'
          ? formatUpdatedWarrantyOptions(item.skuId, productContext)
          : formatUpdatedServiceOptions(item.skuId, productContext);

      productContext.setProductData({ ...newProduct });
      if (channel === 'service') {
        return false;
      }
    }
    const isTottusSA = isTottusStandalone(appCtx?.store);

    if (item.skuId) {
      const cartLine = {
        parentLineNumber: variantId,
        cartLineNumber: offeringId || item.skuId,
        item: {
          variantId: item.skuId,
          offeringId,
        },
        quantity: {
          quantityNumber: totalQuantity,
          quantityUnit: getQuantityUnit({ store: appCtx?.store }),
        },
        skipAlertCodes: isTottusSA ? constants.SKIP_ALERT_CODES : '',
      };

      const reqBody = {
        data: {
          cart: {
            cartLines: [cartLine],
          },
        },
        metadata: {
          politicalAreaId: appCtx.politicalId,
          priceGroup: appCtx.priceGroupId,
        },
      };

      let query = cartId ? `?cartId=${cartId}` : '';
      const symbol = query ? '&' : '?';
      query += isTottusSA ? `${symbol}return=ALL` : '';
      const url = apiConfig.addToCartCommerceUrl(query, regionCode);
      return httpService()
        .post(
          url,
          reqBody,
          {
            credentials: 'include',
          },
          additionalHeaders,
          'json'
        )
        .then((response) => {
          const alerts = _.get(response, 'data.data.cart.cartAlerts', []);
          const { mappedErrors } = cartAlertsAndErrorSeggregator(alerts, appCtx.siteConfig, response.statusCode);
          if (response.statusCode === 200 && !mappedErrors) {
            const childProducts = _.get(response, 'data.data.cart.cartLines', []);
            const totalProductsQuantityCount = _.get(response, 'data.data.cart.totalProductsQuantityCount', 0);
            totalProductsCount = totalProductsQuantityCount;
            updateCartLineId({
              cart,
              setCart,
              warranty: item,
              variantId,
              channel,
              childProducts,
              method: 'UPDATE',
              prevCartLineId: cartLineId,
              totalProductsCount,
            });

            const newProduct =
              channel === 'warranty'
                ? formatUpdatedWarrantyOptions(item.skuId, productContext)
                : formatUpdatedServiceOptions(item.skuId, productContext);

            productContext.setProductData({ ...newProduct });
          } else {
            updateCartLineId({
              cart,
              setCart,
              warranty: item,
              variantId,
              channel,
              childProducts: [],
              method: 'UNCHECKALL',
              prevCartLineId: cartLineId,
            });
            if (mappedErrors && mappedErrors.length > 0) {
              return mappedErrors;
            }
            const { errors } = _.get(response, 'data', {});
            Logger.error(`Failed to Set Warranty ${JSON.stringify(item)}`);
            return errors || [];
          }
          return null;
        })
        .catch(() => {
          appCtx.setBusy(false);
        });
    }
    updateCartLineId({
      cart,
      setCart,
      warranty: item,
      variantId,
      channel,
      childProducts: [],
      method: 'DELETE',
      prevCartLineId: cartLineId,
      totalProductsCount,
    });
    const newProduct =
      channel === 'warranty'
        ? formatUpdatedWarrantyOptions('', productContext)
        : formatUpdatedServiceOptions('', productContext);
    productContext.setProductData({ ...newProduct });

    return null;
  };

export default setWarrantyAndServices;
