export const checkValidity = ({ component, id, config }) => {
  const timevalidity = config?.[component]?.[id];
  if (!timevalidity) {
    return false;
  }
  const now = new Date().getTime();
  if (timevalidity > now) {
    return true;
  }
  return false;
};
