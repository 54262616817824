import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import _ from '../../utils/LodashImports';
import { ADS_SIZE_CONFIG } from './constants';
import GoogleAdsStyles from './GoogleAds.style';
import { getTargetValues, waitForDigitalDataClasification } from './helpers';

const GoogleAdComponent = ({ appCtx, adsPosition, deviceType }) => {
  const [targetData, setTargetData] = useState();

  const { GET_ARRAY_INFO, ADS_SIZE_DESKTOP, ADS_SIZE_MOBILE, CLASSNAME } = ADS_SIZE_CONFIG[adsPosition];
  const { xlp } = _.get(appCtx, 'siteConfig.configurations.adsConfig', '{}');
  const adsData = xlp ? xlp[GET_ARRAY_INFO] : null;
  const adSizes = deviceType ? ADS_SIZE_DESKTOP : ADS_SIZE_MOBILE;
  const hasRun = useRef(false);

  useEffect(() => {
    const fetchTargetData = async () => {
      await waitForDigitalDataClasification();
      const data = getTargetValues(appCtx);
      setTargetData(data);
    };
    fetchTargetData();
  }, [appCtx]);

  useEffect(() => {
    if (hasRun.current || !xlp || !adsData || !targetData) return;
    hasRun.current = true;

    if (!window.googletag?.cmd) {
      window.googletag = { cmd: [] };
    }

    window.googletag.cmd.push(() => {
      const slotId = adsData.slot;

      if (window.googletag.pubads && window.googletag.pubads().getSlots) {
        const existingSlots = window.googletag.pubads().getSlots();
        const existingSlot = existingSlots.find((slot) => slot.getSlotElementId() === slotId);

        if (existingSlot) {
          return;
        }
      }

      const slot = window.googletag.defineSlot(adsData.folder, adSizes, slotId);
      if (!slot) {
        return;
      }

      slot?.addService(window.googletag.pubads());

      if (targetData.emailHash) {
        window.googletag.pubads().setPublisherProvidedId(targetData.emailHash);
      }

      window.googletag.pubads().enableSingleRequest();

      const pubads = window.googletag.pubads();
      Object.entries(targetData).forEach(([key, value]) => {
        if (value) {
          pubads.setTargeting(key, value);
        }
      });

      window.googletag.pubads().collapseEmptyDivs(true);
      window.googletag.enableServices();

      window.googletag.display(slotId);
    });
  }, [xlp, adsData, adSizes, targetData]);

  if (!xlp || !adsData) {
    return null;
  }
  return (
    <>
      <style jsx>{GoogleAdsStyles}</style>
      <div id={adsData.slot} className={`ad-component-${CLASSNAME}`} />
    </>
  );
};

GoogleAdComponent.propTypes = {
  adsPosition: PropTypes.string.isRequired,
  appCtx: PropTypes.object.isRequired,
  deviceType: PropTypes.bool.isRequired,
};

export { GoogleAdComponent };
export default withApplicationContext(GoogleAdComponent);
