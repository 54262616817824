import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import noop from '../../utils/noop';
import { navigateWithUrl } from '../../utils/RouteHandler';
import Heading from '../ui/Heading/Heading';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import { autoCorrectStyles, linkButtonStyles } from './AutoCorrect.style';

const LinkButton = ({ onClick, children, size, type }) => {
  return (
    <button type="button" onClick={onClick} className={`link-button link-button-${size} link-button-${type}`}>
      {children}
      <style jsx>{linkButtonStyles}</style>
    </button>
  );
};

LinkButton.defaultProps = {
  onClick: noop,
  size: '',
  type: '',
};

LinkButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['size1', 'size2', 'size3', '']),
  type: PropTypes.oneOf(['primary', '', 'mkp-primary']),
};

const AutoCorrect = ({ searchTerm, items, appCtx, labels }) => {
  const { setBusy, tenant, store, regionCode } = appCtx;

  const resultTerm = items[0];
  const restTerms = items.slice(1);
  const isWhiteLabelExperience = _.get(appCtx, 'siteConfig.toggles.isWhiteLabelExperience', false);

  const handleButtonClick = (url) => {
    navigateWithUrl({
      url,
      onRouteChange: () => {
        setBusy(true);
      },
      site: tenant,
      store,
      regionCode,
      isWhiteLabelExperience,
    });
  };

  const variation = {
    mobile: {
      headerSize: 'title5',
      buttonSize: 'size1',
      rebrandingHeaderSize: 'title12',
      rebrandingButtonSize: 'size3',
    },
    tablet: {
      headerSize: 'title6',
      buttonSize: 'size2',
      rebrandingHeaderSize: 'title6',
      rebrandingButtonSize: 'size2',
    },
    desktop: {
      headerSize: 'title6',
      buttonSize: 'size2',
      rebrandingHeaderSize: 'title6',
      rebrandingButtonSize: 'size2',
    },
  };

  const noResultLabel = labels.NO_RESULT_LABEL;
  const showingResultForLabel = labels.SHOWING_RESULT_FOR_LABEL;
  const otherPossibleSearchesLabel = labels.OTHER_SEARCHES_LABEL;
  const variationKey = 'rebrandingHeaderSize';
  const buttonVariationKey = 'rebrandingButtonSize';
  const getAutoCorrectBody = (deviceType) => (
    <>
      <Heading level="3" variation={variation[deviceType][variationKey]} inline light>
        {noResultLabel} &nbsp;
      </Heading>
      <Heading level="3" type="mkp-primary" variation={variation[deviceType][variationKey]} light={false} inline>
        {`'${decodeURIComponent(searchTerm)}'`}
      </Heading>
      <Heading level="3" variation={variation[deviceType][variationKey]} inline light>
        . &nbsp;
      </Heading>
      <Heading level="3" light variation={variation[deviceType][variationKey]} inline type="">
        {showingResultForLabel} &nbsp;
      </Heading>
      <Heading level="4" variation={variation[deviceType][variationKey]} type="mkp-primary" light={false} inline>
        {`'${resultTerm.label}'`}
      </Heading>

      <Heading level="3" light variation={variation[deviceType][variationKey]} inline type="">
        .
      </Heading>
      {restTerms.length !== 0 && (
        <>
          <Heading level="3" light variation={variation[deviceType][variationKey]} inline type="">
            {otherPossibleSearchesLabel} &nbsp;
          </Heading>
          {restTerms.map((item, index) => (
            <Fragment key={item.label}>
              <LinkButton
                id={`testId-AutoCorrect-${item.label}`}
                onClick={() => {
                  handleButtonClick(item.url);
                }}
                type="mkp-primary"
                inline
                size={variation[deviceType][buttonVariationKey]}
                capitalize={false}
              >
                {`'${item.label}'`}
              </LinkButton>

              <Heading variation={variation[deviceType][variationKey]} light level="3" inline isHighlighted={false}>
                {restTerms.length === index + 1 ? '.' : ','}
                &nbsp;
              </Heading>
            </Fragment>
          ))}
        </>
      )}
    </>
  );

  return (
    <div className="auto-correct-container" id="testId-AutoCorrect-container">
      <MatchMedia
        content={{
          mobile: () => getAutoCorrectBody('mobile'),
          tablet: () => getAutoCorrectBody('tablet'),
          desktop: () => getAutoCorrectBody('desktop'),
        }}
      />

      <style jsx>{autoCorrectStyles}</style>
    </div>
  );
};

AutoCorrect.defaultProps = {
  items: [],
  searchTerm: '',
  labels: {},
};

AutoCorrect.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  appCtx: PropTypes.object.isRequired,
  searchTerm: PropTypes.string,
  labels: PropTypes.object,
};
export default withApplicationContext(withLabels(AutoCorrect));
export { AutoCorrect, LinkButton };
