import constants from '../../../config/constants';
import _ from '../../../utils/LodashImports';

const getWarrantyLabel = ({ textLabel = '', sellerName, warrantyLabelTemplate }) => {
  if (constants.SODIMAC_REGEX.test(sellerName)) {
    const regex = /\b(\d+)A\b/;
    const match = textLabel.match(regex);
    if (match) {
      const years = match[1];
      const warrantyName = `${years} Año${years === '1' ? '' : 's'}`;
      return warrantyLabelTemplate.replace('{warrantyName}', warrantyName);
    }
    return textLabel;
  }
  return warrantyLabelTemplate.replace('{warrantyName}', textLabel);
};

const getWarrantyBenefits = ({ siteConfig = {}, regionCode = 'cl', gCategoryId = '' }) => {
  const fallbackGenericWarrantyBenefits = {
    cl: [],
    pe: [
      'Devolución de GEX por robo durante la Garantía Original',
      'Trabajo realizado por expertos certificados.',
      'Cobertura en caso de variación de voltaje.',
      'Transferencia de titularidad sin cargo adicional.',
      'Hasta 25% de descuento en mantenimiento y/o compra de accesorios para todos sus electrodomésticos.',
      'Uso de repuestos originales.',
      'Reemplazo si la reparación excede el plazo de atención o falla 3 veces, y su costo supera el 70% del valor.',
      'Hasta 25% de descuento en reparaciones no cubiertas por la GEX, solo si el equipo es reparable.',
    ],
    co: [
      'Uso de repuestos originales',
      'Mano de Obra Certificada',
      'Transportes y fletes en caso de requerimiento',
      'Extensión de la garantía de fábrica de 1 a 4 años',
      'Daño accidental primeros 30 días (una vez entregado el producto)',
      'Cobertura en caso de variación de voltaje',
      'Cobertura Nacional',
      'Visitas técnicas y diagnósticos gratis',
      'Garantía de reparación-uso',
    ],
  };
  const fallbackGCatWarrantyBenefits = {
    cl: {
      G19030102: [
        'Cobertura en caso de variacion de voltaje',
        'Trabajo realizado por expertos',
        'Uso de repuestos originales',
        'Evaluación gratuita',
        'Garantía trasferible',
        'Cobertura Nacional',
        'Reembolso para compras inferiores a $1.500.000, luego de revision por parte del servicio tecnico.',
      ],
      G1901: [
        'Cobertura en caso de variacion de voltaje',
        'Reembolso automatico para productos menores a $80.000, luego de revision',
        'Trabajo realizado por expertos',
        'Uso de repuestos originales',
        'Evaluación gratuita',
        'Garantía trasferible',
        'Cobertura Nacional',
      ],
      G1904: [
        'Cobertura en caso de variacion de voltaje',
        'Reembolso automatico para productos menores a $80.000, luego de revision',
        'Trabajo realizado por expertos',
        'Uso de repuestos originales',
        'Evaluación gratuita',
        'Garantía trasferible',
        'Cobertura Nacional',
      ],
      G1902: [
        'Cobertura en caso de variacion de voltaje',
        'Reembolso automatico para productos menores a $80.000, luego de revision',
        'Trabajo realizado por expertos',
        'Uso de repuestos originales',
        'Evaluación gratuita',
        'Garantía trasferible',
        'Cobertura Nacional',
      ],
      G1907: [
        'Cobertura en caso de variacion de voltaje',
        'Trabajo realizado por expertos',
        'Uso de repuestos originales',
        'Evaluación gratuita',
        'Garantía trasferible',
        'Cobertura Nacional',
        'Reembolso para compras inferiores a $100.000, luego de revision por parte del servicio tecnico.',
      ],
      G140203: ['Producto de reemplazo tras diagnóstico de falla'],
      G140205: ['Producto de reemplazo tras diagnóstico de falla'],
      G140206: ['Producto de reemplazo tras diagnóstico de falla'],
      G1403: [
        'Cobertura en caso de variacion de voltaje',
        'Reembolso automatico para productos menores a $80.000, luego de revision',
        'Trabajo realizado por expertos',
        'Uso de repuestos originales',
        'Evaluación gratuita',
        'Garantía trasferible',
        'Cobertura Nacional',
      ],
      G1401: [
        'Cobertura en caso de variacion de voltaje',
        'Reembolso automatico para productos menores a $80.000, luego de revision',
        'Trabajo realizado por expertos',
        'Uso de repuestos originales',
        'Evaluación gratuita',
        'Garantía trasferible',
        'Cobertura Nacional',
      ],
      G02010210: [
        'Cobertura en caso de variacion de voltaje',
        'Reembolso automatico para productos menores a $80.000, luego de revision',
        'Trabajo realizado por expertos',
        'Uso de repuestos originales',
        'Evaluación gratuita',
        'Garantía trasferible',
        'Cobertura Nacional',
      ],
      G21020205: [
        'Reembolso automatico para productos menores a $80.000, luego de revision',
        'Trabajo realizado por expertos',
        'Uso de repuestos originales',
        'Evaluación gratuita',
        'Garantía trasferible',
        'Cobertura Nacional',
      ],
      G21020105: [
        'Reembolso automatico para productos menores a $80.000, luego de revision',
        'Trabajo realizado por expertos',
        'Uso de repuestos originales',
        'Evaluación gratuita',
        'Garantía trasferible',
        'Cobertura Nacional',
      ],
      G13020806: [
        'Cobertura en caso de variacion de voltaje',
        'Reembolso automatico para productos menores a $80.000, luego de revision',
        'Trabajo realizado por expertos',
        'Uso de repuestos originales',
        'Evaluación gratuita',
        'Garantía trasferible',
        'Cobertura Nacional',
      ],
      G21020202: [
        'Reembolso automatico para productos menores a $80.000, luego de revision',
        'Trabajo realizado por expertos',
        'Uso de repuestos originales',
        'Evaluación gratuita',
        'Garantía trasferible',
        'Cobertura Nacional',
      ],
      G13020805: [
        'Reembolso automatico para productos menores a $80.000, luego de revision',
        'Trabajo realizado por expertos',
        'Uso de repuestos originales',
        'Evaluación gratuita',
        'Garantía trasferible',
        'Cobertura Nacional',
      ],
      G13021603: [
        'Reembolso automatico para productos menores a $80.000, luego de revision',
        'Trabajo realizado por expertos',
        'Uso de repuestos originales',
        'Evaluación gratuita',
        'Garantía trasferible',
        'Cobertura Nacional',
      ],
    },
    co: {
      G1908: ['Televisores: Indemnizacion inmediata, previo validación del tecnico'],
      G1403: [
        'Productos cuyo Monto sea entre $60.000 -$ 350.000 se indeminza el valor del producto. Solo pequeños electrodomesticos.',
      ],
    },
    pe: {},
  };
  const warrantyBenefits = _.get(
    siteConfig,
    `configurations.genericWarrantyBenefits`,
    fallbackGenericWarrantyBenefits[regionCode]
  );
  const gCatWarrantyBenefitsList = _.get(
    siteConfig,
    `configurations.gCatWarrantyBenefits`,
    fallbackGCatWarrantyBenefits[regionCode]
  );

  if (gCatWarrantyBenefitsList) {
    const gCatMatch = Object.keys(gCatWarrantyBenefitsList).find((g) => gCategoryId.startsWith(g));
    if (gCatMatch) {
      warrantyBenefits.push(gCatWarrantyBenefitsList[gCatMatch]);
    }
  }
  return warrantyBenefits.flat();
};
const isWarrantyRecommended = (warrantyText = '') => {
  return warrantyText.toLowerCase().includes('2 años');
};

export { getWarrantyBenefits, isWarrantyRecommended, getWarrantyLabel };
