import css from 'styled-jsx/css';
import { colors, icons } from '../../../../../config/styles';
import { svgToDataUri } from '../../../../../utils/styles/svgMixins';

const ShippingBoxStyles = css`
  .shipping-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 48px;
  }

  .heading-section {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 40px;
  }

  .icon {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    &.home_delivery {
      width: 24px;
      height: 24px;
      background-image: url('${svgToDataUri(icons.hdIcon)}');
    }
    &.withdraw_at_store {
      width: 24px;
      height: 24px;
      background-image: url('${svgToDataUri(icons.ccIcon)}');
    }
    &.buy_at_store {
      width: 24px;
      height: 24px;
      background-image: url('${svgToDataUri(icons.bsIcon)}');
    }
  }

  .heading {
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    color: ${colors.black.shade1};
    flex: 1;
  }

  .heading > p {
    font-weight: 700;
  }

  .switch-container {
    width: 44px;
    position: relative;
    margin-top: 2px;
  }

  .label-section {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: ${colors.gray.shade40};
    word-wrap: break-word;
  }

  .bold {
    font-weight: bold;
  }
  .shipping-buttons {
    display: inline-flex;
    gap: 8px;
    flex-wrap: wrap;
    width: auto;
  }

  .store-title {
    display: flex;
    gap: 4px;
    color: ${colors.blue.shade3};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: 8px 0;
  }

  .facility-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    width: 14px;
    height: 14px;
    padding: 2px 5px;

    &.sodimac {
      border: 0.5px solid ${colors.gray.shade61};
      background-color: ${colors.white.shade1};

      i {
        background-size: contain;
        background-repeat: no-repeat;
        width: 11px;
        height: 8px;
        flex-shrink: 0;
        background-image: url('${svgToDataUri(icons.sodimacFacilityIcon)}');
      }
    }

    &.falabella {
      background-color: ${colors.lime['lime-06']};

      i {
        background-repeat: no-repeat;
        width: 5px;
        height: 10px;
        background-image: url('${svgToDataUri(icons.falabellaFacilityIcon)}');
      }
    }
  }

  .envio-gratis-toggle {
    .toggle-name {
      color: ${colors.green.shade26};
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      align-self: center;
    }
  }

  .stores-line {
    color: #717171;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding-bottom: 12px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export { ShippingBoxStyles };
