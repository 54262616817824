/* eslint-disable react/prop-types */
import React from 'react';
import _ from '../../utils/LodashImports';
import { getMatchingVariants } from '../../utils/variant';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import CartItem from './CartItem/CartItem';

const mapItem = (imageBaseURL, item) => {
  const { brand, displayName, id, prices } = item.product;

  return {
    brandName: brand,
    name: displayName,
    skuId: id,
    image: `${imageBaseURL}/${id}`,
    productId: '',
    price: prices,
    totalQuantity: 0,
  };
};

const mapBundledItem =
  ({ bundleItems, setBusy, removeProduct, setWarrantyOption, addProduct, imageBaseURL, limitAlerts }) =>
  (item) => {
    let isItemInCart = false;
    let bundleItem;

    bundleItems.forEach((bundle) => {
      const itemId = _.get(item, 'product.id', _.get(item, 'id', ''));
      if (!isItemInCart && bundle.skuId === itemId) {
        isItemInCart = true;
        bundleItem = bundle;
      }
    });

    const { skuId, id } = item;

    return (
      <CartItem
        key={skuId || id}
        setBusy={setBusy}
        item={isItemInCart ? bundleItem : mapItem(imageBaseURL, item)}
        onRemoveFromCart={removeProduct}
        showWarrantyOption={false}
        showAssemblyOption={false}
        setWarrantyOption={setWarrantyOption}
        onAddToCart={addProduct}
        type="bundle"
        limitAlerts={limitAlerts}
      />
    );
  };

const renderAddedBundle = ({
  labels,
  imageBaseURL,
  CartAddedBundleStyles,
  renderDescription,
  renderBrandName,
  bundleItems,
}) => {
  return (
    <>
      <div className="added-bundles">
        <div className="header mkp">
          <i className="csicon-icon-success-circle">
            <span className="sr-only">{labels.ITEM_ADDED_A11Y_LABEL}</span>
          </i>

          <span className="label">
            {bundleItems.length} {labels.ITEM_ADDED_IN_CART}
          </span>
        </div>

        <div className="bundle-items">
          {bundleItems.map(({ brandName, name, skuId }) => (
            <div className="bundle-item">
              <img src={`${imageBaseURL}/${skuId}`} width="50" height="50" alt={name} />
              <div className="item-info">
                {renderBrandName(brandName)}
                {renderDescription(name)}
              </div>
            </div>
          ))}
        </div>
      </div>
      <style jsx>{CartAddedBundleStyles}</style>
    </>
  );
};

const renderBundleItem = ({
  bundledProductOrigin,
  bundles,
  labels,
  imageBaseURL,
  CartAddedBundleStyles,
  bundleItems,
  setBusy,
  textDictionary,
  removeProduct,
  setWarrantyOption,
  addProduct,
  renderDescription,
  renderBrandName,
  isTypeA,
  limitAlerts,
}) => {
  if (isTypeA || !bundledProductOrigin) {
    return null;
  }

  if (bundledProductOrigin === 'CART') {
    return renderAddedBundle({
      labels,
      bundles,
      imageBaseURL,
      CartAddedBundleStyles,
      renderDescription,
      renderBrandName,
      bundleItems,
    });
  }

  const renderBundledItem = mapBundledItem({
    bundleItems,
    setBusy,
    removeProduct,
    setWarrantyOption,
    addProduct,
    imageBaseURL,
    limitAlerts,
  });
  return bundles.map(renderBundledItem);
};

const getTypeCollectionFromCart = (collection, variantId) => {
  for (let i = 0; i < collection.length; i += 1) {
    if (collection[i].skuId === variantId || collection[i].productId === variantId) {
      return collection[i];
    }
  }
  return null;
};

const isCollectionInMainSection = (cartItems, variantId) => {
  for (let i = 0; i < cartItems.length; i += 1) {
    if (cartItems[i].skuId === variantId) {
      return true;
    }
  }
  return false;
};

const renderCollections = ({
  labels,
  variants,
  colorCode,
  brandName,
  imageBaseURL,
  productId,
  setBusy,
  textDictionary,
  removeProduct,
  addProduct,
  cartItems,
  collection,
  limitAlerts,
}) => {
  const variantsToRender = getMatchingVariants(variants, {
    colorCode,
  }).filter((variant) => {
    const { id: skuId } = variant;

    return !isCollectionInMainSection(cartItems, skuId);
  });

  return (
    variantsToRender &&
    variantsToRender.length !== 0 && (
      <>
        <BodyCopy size="title14">{labels.MORE_PRODUCTS_FROM_COLLECTION}</BodyCopy>
        {variantsToRender.map((variant) => {
          const { name, id: skuId } = variant;

          const collectionItem = getTypeCollectionFromCart(collection, skuId);

          const item = {
            brandName,
            name,
            skuId,
            image: `${imageBaseURL}/${skuId}`,
            productId,
            price: variant.prices,
            totalQuantity: _.get(collectionItem, 'totalQuantity', -1),
            commerceItemId: _.get(collectionItem, 'commerceItemId', 0),
            addedQuantity: _.get(collectionItem, 'addedQuantity', 0),
            cartLineId: _.get(collectionItem, 'cartLineId', ''),
            offeringId: _.get(variant, 'offerings[0].offeringId', ''),
          };

          return (
            <CartItem
              key={skuId}
              setBusy={setBusy}
              item={item}
              onRemoveFromCart={removeProduct}
              showWarrantyOption={false}
              showAssemblyOption={false}
              onAddToCart={addProduct}
              limitAlerts={limitAlerts}
            />
          );
        })}
      </>
    )
  );
};

const showWarningMessage = (stockUnits, cart) => {
  const { cartItems = [], bundleItems = [], typeACollections = [] } = cart;

  const products = [...cartItems, ...bundleItems, ...typeACollections];

  return !!products
    .filter((p) => p)
    .find((p) => {
      if (p.skuId && typeof stockUnits[p.skuId] !== 'undefined' && stockUnits[p.skuId] <= 3) {
        return true;
      }
      return false;
    });
};

export { renderBundleItem, renderCollections, showWarningMessage };
