import PropTypes from 'prop-types';
import React from 'react';
import ChipStyles from './Chip.style';

const Chip = ({ value, url, onChipClick, type, facetType, facetValue, hideRemove, active, onRemove }) => {
  const handleClick = (clickedType, clickedValue) => {
    onChipClick(url, clickedType, clickedValue);
  };
  return (
    <span className={`chip ${type} ${active ? 'active' : ''}`}>
      <button
        type="button"
        onClick={() => {
          handleClick(facetType, facetValue);
        }}
      >
        {value}
      </button>
      {!hideRemove && (
        <button className="csicon-danger" type="button" onClick={() => onRemove(url, facetType, facetValue)}>
          <span className="sr-only">remove</span>
        </button>
      )}
      <style jsx>{ChipStyles}</style>
    </span>
  );
};

Chip.propTypes = {
  value: PropTypes.any.isRequired,
  url: PropTypes.string.isRequired,
  onChipClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  facetType: PropTypes.string,
  facetValue: PropTypes.string,
  hideRemove: PropTypes.bool,
  active: PropTypes.bool,
  onRemove: PropTypes.func,
};

Chip.defaultProps = {
  type: '',
  facetType: '',
  facetValue: '',
  hideRemove: false,
  active: false,
  onRemove: () => {},
};

export default Chip;
