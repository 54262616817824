import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import noop from '../../utils/noop';
import { showCount } from '../ui/FacetCount/helper';
import { FacetsLinkStyle } from './FacetsLink.style';

const FacetsLink = ({ title, onFacetChange, count, isFacetCountHidden, facetType, appCtx }) => {
  return (
    <>
      <button type="button" className="facets-link" id={`testId-facetsLink-${title}`} onClick={onFacetChange}>
        {`${title} ${showCount({ isFacetCountHidden, type: facetType, appCtx }) ? `(${count})` : ''}`}
        <i className="icon csicon-arrow" />
      </button>
      <style jsx>{FacetsLinkStyle}</style>
    </>
  );
};

FacetsLink.defaultProps = {
  onFacetChange: noop,
  isFacetCountHidden: false,
  facetType: '',
};

FacetsLink.propTypes = {
  title: PropTypes.string.isRequired,
  onFacetChange: PropTypes.func,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isFacetCountHidden: PropTypes.bool,
  facetType: PropTypes.string,
  appCtx: PropTypes.object.isRequired,
};

export { FacetsLink };
export default withApplicationContext(FacetsLink);
