import constants from '../../config/constants';
import { getSponsoredClickServiceData } from '../../services/sponsoredService';
import _ from '../../utils/LodashImports';

const getCallToActionText = (product, shouldShowPlans) => {
  let ctaCopy;
  if (product.viewTemplate === 'CONNECT') {
    ctaCopy = 'VIEW_PREPAID';
    if (shouldShowPlans) {
      ctaCopy = 'VIEW_PLAN';
    }
  } else if (product.variants.length && product.variants[0].options.length > 1) {
    ctaCopy = 'CHOOSE_VARIANTS';
  } else {
    ctaCopy = 'ADD_TO_CART';
  }
  return ctaCopy;
};

const formatUrl = (callToAction, productURL) => {
  if (callToAction === 'VIEW_PLAN') {
    if (productURL.match(/\?/g)) {
      return productURL.replace('product', 'plans');
    }

    return productURL.replace('product', 'plans');
  }

  return productURL;
};

function addSpacesAroundAmpersand(inputString) {
  const modifiedString = inputString.replace(/([^ ])&([^ ])/g, '$1 & $2');
  return modifiedString;
}

const capitalizeText = (text) => {
  const words = addSpacesAroundAmpersand(text).split(' ');
  if (words.length === 1) {
    return `${words[0].charAt(0).toUpperCase()}${words[0].slice(1).toLowerCase()}`;
  }
  let capSentence = '';
  words.forEach((word) => {
    capSentence += `${word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()} `;
  });
  return capSentence;
};

const capitalizeFirstLetter = (text) => {
  const words = addSpacesAroundAmpersand(text).split(' ');
  if (words.length === 1) {
    return `${words[0].charAt(0).toUpperCase()}${words[0].slice(1).toLowerCase()}`;
  }
  let capSentence = '';
  words.forEach((word, index) => {
    if (index === 0) {
      capSentence += `${word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()} `;
    } else {
      capSentence += `${word.toLowerCase()} `;
    }
  });
  return capSentence;
};

const buildPodUrl = (url = '', skuId = '') => {
  if (url.split('?').length > 1 && skuId) {
    const productUrlWithoutQueryParams = url.split('?')[0];
    const queryParamsInProductUrl = url.split('?')[1];
    const finalProductUrl = `${productUrlWithoutQueryParams}/${skuId}${
      queryParamsInProductUrl ? `?${queryParamsInProductUrl}` : ''
    }`;
    return finalProductUrl;
  }
  return `${url}${skuId ? '/' : ''}${skuId}`;
};

const isColorVariant = (variant) => variant.type === 'COLOR';

const getSelectedColorIndex = (product) => {
  const colorVariants = product.variants.filter((variant) => isColorVariant(variant));
  const swatchIndex = colorVariants?.[0]?.options?.findIndex((option) => option.selected);

  return swatchIndex && swatchIndex >= 0 ? swatchIndex : 0;
};

const getSellerIconClass = (sellerName = '') => {
  const sellerNameLower = sellerName.toLowerCase();

  if (constants.FALABELLA_REGEX.test(sellerNameLower)) {
    return 'falabella';
  }

  if (constants.SODIMAC_REGEX.test(sellerNameLower)) {
    return 'sodimac';
  }

  if (constants.HOMECENTER_REGEX.test(sellerNameLower)) {
    return 'homecenter';
  }

  if (constants.TOTUS_REGEX.test(sellerNameLower)) {
    return 'tottus';
  }

  if (constants.LINIO_REGEX.test(sellerNameLower)) {
    return 'linio';
  }

  return '';
};

const getUrlWithSponsoredProductClickData = (product, productPosition, config, appCtx, categoryName) => {
  const { isSponsored } = product;
  const { store, regionCode, deviceType, tenant } = appCtx;
  const { MABAYA_EVENTS_ENABLED, MABAYA_TOKEN } = config;
  const productUrl = buildPodUrl(product.url, product.skuId);
  const adInfo = _.get(product, 'mabaya_ad_info', '') || _.get(product, 'adIdentifier', '');
  const sku = _.get(product, 'skuId', '');
  const providerName = _.get(product, 'providerName', null);

  const isEnabledClickinPDP = _.get(appCtx, 'siteConfig.toggles.isEnabledSponsoredClickInPDP', false);

  if (isEnabledClickinPDP && isSponsored) {
    const sponsoredClick = {
      mabayaToken: MABAYA_TOKEN,
      enabled: MABAYA_EVENTS_ENABLED,
      platform: deviceType,
      referrerUrl: window.location.href,
      sku,
      regionCode,
      adInfo,
      mPos: productPosition + 1,
      categoryName,
      tenant,
      store,
      ...(providerName && { providerName }),
    };

    const sponsoredClickData = getSponsoredClickServiceData(sponsoredClick);

    const JsonSponsoredClickData = JSON.stringify(sponsoredClickData);
    const encodedJsonSponsoredClickData = encodeURIComponent(JsonSponsoredClickData);

    const url = new URL(productUrl, window.location.origin);
    url.searchParams.set('sponsoredClickData', encodedJsonSponsoredClickData);

    return url.toString();
  }

  return productUrl;
};

export {
  buildPodUrl,
  capitalizeFirstLetter,
  capitalizeText,
  formatUrl,
  getCallToActionText,
  getSelectedColorIndex,
  getSellerIconClass,
  getUrlWithSponsoredProductClickData,
  isColorVariant,
};
