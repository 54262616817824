import css from 'styled-jsx/css';
import { typography } from '../../config/styles/index';

const stickersStyles = css`
  .meatstickers {
    display: contents;
  }
  .list-desktop-event {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .list-desktop-event span {
    width: fit-content !important;
  }
  .event-sticker-bold {
    font-weight: 700 !important;
  }
  .list-mobile-event span {
    padding: 2px 4px 2px 4px !important;
    width: fit-content !important;
    text-wrap: nowrap;
  }
  .sticker-group {
    line-height: 16px !important;
    padding-right: 3px;
    &.pod-badges-PDP {
      padding-left: 30px;
      &.SOFTLINE {
        padding-left: 0;
        margin-bottom: 3px;
        margin-top: 12px;
      }
    }
    &.pod-badges-LIST {
      padding-left: 0;
      padding-right: 0;
    }
    @mixin mobileToTablet {
      padding-right: 4px;

      &.pod-badges-PDP {
        padding-left: 4px;
        &.SOFTLINE {
          padding-left: 0;
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }
    .pod-badges-item {
      text-transform: none;
      border-radius: 3px;
      letter-spacing: 0;
      padding: 2px 4px 2px 4px;
      margin-right: 4px;
      line-height: 1.7rem;
      &-4_GRID,
      &-LIST {
        font-size: ${typography.base};
      }
      &-PDP {
        font-size: ${typography.base};
        font-weight: 700;
      }
      @mixin mobileToTablet {
        font-size: 12px;
        line-height: 1.7rem;
        &-LIST {
          line-height: 1.7rem;
          font-size: ${typography.sm};
          margin-bottom: 4px;
          width: auto;
          margin-right: 0;
        }
        &-PDP {
          font-size: ${typography.base};
          font-weight: 700;
          line-height: 1.7rem;
          margin-right: 0;
          margin-bottom: 4px;
          width: auto;
        }
      }

      @mixin tabletOnly {
        white-space: nowrap;
        width: auto;
        font-size: 1.3rem;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export default stickersStyles;
