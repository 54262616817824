import css from 'styled-jsx/css';
import { colors, icons } from '../../../../../config/styles';
import { svgToDataUri } from '../../../../../utils/styles/svgMixins';

const StoresLineStyles = css`
  .stores-line-container {
    display: flex;
    width: 100%;
    align-items: center;
    padding-bottom: 12px;
    @mixin mobileToTablet {
      width: 90%;
    }
  }
  .stores-line {
    color: ${colors.gray.shade52};
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .question-icon {
    display: flex;
    position: relative;
    min-height: 24px;
    min-width: 24px;
    background-image: url('${svgToDataUri(icons.questionIcon)}');
    background-size: 100%;
    background-position: center;
  }
  .stores-list-popup,
  .stores-list-popup-mobile {
    display: flex;
    width: 260px;
    min-width: 260px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
    border-radius: 8px;
    background: #333;
    color: #ddd;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    transform: translateY(-50%);
    box-shadow: 4px 0 rgba(29, 29, 29, 0.2);
    position: absolute;
    max-width: 250px;
    white-space: normal;
    z-index: 1000;
    transition: opacity 0.2s ease, transform 0.2s ease;
    pointer-events: none;
  }
  .stores-list-popup {
    top: 12px;
    left: 40px;
  }

  .stores-list-popup-mobile {
    position: absolute;
    right: -32px;
    top: -12px;
    transform: translateY(-100%);
  }
  .stores-list-popup::before {
    content: '';
    position: absolute;
    left: -37px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 24px;
    border-style: solid;
    border-color: transparent #333 transparent transparent;
  }
  .stores-list-popup-mobile::before {
    content: '';
    position: absolute;
    right: 20px;
    bottom: -35px;
    transform: translateY(-50%);
    border-width: 12px 24px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
`;

export { StoresLineStyles };
