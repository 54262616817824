import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { toggleBodyClass } from '../../../utils/jsUtils';
import noop from '../../../utils/noop';
import WithOutsideClick from '../../../utils/WithOutsideClick';
import Loader from '../../Loader/Loader';
import BackgroundOverlay from '../BackgroundOverlay/BackgroundOverlay';
import { DrawerGlobalStyles, DrawerStyles } from './Drawer.style';

const Drawer = ({ heading, icon, subHeading, children, toggleState, isLoading, onOpen, type }) => {
  const [isDrawerActive, activateDrawer] = toggleState;

  useEffect(() => {
    toggleBodyClass(isDrawerActive, 'drawer-active');
  }, [isDrawerActive]);

  useEffect(() => {
    if (isDrawerActive) onOpen();
  }, [isDrawerActive]);

  return (
    <div
      className={clsx(`drawer-container ${type}`, {
        'is-open': isDrawerActive,
      })}
    >
      {isDrawerActive && (
        <div className="drawer-layout">
          <WithOutsideClick
            onOutsideClick={() => {
              activateDrawer(false);
            }}
          >
            <div className={`drawer-header ${type}`}>
              <div
                className="drawer-line"
                onClick={() => activateDrawer(false)}
                onKeyDown={noop}
                role="button"
                tabIndex={0}
              />
              {heading && (
                <>
                  <div className="title">
                    <div className={`${icon}`} />
                    <div>{heading}</div>
                  </div>

                  {subHeading && <div className="sub-heading">{subHeading}</div>}
                  <div className="divider" />
                </>
              )}
            </div>
            <div className={`drawer-content ${type}`}>
              {children}
              {isLoading && <Loader />}
            </div>
          </WithOutsideClick>
          <BackgroundOverlay />
        </div>
      )}
      <style jsx>{DrawerStyles}</style>
      <style jsx>{DrawerGlobalStyles}</style>
    </div>
  );
};

Drawer.defaultProps = {
  subHeading: null,
  isLoading: true,
  onOpen: noop,
  type: '',
  icon: '',
};

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.func.isRequired,
  subHeading: PropTypes.string,
  toggleState: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  onOpen: PropTypes.func,
  type: PropTypes.string,
  icon: PropTypes.string,
};

export default Drawer;
