import PropTypes from 'prop-types';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import _ from '../../../utils/LodashImports';
import { checkValidity } from './FeatureHighlighter.helper';
import newFeatureStyle from './FeatureHighlighter.style';

const FeatureHighlighter = ({ component, id, appCtx }) => {
  const config = _.get(appCtx, 'siteConfig.configurations.featureHighlighter', {});
  const showHighLighter = () => {
    return checkValidity({ component, id, config });
  };
  return (
    showHighLighter() && (
      <>
        <div className={`highlighter ${component}`}>
          <p className="feature">Nuevo</p>
        </div>
        <style jsx>{newFeatureStyle}</style>
      </>
    )
  );
};
FeatureHighlighter.propTypes = {
  component: PropTypes.string.isRequired,
  appCtx: PropTypes.object,
  id: PropTypes.string.isRequired,
};
FeatureHighlighter.defaultProps = {
  appCtx: {},
};

export { FeatureHighlighter };
export default withApplicationContext(FeatureHighlighter);
