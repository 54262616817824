import css from 'styled-jsx/css';
import colors from '../../config/styles/colors';

const CategoryTitleStyles = css`
  .brand-title-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    bottom: auto;
    height: auto;
    background-color: ${colors.white.shade1};
    box-sizing: border-box;
    border-bottom: 1px solid ${colors.white.shade2};
    padding: 10px 19px;
    @mixin tabletToDesktop {
      padding: 25px 0;
    }
    &.mobile {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-height: 39px;
    }
  }
`;

export default CategoryTitleStyles;
