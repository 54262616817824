import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import noop from '../../../utils/noop';
import FeatureHighlighter from '../FeatureHighlighter/FeatureHighlighter';
import Heading from '../Heading/Heading';
import { accordionItemStyle, accordionStyle } from './Accordion.style';

const AccordionItem = ({
  title,
  group,
  subTitle,
  logo,
  iconOptions,
  content,
  hasCustomVisualsForItem,
  state,
  border = false,
  withOutExpansion = false,
  clickEventHandler,
  withPadding,
  subTitleElement,
  highLightHeading,
  isCart = false,
  badge,
  type = '',
  collapseIds,
  idItem,
  handleAccordianClick,
  isMobileAccordian,
  selectedCount,
  appCtx,
  skipAccordianItem,
}) => {
  const [toggle, setToggle] = useState(state);
  let mobileToggle = state;
  const [idState, setIdState] = useState(idItem);
  const { deviceType } = appCtx;
  const {
    up = 'csicon-arrow_up',
    down = 'csicon-arrow_down',
    styles = { fontSize: '.741em', height: '9px', width: '21px' },
  } = iconOptions || {};
  const expandCollapse = () => {
    setToggle(!toggle);
    mobileToggle = !mobileToggle;
    handleAccordianClick(title, group);
    if (collapseIds) {
      if (idState === collapseIds.open) {
        setIdState(collapseIds.close);
      } else if (idState === collapseIds.close) {
        setIdState(collapseIds.open);
      }
    }
  };

  const handleClick = () => {
    if (withOutExpansion === false) {
      expandCollapse();
    } else {
      clickEventHandler();
    }
  };

  const getBorderClass = () => {
    const resClass = 'withBorder';
    if (!border) return '';
    if (toggle) return resClass;
    return `${resClass} bottomPadding`;
  };
  if (skipAccordianItem) {
    return content();
  }
  return (
    <li
      className={`${hasCustomVisualsForItem ? 'withBorderBottom' : ''} ${getBorderClass()} ${isCart ? 'isCart' : ''}`}
      key={title}
    >
      <button type="button" className={type} onClick={handleClick} id={idState}>
        <div className="container">
          {logo ? (
            <div className={`logo ${withPadding ? 'withPadding' : 'withFixedWidth'} rebranded`}>
              <i className={`${logo}`} />
            </div>
          ) : (
            ''
          )}

          <div className={`headingContainer ${badge && 'flexRow'}`}>
            <Heading
              isHighlighted={subTitle || toggle || isMobileAccordian || subTitleElement || highLightHeading}
              level="2"
              variation="title13"
              alignment="left"
            >
              {title}
            </Heading>
            {badge}
            {subTitle ? (
              <div>
                <Heading type="secondary" level="1" variation="base">
                  {subTitle}
                </Heading>
              </div>
            ) : (
              !subTitle &&
              subTitleElement && (
                <div>
                  <Heading type="secondary" level="1" variation="title7">
                    {subTitleElement()}
                  </Heading>
                </div>
              )
            )}
          </div>
          <FeatureHighlighter component="facets" id={title} />
          {selectedCount > 0 && deviceType !== 'desktop' && <span className="mkp-count">{selectedCount}</span>}
        </div>
        {isMobileAccordian ? (
          <i className={`icon ${mobileToggle ? up : down}`} style={styles}>
            <span className="sr-only">{title}</span>
          </i>
        ) : (
          <i className={`icon ${toggle ? up : down}`} style={styles}>
            <span className="sr-only">{title}</span>
          </i>
        )}
      </button>
      {isMobileAccordian ? <div hidden={!mobileToggle}>{content()}</div> : <div hidden={!toggle}>{content()}</div>}
      <style jsx>{accordionItemStyle}</style>
    </li>
  );
};

const Accordion = ({ items, hasCustomVisualsForItem, handleAccordianClick, isMobileAccordian, appCtx }) => {
  return (
    <ul className="accordion">
      {items.map(
        ({
          title,
          group,
          subTitle,
          subTitleElement,
          logo,
          iconOptions,
          content,
          state,
          border,
          withOutExpansion,
          clickEventHandler,
          withPadding,
          highLightHeading,
          isCart,
          badge,
          idCollapse,
          collapseIds,
          selectedCount,
          skipAccordianItem,
        }) => {
          const idItem = idCollapse ? `${idCollapse}_up_id` : `testId-Accordion-${title}`;
          return title ? (
            <AccordionItem
              state={state}
              title={title}
              group={group}
              subTitle={subTitle}
              logo={logo}
              iconOptions={iconOptions}
              content={content}
              hasCustomVisualsForItem={hasCustomVisualsForItem}
              handleAccordianClick={handleAccordianClick}
              isMobileAccordian={isMobileAccordian}
              border={border}
              withOutExpansion={withOutExpansion}
              clickEventHandler={clickEventHandler}
              withPadding={withPadding}
              subTitleElement={subTitleElement}
              highLightHeading={highLightHeading}
              isCart={isCart}
              badge={badge}
              idItem={idItem}
              collapseIds={collapseIds}
              selectedCount={selectedCount}
              appCtx={appCtx}
              skipAccordianItem={skipAccordianItem}
            />
          ) : null;
        }
      )}

      <style jsx>{accordionStyle}</style>
    </ul>
  );
};

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  group: PropTypes.string,
  subTitle: PropTypes.string,
  logo: PropTypes.string,
  iconOptions: PropTypes.object,
  content: PropTypes.func.isRequired,
  state: PropTypes.bool.isRequired,
  hasCustomVisualsForItem: PropTypes.bool,
  border: PropTypes.bool,
  withOutExpansion: PropTypes.bool,
  clickEventHandler: PropTypes.func,
  handleAccordianClick: PropTypes.func,
  withPadding: PropTypes.bool,
  subTitleElement: PropTypes.func,
  highLightHeading: PropTypes.bool,
  isCart: PropTypes.bool,
  badge: PropTypes.element,
  type: PropTypes.string,
  idItem: PropTypes.string.isRequired,
  collapseIds: PropTypes.object,
  isMobileAccordian: PropTypes.bool,
  selectedCount: PropTypes.number,
  appCtx: PropTypes.object.isRequired,
  skipAccordianItem: PropTypes.bool,
};

AccordionItem.defaultProps = {
  group: '',
  subTitle: null,
  logo: null,
  iconOptions: undefined,
  border: false,
  hasCustomVisualsForItem: false,
  withOutExpansion: false,
  clickEventHandler: noop,
  handleAccordianClick: noop,
  withPadding: true,
  subTitleElement: null,
  highLightHeading: false,
  isCart: false,
  badge: null,
  type: '',
  collapseIds: {},
  isMobileAccordian: false,
  selectedCount: undefined,
  skipAccordianItem: false,
};

Accordion.defaultProps = {
  hasCustomVisualsForItem: false,
  handleAccordianClick: noop,
  isMobileAccordian: false,
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMobileAccordian: PropTypes.bool,
  hasCustomVisualsForItem: PropTypes.bool,
  handleAccordianClick: PropTypes.func,
  appCtx: PropTypes.object.isRequired,
};
export { AccordionItem };
export default withApplicationContext(Accordion);
