import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { ucFirst } from '../../utils/textHelper';
import { setDigitalData } from '../SelectedFilters/helpers';
import Chip from '../ui/Chip/Chip';
import SelectedFiltersStyles from './SelectedFiltersMobile.style';

const SelectedFilters = ({ filters, makeFacetCall, facets, baseFacetsURL }) => {
  const getSelectedFacetsLabels = () => {
    const facetLabelMap = {};
    facets.forEach((facet) => {
      const { facetType } = facet?.values?.[0] || '';
      facetLabelMap[`${facetType}`] = facet?.name || '';
    });
    return facetLabelMap;
  };
  const handleChipClick = (chipUrl, clickedType, clickedValue) => {
    const url = chipUrl ? `${baseFacetsURL}&${chipUrl}` : chipUrl;
    const urls = [];
    if (url) {
      const facetURL = url.split('&').filter((u) => !u.match(/facetSelected/g));
      if (facetURL.length > 0) {
        urls.push(facetURL.join('&'));
      }
    }
    makeFacetCall(urls.join('&'), false);
    const facetLabels = getSelectedFacetsLabels();
    const facetLabel = facetLabels[`${clickedType}`];
    setDigitalData(facetLabel, clickedValue);
  };

  const getValue = (label, attributes) => {
    if (attributes?.unit && label.split('-').length > 1) {
      const { unit } = attributes;
      return `${unit}${label.split('-').join(` - ${unit}`)}`;
    }
    return ucFirst(label);
  };

  const onRemove = ({ url, facetType, facetValue }) => {
    handleChipClick(url, facetType, facetValue);
  };

  return (
    <div className="selected-filters">
      <div className="filter-container" id="testId-SelectedFilters-container">
        <div className="chips">
          {filters.map((val) => (
            <Chip
              value={getValue(val.title, val.attributes)}
              key={`${val.title}-${val.facetValue}`}
              onChipClick={() => handleChipClick(val.url, val.facetType, val.facetValue)}
              url={val.url}
              facetType={val.facetType}
              facetValue={val.facetValue}
              type="mkp"
              onRemove={() => onRemove(val)}
            />
          ))}
        </div>
      </div>

      <style jsx>{SelectedFiltersStyles}</style>
    </div>
  );
};

SelectedFilters.defaultProps = {
  baseFacetsURL: '',
};

SelectedFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  makeFacetCall: PropTypes.string.isRequired,
  facets: PropTypes.array.isRequired,
  baseFacetsURL: PropTypes.string,
};

export { SelectedFilters };
export default withApplicationContext(SelectedFilters);
