import css from 'styled-jsx/css';

const GoogleAdsStyles = css`
  .ad-component-top {
    margin-bottom: 5px;
  }
  .ad-component-side {
    margin-top: 5px;
  }
  .ad-component-bottom {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 40px;
  }
`;

export default GoogleAdsStyles;
