import css from 'styled-jsx/css';
import { colors, typography } from '../../config/styles';

const paginationCommonStyle = css`
  li,
  button {
    display: inline-block;
    line-height: 25px;
    min-height: 25px;
    text-align: center;
    font-family: inherit;
    min-width: 25px;
    font-size: ${typography.base};
    width: 25px;
    font-weight: 700;
    text-decoration: none;
    color: inherit;
  }

  /* Cambiar min height */
  .pagination-button-mkp {
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: -0.274px;
    color: ${colors.black.shade10};

    :disabled {
      color: ${colors.gray.shade2};
    }
  }

  @mixin fallback {
    .pagination-item-mkp {
      min-width: 20px;
    }
  }

  /* stylelint-disable declaration-no-important, length-zero-no-unit */
  .arrow_left-mkp,
  .arrow_right-mkp {
    margin-top: 0px !important;
    line-height: 25px;
    color: ${colors.black.shade10};

    &.disabled {
      color: ${colors.gray.shade2};
    }
  }

  /* stylelint-enable declaration-no-important, length-zero-no-unit */
`;

const paginationBodyStyle = css`
  .pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    position: static;

    &::before {
      content: '';
      padding: 15px 0;
      display: block;
    }
  }

  ol {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    position: static;
  }

  .selected {
    background-color: ${colors.blue.shade3};
    color: #fff;
    border-radius: 15px;
    font-size: 15px;
    letter-spacing: -0.274px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    margin: 0 4px;

    &.tottus {
      background-color: ${colors.teal.shade1};
    }
    &.whitelabel {
      background-color: var(--primary-color);
    }
  }

  .ellipses {
    margin: 5px 0;
    min-width: auto;
    color: #2a2a2a;
    font-size: 15px;
    font-weight: 700;
    min-height: 21px;
    margin-right: 3px;
    margin-left: 3px;
    height: 21px;
    width: 12px;
    line-height: 21px;
    letter-spacing: -0.274px;
  }

  .arrow {
    margin: 0 5px;

    button {
      width: auto;
      height: 35px;
      margin-left: 0;
      margin-right: 0;
    }

    i {
      width: 16px;
      display: inline-block;
      font-size: ${typography.g1};
    }
  }
`;

const paginationItemStyle = css`
  li {
    margin: 4px;
    &:not(.disabled):hover {
      background-color: ${colors.gray.shade2};
      border-radius: 50%;
    }
  }
`;

export { paginationBodyStyle, paginationCommonStyle, paginationItemStyle };
