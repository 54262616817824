// 12px   10px    8px   - X-Small-Old
// 14px   12px    10px  - X-Small-Super-Old
// 16px 	14px 	  12px  - X-Small
// 16px 	12px 	  --    - X-Small-Super
// 18px	  15px	  14px  - Small
// 18px	  13px	  12px  - Small-Old
// 18px   18px    18px  - Small-New
// 18px	  14px	  --    - Small-Super
// 20px	  16px	  14px  - Medium
// 20px   20px    20px  - Medium-New
// 20px	  16px	  12px  - Medium-Old
// 20px	  14px	  --    - Medium Super
// 24px 	18px 	  16px  - Large
// 24px 	16px 	  12px  - Large-Super
// 24px   24px    24px  - Large-New
// 24px	  18px	  16px  - X-Large
// 24px	  16px	  --    - X-Large Super
const priceSizes = {
  'x-small-old': [
    {
      size: 'copy5',
      importance: 'high',
    },
    {
      size: 'copy6',
    },
    {
      size: 'copy9',
      type: 'tertiary',
    },
  ],
  'x-small-super-old': [
    {
      size: 'copy3',
      importance: 'high',
    },
    {
      size: 'copy5',
    },
    {
      size: 'copy6',
      type: 'tertiary',
    },
  ],
  'x-small': [
    {
      size: 'copy14',
      importance: 'high',
    },
    {
      size: 'copy3',
    },
    {
      size: 'copy5',
      type: 'tertiary',
    },
  ],
  'x-small-super': [
    {
      size: 'copy14',
      importance: 'high',
    },
    {
      size: 'copy5',
    },
    {
      size: 'copy5',
      type: 'tertiary',
    },
  ],
  small: [
    {
      size: 'copy10',
      importance: 'high',
    },
    {
      size: 'copy2',
    },
    {
      size: 'copy3',
      type: 'tertiary',
    },
  ],
  'small-old': [
    {
      size: 'copy10',
      importance: 'high',
    },
    {
      size: 'copy4',
    },
    {
      size: 'copy5',
      type: 'tertiary',
    },
  ],
  'small-new': [
    {
      size: 'copy10',
      importance: 'high',
    },
    {
      size: 'copy10',
      importance: 'medium',
    },
    {
      size: 'copy10',
      type: 'septenary',
    },
  ],
  'small-super': [
    {
      size: 'copy10',
      importance: 'high',
    },
    {
      size: 'copy3',
    },
    {
      size: 'copy3',
      type: 'tertiary',
    },
  ],
  medium: [
    {
      size: 'copy13',
      importance: 'high',
    },
    {
      size: 'copy14',
    },
    {
      size: 'copy3',
      type: 'tertiary',
    },
  ],
  'medium-new': [
    {
      size: 'copy13',
      importance: 'high',
    },
    {
      size: 'copy13',
      importance: 'medium',
    },
    {
      size: 'copy13',
      type: 'septenary',
    },
  ],
  'medium-new-unit': [
    {
      size: 'copy13',
      importance: 'high',
    },
    {
      size: 'copy13',
      importance: 'medium',
    },
    {
      size: 'copy13',
      type: 'septenary',
    },
  ],
  'medium-super': [
    {
      size: 'copy13',
      importance: 'high',
    },
    {
      size: 'copy3',
    },
    {
      size: 'copy3',
      type: 'tertiary',
    },
  ],
  'medium-old': [
    {
      size: 'copy13',
      importance: 'high',
    },
    {
      size: 'copy14',
    },
    {
      size: 'copy5',
      type: 'tertiary',
    },
  ],
  large: [
    {
      size: 'copy12',
      importance: 'high',
    },
    {
      size: 'copy10',
    },
    {
      size: 'copy14',
      type: 'tertiary',
    },
  ],
  'large-new': [
    {
      size: 'copy12',
      importance: 'high',
    },
    {
      size: 'copy12',
      importance: 'medium',
    },
    {
      size: 'copy12',
      type: 'septenary',
    },
  ],
  'large-super': [
    {
      size: 'copy12',
      importance: 'high',
    },
    {
      size: 'copy14',
    },
    {
      size: 'copy5',
      type: 'tertiary',
    },
  ],
  'x-large': [
    {
      size: 'copy12',
      importance: 'high',
    },
    {
      size: 'copy10',
    },
    {
      size: 'copy14',
      type: 'tertiary',
    },
  ],
  'x-large-super': [
    {
      size: 'copy12',
      importance: 'high',
    },
    {
      size: 'copy14',
    },
    {
      size: 'copy14',
      type: 'tertiary',
    },
  ],
};

const priceStylesConfig = {
  DESKTOP_4_GRID: {
    cmr: 'small',
    noCmr: 'small-super',
  },
  DESKTOP_3_GRID_SQ: {
    cmr: 'medium',
    noCmr: 'medium-super',
  },
  DESKTOP_3_GRID: {
    cmr: 'medium',
    noCmr: 'medium-super',
  },
  DESKTOP_2_GRID: {
    cmr: 'large',
    noCmr: 'large-super',
  },
  DESKTOP_LIST: {
    cmr: 'small',
    noCmr: 'small-super',
  },
  MOBILE_2_GRID: {
    cmr: 'x-small',
    noCmr: 'x-small-super',
  },
  MOBILE_1_GRID: {
    cmr: 'small',
    noCmr: 'small-super',
  },
  MOBILE_LIST: {
    cmr: 'x-small',
    noCmr: 'x-small-super',
  },
  PDP_MAIN: {
    cmr: 'x-large',
    noCmr: 'x-large-super',
  },
  BUNDLES: {
    cmr: 'small',
    noCmr: 'small-super',
  },
  MOBILE_BUNDLES: {
    cmr: 'small',
    noCmr: 'small-super',
  },
  TYPE_A: {
    cmr: 'small',
    noCmr: 'small-super',
  },
  CART: {
    cmr: 'small',
    noCmr: 'small-super',
  },
  DESKTOP_SUB_TOTAL: {
    cmr: 'x-small',
    noCmr: 'x-small-super',
  },
  MOBILE_SUB_TOTAL: {
    cmr: 'small',
    noCmr: 'small-super',
  },
  PDP_MAIN_MOBILE: {
    cmr: 'x-large',
    noCmr: 'x-large-super',
  },
};

const newPriceStylesConfig = {
  DESKTOP_4_GRID: {
    cmr: 'small-new',
    noCmr: 'small-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-22',
      1: 'line-height-22',
      2: 'line-height-17',
    },
  },
  DESKTOP_3_GRID_SQ: {
    cmr: 'medium-new',
    noCmr: 'medium-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-24',
      1: 'line-height-24',
      2: 'line-height-17',
    },
  },
  DESKTOP_3_GRID: {
    cmr: 'medium-new',
    noCmr: 'medium-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-24',
      1: 'line-height-24',
      2: 'line-height-17',
    },
  },
  DESKTOP_2_GRID: {
    cmr: 'large-new',
    noCmr: 'large-new',
    crossed: 'copy14',
    lineHeight: {
      0: 'line-height-29',
      1: 'line-height-29',
      2: 'line-height-19',
    },
  },
  DESKTOP_LIST: {
    cmr: 'small-new',
    noCmr: 'small-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-22',
      1: 'line-height-22',
      2: 'line-height-17',
    },
  },
  MOBILE_2_GRID: {
    cmr: 'small-new',
    noCmr: 'small-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-22',
      1: 'line-height-22',
      2: 'line-height-17',
    },
  },
  MOBILE_1_GRID: {
    cmr: 'small-new',
    noCmr: 'small-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-22',
      1: 'line-height-22',
      2: 'line-height-17',
    },
  },
  MOBILE_LIST: {
    cmr: 'small-new',
    noCmr: 'small-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-22',
      1: 'line-height-22',
      2: 'line-height-17',
    },
  },
  PDP_MAIN: {
    cmr: 'large-new',
    noCmr: 'large-new',
    crossed: 'copy1',
    lineHeight: {
      0: 'line-height-29',
      1: 'line-height-29',
      2: 'line-height-19',
    },
  },
  BUNDLES: {
    cmr: 'small-new',
    noCmr: 'small-new',
    crossed: 'copy3',
  },
  MOBILE_BUNDLES: {
    cmr: 'small-new',
    noCmr: 'small-new',
    crossed: 'copy3',
  },
  TYPE_A: {
    cmr: 'small-new',
    noCmr: 'small-new',
    crossed: 'copy3',
    lineHeight: { 0: 'line-height-22', 1: '', 2: '' },
  },
  CART: {
    cmr: 'small-new',
    noCmr: 'small-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-22',
      1: 'line-height-22',
      2: 'line-height-17',
    },
  },
  DESKTOP_SUB_TOTAL: {
    cmr: 'x-small',
    noCmr: 'x-small-super',
    crossed: 'copy3',
  },
  MOBILE_SUB_TOTAL: {
    cmr: 'small',
    noCmr: 'small-super',
    crossed: 'copy3',
  },
  PDP_MAIN_MOBILE: {
    cmr: 'large-new',
    noCmr: 'large-new',
    crossed: 'copy1',
    lineHeight: {
      0: 'line-height-29',
      1: 'line-height-29',
      2: 'line-height-19',
    },
  },
};

const unitPriceStylesConfig = {
  PDP_MAIN: {
    cmr: 'medium',
    noCmr: 'medium-super',
  },
  PDP_MAIN_MOBILE: {
    cmr: 'medium',
    noCmr: 'medium-super',
  },
  DESKTOP_4_GRID: {
    cmr: 'small',
    noCmr: 'small-super',
  },
  DESKTOP_3_GRID: {
    cmr: 'small',
    noCmr: 'small-super',
  },
  DESKTOP_2_GRID: {
    cmr: 'small',
    noCmr: 'small-super',
  },
  DESKTOP_LIST: {
    cmr: 'small',
    noCmr: 'small-super',
  },
  MOBILE_2_GRID: {
    cmr: 'x-small',
    noCmr: 'x-small-super',
  },
  MOBILE_1_GRID: {
    cmr: 'small',
    noCmr: 'small-super',
  },
  MOBILE_LIST: {
    cmr: 'x-small',
    noCmr: 'x-small-super',
  },
};

const newUnitPriceStyleConfig = {
  PDP_MAIN: {
    cmr: 'medium-new-unit',
    noCmr: 'medium-new-unit',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-24',
      1: 'line-height-24',
      2: 'line-height-17',
    },
  },
  PDP_MAIN_MOBILE: {
    cmr: 'medium-new-unit',
    noCmr: 'medium-new-unit',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-24',
      1: 'line-height-24',
      2: 'line-height-17',
    },
  },
  DESKTOP_4_GRID: {
    cmr: 'small-new',
    noCmr: 'small-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-22',
      1: 'line-height-22',
      2: 'line-height-17',
    },
  },
  DESKTOP_3_GRID: {
    cmr: 'medium-new',
    noCmr: 'medium-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-24',
      1: 'line-height-24',
      2: 'line-height-17',
    },
  },
  DESKTOP_2_GRID: {
    cmr: 'large-new',
    noCmr: 'large-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-29',
      1: 'line-height-29',
      2: 'line-height-19',
    },
  },
  DESKTOP_LIST: {
    cmr: 'small-new',
    noCmr: 'small-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-22',
      1: 'line-height-22',
      2: 'line-height-17',
    },
  },
  MOBILE_2_GRID: {
    cmr: 'small-new',
    noCmr: 'small-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-22',
      1: 'line-height-22',
      2: 'line-height-17',
    },
  },
  MOBILE_1_GRID: {
    cmr: 'small-new',
    noCmr: 'small-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-22',
      1: 'line-height-22',
      2: 'line-height-17',
    },
  },
  MOBILE_LIST: {
    cmr: 'small-new',
    noCmr: 'small-new',
    crossed: 'copy3',
    lineHeight: {
      0: 'line-height-22',
      1: 'line-height-22',
      2: 'line-height-17',
    },
  },
};

const oldPriceStyles = {
  DESKTOP_4_GRID: 'x-small-super',
  DESKTOP_3_GRID_SQ: 'medium',
  DESKTOP_3_GRID: 'medium',
  DESKTOP_2_GRID: 'medium-old',
  DESKTOP_LIST: 'small-old',
  MOBILE_2_GRID: 'x-small-old',
  MOBILE_1_GRID: 'medium-old',
  MOBILE_LIST: 'x-small-super-old',
  PDP_MAIN: 'medium-old',
  BUNDLES: 'x-small-super-old',
  MOBILE_BUNDLES: 'x-small',
  TYPE_A: 'x-small',
  CART: 'x-small-super-old',
  DESKTOP_SUB_TOTAL: 'x-small',
  MOBILE_SUB_TOTAL: 'small',
  PDP_MAIN_MOBILE: 'large-super',
};

export { newPriceStylesConfig, newUnitPriceStyleConfig, oldPriceStyles, priceSizes, unitPriceStylesConfig };
export default priceStylesConfig;
