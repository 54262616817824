import css from 'styled-jsx/css';

const newFeatureStyle = css`
  .highlighter {
    display: flex;
    &.facets {
      padding: 1px 4px 1px 4px;
    }
  }
  .feature {
    display: flex;
    padding: 3px 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    background: #343e49;
    color: #fff;
    text-align: center;
    font-size: 10px;
    font-weight: 700;
    line-height: 12px; /* 120% */
    letter-spacing: 0.4px;
    text-transform: uppercase;
    height: 18px;
    width: 50px;
  }
`;

export default newFeatureStyle;
