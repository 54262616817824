import css from 'styled-jsx/css';

const BestSellerribbonStyle = css`
  /* common */
  .ribbonBox {
    position: absolute;
    width: 101px;
    height: 23px;
    left: 0;
    top: 0;
    background: #ffd248;
    border-radius: 0 0 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .whitelabel-ribbon {
    background: var(--primary-color);
  }

  /*
  .ribbonBox::before,
  .ribbonBox::after {
    position: absolute;
    z-index: 1;
    content: '';
    display: block;
    border: 7px solid #2980b9;
  }
*/
  /* stylelint-disable font-family-no-missing-generic-family-keyword */
  .ribbonBox span {
    position: static;
    width: 91px;
    height: 13px;
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 11px;
    line-height: 13.2px;
    color: #4a4a4a;
    margin: 5px;
    letter-spacing: 0.59px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .whitelabel-ribbon span {
    color: #fff;
    letter-spacing: 0px;
  }
`;
export default BestSellerribbonStyle;
