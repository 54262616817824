import constants from '../config/constants';
import CookieStorage from './CookieStorage';

const isKiosk = (appCtx) => {
  const { KIOSKO_MODULO, KIOSKO_STORE_ID, KIOSKO_INFO } = constants;
  const cookies = CookieStorage.parseCookies(appCtx.ssrCookies);
  const moduloCookie = cookies?.[KIOSKO_MODULO];
  const storeIdCookie = cookies?.[KIOSKO_STORE_ID];
  const kioskoInfo = cookies?.[KIOSKO_INFO];
  if (kioskoInfo || (moduloCookie && storeIdCookie)) {
    return true;
  }
  return false;
};
export { isKiosk };
