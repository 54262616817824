import { isWhitelabel } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React from 'react';
import BestSellerribbonStyle from './BestSellerRibbon.style';

const BestSellerRibbon = ({ label, store }) => {
  return (
    <div className={`ribbonBox ${isWhitelabel({ store }) ? 'whitelabel-ribbon' : ''}`}>
      <span>{label}</span>
      <style jsx>{BestSellerribbonStyle}</style>
    </div>
  );
};

BestSellerRibbon.defaultProps = {
  label: 'TOP EN VENTAS',
  store: '',
};

BestSellerRibbon.propTypes = {
  label: PropTypes.string,
  store: PropTypes.string,
};

export default BestSellerRibbon;
