import css from 'styled-jsx/css';
import { typography } from '../../../../config/styles';

const VerMasFacetCheckboxStyle = css`
  :global(.title) {
    align-items: center !important; /* stylelint-disable-line declaration-no-important */
    @mixin tabletToDesktop {
      text-transform: capitalize;
    }
    .label-count {
      font-size: ${typography.base};
      line-height: 2.3rem;
      letter-spacing: 0.4px;
      align-self: flex-start;
      font-weight: 400;
      right: 10px;
      &.shipping-facet-text {
        font-size: ${typography.base};
        line-height: 1.6rem;
      }
    }
    .capitalize {
      text-transform: capitalize;
    }
    .uppercase {
      text-transform: uppercase;
    }
    .ignorecase {
      text-transform: none;
    }
  }
`;

export default VerMasFacetCheckboxStyle;
