import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../../config/constants';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import { truncateText, ucFirst } from '../../../../utils/textHelper';
import BodyCopy from '../../../ui/BodyCopy/BodyCopy';
import Checkbox from '../../../ui/Checkbox/Checkbox';
import { showCount } from '../../../ui/FacetCount/helper';
import { FacetWithLink } from '../../FacetWithLink/FacetWithLink';
import VerMasFacetCheckboxStyle from './VerMasFacetCheckbox.style';

const VerMasFacetCheckbox = ({ option, onChange, isModalView, isFacetCountHidden, isLink, canonicalUrl, appCtx }) => {
  const { title, selected, count, facetType, extraId } = option;
  const isBrandFacet = constants.BRAND_FACET.test(facetType);
  const facetCase = () => {
    if (isBrandFacet) {
      return 'capitalize';
    }
    return 'ignoreCase';
  };
  return (
    <>
      <Checkbox
        isChecked={selected}
        onChange={onChange}
        id={`${isModalView ? 'verMas-' : ''}checkbox-${title.replace(/\s/g, '-').toUpperCase()}${
          extraId ? `-${extraId}` : ''
        }`}
        type="mkp-succes"
        normalBorderClass=" mkp-normal-border"
        selectedBorderClass=" mkp-border"
        isPrimaryTheme
      >
        <BodyCopy size="title24" highlightedClass="normal">
          <FacetWithLink isLink={isLink} canonicalUrl={canonicalUrl} option={option}>
            <span className={facetCase()}>
              {ucFirst(truncateText(title, constants.FACETS.VALUE_TEXT_CHARCOUNT, true))}
            </span>
          </FacetWithLink>

          {showCount({ isFacetCountHidden, type: facetType, appCtx }) ? (
            <span className="label-count">&nbsp;&nbsp;{`(${count})`}</span>
          ) : null}
        </BodyCopy>
      </Checkbox>
      <style jsx>{VerMasFacetCheckboxStyle}</style>
    </>
  );
};

VerMasFacetCheckbox.propTypes = {
  option: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isModalView: PropTypes.bool,
  isFacetCountHidden: PropTypes.bool,
  isLink: PropTypes.bool,
  canonicalUrl: PropTypes.string,
  appCtx: PropTypes.object.isRequired,
};

VerMasFacetCheckbox.defaultProps = {
  isModalView: false,
  isFacetCountHidden: true,
  isLink: false,
  canonicalUrl: '',
};

export { VerMasFacetCheckbox };
export default withApplicationContext(VerMasFacetCheckbox);
