import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { capitalizeFirstLetter, capitalizeText } from '../Pod/podUtils';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import Heading from '../ui/Heading/Heading';
import CategoryTitleStyles from './BrandTitle.style';

const BrandTitle = ({ brandTitle, resultsLabel, paginationCount, deviceType, isShopPage }) => {
  const isMobile = deviceType === 'mobile';
  const variation = `${isMobile ? 'title5' : 'title11'}${isShopPage ? ' ellipsis' : ''}`;
  return (
    <div
      className={`brand-title-container collection-title ${isMobile ? 'mobile' : ''}`}
      id="testId-BrandTitle-container"
    >
      {isShopPage ? (
        <Heading variation={variation} level="1" light={!!isMobile}>
          {capitalizeFirstLetter((brandTitle || '').replace(/-/g, ' '))}
        </Heading>
      ) : (
        <Heading variation={variation} level="1" light={!!isMobile}>
          {isMobile ? capitalizeText(brandTitle) : brandTitle}
        </Heading>
      )}
      {isMobile && !isShopPage && (
        <BodyCopy size="copy5" id="testId-ProductLandingContainer-totalResults" type="secondary">
          {resultsLabel} ({paginationCount})
        </BodyCopy>
      )}
      <style jsx>{CategoryTitleStyles}</style>
    </div>
  );
};

BrandTitle.defaultProps = {
  brandTitle: '',
  resultsLabel: '',
  isShopPage: false,
};

BrandTitle.propTypes = {
  brandTitle: PropTypes.string,
  resultsLabel: PropTypes.string,
  paginationCount: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  isShopPage: PropTypes.bool,
};

export { BrandTitle };
export default withApplicationContext(BrandTitle);
